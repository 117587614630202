var _module_exports = "<div class=\"toolbar sticky\" data-module=\"Toolbar\">\n    <i\n        class=\"icon-angle-right icon-2x\"\n        data-feature=\"Scroll Right\"\n        ng-click=\"toolbar.scrollGridRight()\"\n    ></i>\n    <i\n        class=\"icon-angle-left icon-2x\"\n        data-feature=\"Scroll Left\"\n        ng-click=\"toolbar.scrollGridLeft()\"\n    ></i>\n</div>\n";;
var path = '/views/gradebook/common/toolbar/toolbar.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
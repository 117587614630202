import './assessment-objective-score-cell/assessment-objective-score-cell.directive.js';
import './sbg-assessment-bucket-cell/sbg-assessment-bucket-cell.component.js';
import './sbg-assessment-bucket-header-cell/sbg-assessment-bucket-header-cell.component.js';
import './sbg-assessment-header-cell/sbg-assessment-header-cell.component.js';
import addEditAssessmentModalTemplate from '../assessment-add-edit/assessment-add-edit.html';
import * as helpers from '../../helpers'

angular
    .module('gradebook')
    .controller('StandardsBasedGridController', [ 
        '$scope',
        '$rootScope',
        '$route',
        '$routeParams',
        '$q',
        '$timeout',
        '$window',
        'GradebookService',
        'ngDialog',
        'SectionService',
        'SettingService',
        'SocketService',
        'TermBinService',
        'GradingMethodologyBucketService',
        'CourseService',
        'settings', 
        
    function( $scope,
        $rootScope,
        $route,
        $routeParams,
        $q,
        $timeout,
        $window,
        GradebookService,
        ngDialog,
        SectionService,
        SettingService,
        SocketService,
        TermBinService,
        GradingMethodologyBucketService,
        CourseService,
        settings
    ){
    var watches = [];
    var fetchGradebookDataPromise;
    var promiseCanceler;
    var previouslyOpenBucketIds = [];

    $scope.filters = { sort: 'section_number' };
    $scope.socket = null;

    $scope.getSetting = SettingService.get;
    $scope.getSortKey = getSortKey;
    $scope.getAssessmentStudentsForAssessment = getAssessmentStudentsForAssessment;
    $scope.getObjectiveGradesForObjectiveBucket = getObjectiveGradesForObjectiveBucket;
    $scope.onAfterObjectiveGradeSave = fetchDataKeepingOpenBuckets;
    $scope.onAfterAssessmentObjectiveScoreSave = fetchDataKeepingOpenBuckets;
    $scope.onAfterAssessmentDeactivation = fetchDataKeepingOpenBuckets;
    $scope.isBucketOpen = isBucketOpen;
    $scope.getVisibleCellCount = getVisibleCellCount;
    $scope.sortStudents = sortStudents;
    $scope.promiseIsNull = promiseIsNull;
    $scope.goToCoursePage = goToCoursePage;
    $scope.openLinkInNewTab = openLinkInNewTab;
    $scope.getSelectedSectionNames = getSelectedSectionNames;
    $scope.hasRequiredParams = hasRequiredParams;
    $scope.getColor = getColor;
    $scope.getCurrentCourseUseStandardsBasedGrading = getCurrentCourseUseStandardsBasedGrading;
    $scope.getEditingClasses = getEditingClasses;
    var hasEditingCellsClass = 'has-editing-cells';
    var editingWithCommentsClass = 'with-comments';

    init();

    function init() {
        setupSocket();
        fetchData();
        setupWatches();

        // extend so that previousRoute is a new object, otherwise it will reference
        // the same object as $route.current and will change when current route changes
        $route.previous = _.extend({}, $route.current);
    }

    function fetchData() {
        var env = angular.element('.js-env');
        var termId = env.data('term-id');
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        var order = SettingService.get('gradebook_assessment_date_order');
        promiseCanceler = $q.defer();

        if (courseId && sectionIds && termBinId) {
            $rootScope.$broadcast('loadingGradebookData', true);
            TermBinService.findById($routeParams.term_bin_id)
                .then(termBin => $scope.selectedTermBin = termBin);

            GradingMethodologyBucketService.getGradingMethodologyBucketContents(courseId, termId, termBinId)
                .then(data => $scope.gradingMethodologyBucketContentsTermBinIds = _.chain(data)
                    .map('term_bin_id')
                    .compact()
                    .value()
                );
            fetchGradebookDataPromise = GradebookService.getBucketedStandardsData(
                courseId,
                sectionIds,
                termBinId,
                termId,
                order,
                { timeout: promiseCanceler.promise }
            );
            return fetchGradebookDataPromise
                .then(processAssessmentData)
                .then(() => $timeout(scrollAssessmentIntoView))
                .then(editAssessmentObjectiveScores)
                .then(clearFocusedAssessmentParams)
                .then(() => fetchGradebookDataPromise = null)
                .catch(_.noop)
                .finally(() => $rootScope.$broadcast('loadingGradebookData', false));
        } else {
            return $timeout(resetGradebookData);
        }
    }

    function fetchDataAndCloseDialog() {
        return fetchData()
            .then(ngDialog.closeAll);
    }

    function fetchDataKeepingOpenBuckets() {
        previouslyOpenBucketIds = _.chain($scope.buckets)
            .filter('open')
            .map('objective.objective_id')
            .value();

        return fetchData()
            .then(ngDialog.closeAll);
    }

    function processAssessmentData(response) {
        if (!response) {
            return $q.reject();
        }

        var courseGradingScale = _.get(response, 'results.course_grading_scale');
        var allGradingScales = _.get(response, 'results.all_grading_scales');

        $scope.course_grading_scale = helpers.cleanGradingScale(courseGradingScale);
        $scope.grading_scales = _.mapValues(allGradingScales, helpers.cleanGradingScale);

        // open buckets previously open (do before setting on scope to avoid jump)
        var buckets = _.get(response, 'results.bucketed_assessments');
        if (_.size(previouslyOpenBucketIds) > 0) {
            buckets = _.chain(buckets)
                .map(bucket => {
                    if (_.includes(previouslyOpenBucketIds, bucket.objective.objective_id)) {
                        bucket.open = true;
                    }
                    return bucket;
                })
                .value();

            previouslyOpenBucketIds = [];
        }

        $scope.buckets = buckets;

        // get all assessments from buckets
        var assessments = _.reduce($scope.buckets, (assessments, standard) => {
            return assessments.concat(standard.assessments);
        }, []);

        // key assessments by assessment_id for fast lookup
        $scope.assessments = _.chain(assessments)
            .keyBy('assessment_id')
            .mapValues(checkIfAssessmentEditable)
            .value();

        // key buckets by objective_id for fast lookup
        $scope.bucketsKeyed = _.keyBy($scope.buckets, 'objective.objective_id');

        $scope.students = _.get(response, 'results.students');

        // get all assessment students where the assessment is editable
        // used in edit-by-student modal
        $scope.assessmentStudentsByStudent = _.chain($scope.students)
            .keyBy('student_id')
            .mapValues('bucketed_assessments')
            .mapValues((buckets) => _.flatMap(buckets, 'assessment_objective_scores'))
            .mapValues((objectiveScores) => _.flatMap(objectiveScores, 'assessment_student'))
            .mapValues((assessmentStudents) => _.filter(assessmentStudents, checkIfAssessmentStudentEditable))
            .value();

        return response;
    }

    function checkIfAssessmentEditable(assessment) {
        var editable = parseInt(assessment.assessment_question_count) == 1
            && parseInt(assessment.assessment_question_point_value) == 100
            && assessment.assessment_question_correct_answer == null;

        assessment.is_editable = editable;

        return assessment;
    }

    function checkIfAssessmentStudentEditable(assessmentStudent) {
        var assessment = $scope.assessments[assessmentStudent.assessment_id];
        return assessment.is_editable;
    }

    function scrollAssessmentIntoView() {
        var assessmentId = $routeParams.focused_assessment_id;
        if (assessmentId) {
            var bucket = _.find($scope.buckets, bucket => _.includes(bucket.assessment_ids, assessmentId));
            bucket.open = true;
            var bucketId = bucket.objective.objective_id;
            return $timeout(() => {
                var assessmentColumn = angular.element(`#sbg-assessment-header-cell-objectiveId-${bucketId}-assessmentId-${assessmentId}`);
                var columnOffset = assessmentColumn.offset();
                var container = angular.element('.biaxial-scroll-rows');
                container.scrollLeft(columnOffset.left - container.offset().left);
            });
        }
    }

    function editAssessmentObjectiveScores() {
        var shouldEditScores = $routeParams.editing_scores == 1;
        if (shouldEditScores) {
            var assessmentId = $routeParams.focused_assessment_id;
            var assessment = _.chain($scope.buckets)
                .reduce((assessments, bucket) => {
                    return assessments.concat(bucket.assessments);
                }, [])
                .find({ assessment_id: assessmentId })
                .value();
            assessment.editingAssessmentObjectiveScores = 1;
            $rootScope.$broadcast('editingAssessmentObjectiveScores', assessment);
        }
    }

    function clearFocusedAssessmentParams() {
        $route.updateParams(_.extend({}, $routeParams, { focused_assessment_id: null, editing_scores: null }));
    }

    // get an array of all changed assessment students for this assessment id
    // from an array of students that looks like:
        // [
        //     {
        //         student_id: <id>,
        //         bucketed_assessments: [
        //             { assessment_objective_scores: [{},{},{}] },
        //             { assessment_objective_scores: [{},{},{}] },
        //         ]
        //     },
        //     {
        //         student_id: <id>,
        //         bucketed_assessments: [
        //             { assessment_objective_scores: [{},{},{}] },
        //             { assessment_objective_scores: [{},{},{}] },
        //         ]
        //     },
        // ]
    function getAssessmentStudentsForAssessment(assessment, ignoreChanged) {
        var filterBy = {
            assessment_id: assessment.assessment_id
        }

        if (!ignoreChanged) {
            filterBy['changed'] = true;
        }

        return _.chain($scope.students)
            .flatMap('bucketed_assessments')
            .flatMap('assessment_objective_scores')
            .flatMap('assessment_student')
            .filter(filterBy)
            .map(assessmentStudent => helpers.cleanAssessmentStudent(assessment, assessmentStudent, $scope.grading_scales, $routeParams.course_id, $scope.students))
            .value();
    }

    // get an array of all objective grades for this objective id
    // then limit to the data we care about for saving an objective grade
    function getObjectiveGradesForObjectiveBucket(bucket) {
        return _.chain($scope.students)
            .map('bucketed_assessments')
            .flatten()
            .filter(_.partial(bucketMatchesObjectiveAndHasChanged, bucket.objective.objective_id))
            .map(formatObjectiveGradeForSave)
            .value();
    }

    // we are only going to save objective grades for the given objective id,
    // and we only care about the ones that have an actual score or comment to save
    function bucketMatchesObjectiveAndHasChanged(objectiveId, objectiveBucket) {
        return objectiveBucket.objective_id == objectiveId
            && objectiveBucket.has_changed == true;
    }

    // dont care about most of the junk that's on each student's bucket object,
    // plus we need to add student id and term bin id for the post/put to work
    // it's ok if student_id isn't set for an update (e.g. won't be found on a standard bucket with no assessments)
    // because we have the objective_grade_id
    function formatObjectiveGradeForSave(objectiveBucket) {
        return {
            'objective_grade_id': _.get(objectiveBucket, 'objective_grade_id', null),
            'objective_id': _.get(objectiveBucket, 'objective_id'),
            'score_override': _.get(objectiveBucket, 'score_override'),
            'comment': _.get(objectiveBucket, 'comment'),
            'student_id': _.get(objectiveBucket, 'assessment_objective_scores[0].student_id'),
            'term_bin_id': $routeParams.term_bin_id
        };
    }

    function resetGradebookData() {
        $scope.assessments = null;
        $scope.assessmentsKeyed = null;
        $scope.students = null;
        $scope.grading_scales = null;
        $scope.course_grading_scale = null;

        $scope.filters = { sort: 'section_number' };
    }

    function setupWatches() {
        watches.push($scope.$on('settingsChanged', fetchDataKeepingOpenBuckets));
        watches.push($scope.$on('$routeUpdate', handleRouteChange));
        $scope.$on('$destroy', handleDestroy);
        $window.onbeforeunload = handleOnBeforeUnload;
    }

    function handleOnBeforeUnload() {
        var editingAssessment = _.find($scope.assessments, { editingAssessmentStudentScores: true });
        var modalIsVisible = angular.element('.ngdialog').length;

        if (editingAssessment || modalIsVisible) {
            return 'It looks like you have unsaved edits!';
        }
    }

    function handleRouteChange(event, route) {
        // we dont want to fetch data if the route change was only the result
        // of clearing the focused assessment (which we do so that the assessment
        // doesn't scroll into view every time we change filters)
        if (!_.get($route.previous, 'params.focused_assessment_id')) {
            promiseCanceler.resolve();
            resetGradebookData();
            fetchDataAndCloseDialog();
        };
        // extend so that previous route is a new object, otherwise it will
        // reference the same object as route and will change when route changes
        $route.previous = _.extend({}, route);
    }

    // count of standards plus number of assessments in open standard buckets
    function getVisibleCellCount() {
        return _.chain($scope.buckets)
            .reduce((sum, standard) => {
                var visibleAssessmentCount = 0;
                if (standard.open == true) {
                    visibleAssessmentCount = standard.assessments.length;
                }
                return sum + visibleAssessmentCount + 1;
            }, 0)
            .value();
    }

    function handleDestroy() {
        angular.forEach(watches, function(watch) {
            watch.call();
        });
    }

    function isBucketOpen(bucket) {
        var standardBucket = _.find($scope.buckets, (scopeBucket) => scopeBucket.objective.objective_id == bucket.objective_id);

        return standardBucket.open;
    }

    // if bucket and assessment provided, looking for avg assessmentObjectiveScore
    // if only bucket provided, looking for bucket gradebook_score
    function getSortKey(bucket, assessment) {
        var objectiveId = bucket.objective.objective_id;
        var assessmentId = assessment ? assessment.assessment_id : -1;
        return `bucket[${objectiveId}].assessments[${assessmentId}]`;
    }

    function sortStudents(sortKey) {
        // if removing sort, default to ordering by section and student
        if (sortKey == '') {
            $scope.students = _.orderBy($scope.students, ['section_number', 'display_name'], ['asc', 'asc']);
        } else {
            // `-section_number` would be desc, `section_number` would be asc
            var order = sortKey.substr(0, 1) == '-' ? 'desc' : 'asc';
            // sort by function because sortKey might be nested e.g. bucket.score
            $scope.students = _.orderBy($scope.students, _.partial(dataSort, sortKey), [order]);
        }
    }

    // if sorting by score, the sortKey will be something like bucket[10].assessments[4]
    // otherwise it will be a simple key on the student object
    function dataSort(sortKey, student) {
        // trim leading `-` from a col sorted descending
        sortKey = sortKey.replace(/^-/, '');
        var matches = sortKey.match(/bucket\[(\d+)\]\.assessments\[(-?\d+)\]/);
        if (matches && matches.length) {
            var objectiveId = matches[1];
            var bucket = _.find(student.bucketed_assessments, ['objective_id', objectiveId]);
            var assessmentId = matches[2];
            // assessmentId of -1 indicates we want the bucket, not a specific assessment
            if (assessmentId && assessmentId > -1) {
                var assessmentObjectiveScore = _.find(bucket.assessment_objective_scores, ['assessment_id', assessmentId]);
                var assessmentStudent = assessmentObjectiveScore.assessment_student;

                if (assessmentStudent.missing == 1 && assessmentStudent.active == 1) {
                    return -1;
                }
                if (assessmentStudent.active == 0) {
                    return -2;
                }

                return parseFloat(_.get(assessmentObjectiveScore, 'avg_score', -3));
            } else {
                return parseFloat(_.get(bucket, 'gradebook_score', -1));
            }
        }

        return _.get(student, sortKey);
    }

    function promiseIsNull() {
        return !fetchGradebookDataPromise;
    }

    function hasRequiredParams() {
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        return courseId && sectionIds && termBinId;
    }

    function goToCoursePage(goToGradingMethodologies) {
        $window.open('/course/' + $routeParams.course_id + (goToGradingMethodologies ? '#grading-methodologies' : ''), '_blank');
    }

    function openLinkInNewTab($event) {
        $event.preventDefault();
        $window.open($event.currentTarget.href, '_blank');
    }

    function getSelectedSectionNames() {
        var sectionIds = _.split($routeParams.section_ids, ',');
        return _.join(SectionService.getNamesForSectionIds(sectionIds), ', ');
    }

    function getColor(index) {
        var colors = ['blue','green','yellow','orange','red'];
        var numColors = colors.length;
        var idx = index;
        if (index > numColors - 1) {
            idx = index % numColors;
        }
        return colors[idx];
    }

    function getEditingClasses(buckets) {
        var className = '';

        _.each(buckets, (bucket) => {
            if (bucket.editingObjectiveGrades) {
                className = `${hasEditingCellsClass}${bucket.showComments ? ` ${editingWithCommentsClass}` : ''}`;
                return false;
            }
        });

        // if editing a bucket, no need to go through assessments
        if (className) {
            return className;
        } else {
            var assessments = _.chain(buckets)
                .map('assessments')
                .flatten()
                .value();

            _.each(assessments, (assessment) => {
                if (assessment.editingAssessmentObjectiveScores) {
                    className = `${hasEditingCellsClass}${assessment.showComments ? ` ${editingWithCommentsClass}` : ''}`;
                    return false;
                }
            });
            return className;
        }
    }

    /**
     * @return boolean
     */
    function getCurrentCourseUseStandardsBasedGrading() {
        var courseUsesStandardsBasedGrading = CourseService.getStandardsBasedGradingForCourseId($routeParams.course_id);

        return courseUsesStandardsBasedGrading != null
            ? courseUsesStandardsBasedGrading == 1
            : SettingService.get('use_standards_based_grading', 0) == 1;
    }

    //--------------------------
    // Socket Stuff
    //--------------------------

    function setupSocket() {
        $scope.socket = SocketService.init({
            scope: $scope,
            updateFunction: fetchDataKeepingOpenBuckets
        });
    }
}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
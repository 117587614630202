angular
    .module('services')
    .factory('StaffMemberService', [
        '$http',
    
function (
    $http
) {
    return {
        get: get,
    };

    function get(active = 1) {
        var uri = '/api/v1/staff-members?active=' + active;
        return $http.get(uri);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('SpencilLoaderController', [

        '$http',
       '$route',
function (
    $http,
    $route
) {
    var vm = this;

    vm.shouldShowLoadingIndicator = shouldShowLoadingIndicator;

    function shouldShowLoadingIndicator() {
        var gradebookRequests = _.filter($http.pendingRequests, request => request.url.match(/\/api\/v1\/gradebook/));
        return !$route.current || gradebookRequests.length;
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import io from 'socket.io-client';

angular
    .module('gradebook')
    .factory('SocketService', [ 
        '$timeout',
        '$location',
        '$routeParams', 
function (
    $timeout,
    $location,
    $routeParams
) {
    var courseIdListener = null;
    // this creates a debounced function that fires initially when called and
    // wont fire again if called within the time limit (30 seconds)
    var debouncedShowGradebookOutdated = _.debounce(setGradebookOutdated, 30 * 1000, { leading: true, trailing: false });

    return {
        init: init
    };

    function init(options) {
        options = _.extend({}, {
            namespace: '/',
            scope: {},
            updateFunction: _.noop
        }, options);

        var env = angular.element('.js-env');
        var token = env.data('token');

        // create socket and set update fun
        var socket = io(options.namespace);
        socket.updateFunction = options.updateFunction;

        // once first connection attempt was success/failure
        // mark socket as initialized.
        addListenerToSocket(socket, 'connect', () => {
            socket.initialized = true;
            socket.emit('authenticate', { token: token })
        });

        addListenerToSocket(socket, 'connect_error', () => {
            socket.initialized = true
        });

        addListenerToSocket(socket, 'authorized', () => {
            // add course listener
            addCourseListenerToSocket(socket, options.scope);
            options.scope.$on('$routeUpdate', () => addCourseListenerToSocket(socket, options.scope));
        });

        addListenerToSocket(socket, 'unauthorized', () => {
            socket.close();
        });

        // remove on scope destroy (when switching views)
        options.scope.$on('$destroy', destroyCourseIdListener);

        // return socket
        return socket;
    }

    function addListenerToSocket(socket, event, listener) {
        var angularizedFunction = createAngularizedFunction(listener);
        socket.on(event, angularizedFunction);
        return () => socket.off(event, angularizedFunction);
    }

    function createAngularizedFunction(func) {
        return (...args) => $timeout(() => func(...args));
    }

    function destroyCourseIdListener() {
        if (courseIdListener) {
            courseIdListener();
            courseIdListener = null;
        }
    }

    function addCourseListenerToSocket(socket, $scope) {
        var requiredParams = ['course_id', 'section_ids', 'term_bin_id'];
        var eventCallback = _.partial(onGradebookMessage, $scope);

        // Do we have all required params, create course listener if not set already (switching term)
        // If we change course, and already have a courseId listener, section_ids and term_bin params
        // are cleared meaning not all params exit and we will remove the old listener
        if (_.every(requiredParams, param => _.has($routeParams, param)) && !courseIdListener) {
            var eventName = `gradebook.course.${$routeParams.course_id}.updated`;
            courseIdListener = addListenerToSocket(socket, eventName, eventCallback);
        } else if (courseIdListener) {
            courseIdListener();
            courseIdListener = null;
        }
    }

    function onGradebookMessage($scope, message) {
        var currentTermBinId = $routeParams.term_bin_id;
        var data = JSON.parse(message);
        var hash = getUserHash();
        var messageHash = _.get(data, 'hash', '');

        // if current user performed action from gradebook
        if (hash == messageHash) {
            // on recalculating event, mark student grades as invalid
            if (_.get(data, 'event') == 'recalculating-grades' && data.term_bin_id == currentTermBinId) {
                var studentIds = _.map(data.student_ids, studentId => parseInt(studentId));
                _.chain($scope.students)
                    .filter(student => _.includes(studentIds, parseInt(student.student_id)))
                    .map(student => student.course_grade = _.extend({}, student.course_grade, { invalid: true }))
                    .value();
            }

            // on recalculated event, update student grades
            if (_.get(data, 'event') == 'recalculated-grades' && data.term_bin_id == currentTermBinId) {
                var studentIds = _.map(data.student_ids, studentId => parseInt(studentId));
                _.chain($scope.students)
                    .filter(student => _.includes(studentIds, parseInt(student.student_id)))
                    .map(student => {
                        var courseGrade = _.find(data.course_grades, { student_id: student.student_id });
                        if (_.isUndefined(courseGrade)) {
                            // all course grades go invalid when any scores are updated,
                            // but only grades that changed ever come back in this event,
                            // so we need to manually make the others valid to prevent
                            // foreverspin of 'loading' course grades in the gradebook
                            student.course_grade =
                                _.extend({}, student.course_grade, {
                                    invalid: false,
                                });
                            return student;
                        }

                        var gradingScaleLevel = _.find($scope.course_grading_scale.levels, {
                            grading_scale_level_id: courseGrade.grading_scale_level_id
                        });

                        student.course_grade =
                            _.extend({}, student.course_grade, {
                                invalid: false,
                                score: Math.round(courseGrade.score_override || courseGrade.score),
                                levelName: _.get(gradingScaleLevel, 'name'),
                                levelAbbreviation: _.get(gradingScaleLevel, 'abbreviation'),
                                levelIndex: _.get(gradingScaleLevel, 'levelIndex')
                            });
                        return student;
                    })
                    .value();
            }

            return;
        }

        // for recalculated events
        // if its the current selected term and if we have students in view that were updated, show message
        if (_.get(data, 'event') == 'recalculated-grades'
            && _.get(data, 'term_bin_id') == currentTermBinId
            && _.find($scope.students, student => _.includes(data.student_ids, student.student_id))) {
            debouncedShowGradebookOutdated($scope);
        }
    }

    function setGradebookOutdated($scope) {
        $timeout(() => $scope.socket.gradebookIsOutdated = true);
    }

    function getUserHash() {
        var env = angular.element('.js-env');
        var userId = env.data('user-id');
        var referrer = $location.absUrl();

        // btoa() = base64 encode
        return btoa(userId + referrer);
    }
}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('SortableHeaderCellController', [

function () {
    var vm = this;

    vm.setSort = setSort;
    vm.isSorted = isSorted;

    function setSort() {
        switch (vm.sortedColumn) {
            case vm.sortColumn:
                vm.sortedColumn = `-${vm.sortColumn}`;
                break;
            case `-${vm.sortColumn}`:
                vm.sortedColumn = '';
                break;
            default: 
                vm.sortedColumn = vm.sortColumn;
        }

        vm.sortFunc()(vm.sortedColumn);
    }

    function isSorted() {
        return vm.sortedColumn == vm.sortColumn || vm.sortedColumn == `-${vm.sortColumn}`;
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
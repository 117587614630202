angular
    .module('services')
    .factory('AssessmentTypeService', [
        '$httpParamSerializer', 
        '$http',

function (
    $httpParamSerializer, 
    $http
) {
    return {
        get: get,
    };

    // Gets assessment types for course
    function get(courseId, termBinId, date) {
        var params = $httpParamSerializer({
            course_id: courseId,
            term_bin_id: termBinId,
            date: date
        });
        var uri = '/api/v1/gradebook/assessment-types?' + params;

        return $http.get(uri);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import courseGradeCellTemplate from './course-grade-cell.html';
import './course-grade-cell.controller.js';

angular
    .module('common')
    .component('courseGradeCell', {
        bindings: {
            courseGrade: '<',
            courseGradingScale: '<',
        },
        controller: 'CourseGradeCellController as cell',
        templateUrl: courseGradeCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
var _module_exports = "<div\n    class=\"socket-notification\"\n    ng-class=\"{\n        'refreshed': notification.dataUpdated\n    }\"\n    ng-show=\"notification.socket.initialized && (notification.socket.connected == false || notification.socket.gradebookIsOutdated == true)\">\n    <div ng-hide=\"notification.dataUpdated\">\n        <a\n            class=\"close\"\n            ng-show=\"notification.settings.showClose\"\n            ng-click=\"notification.close()\">\n            &times;\n        </a>\n\n        <h6 class=\"title\">{{ notification.getNotificationTitle() }}</h6>\n        <p class=\"text\">{{ notification.getNotificationText() }}</p>\n        <button\n            class=\"btn btn-orange btn-small\"\n            ng-click=\"notification.updateData()\"\n            ng-hide=\"notification.updatingData\">\n            Refresh Gradebook\n        </button>\n        <button\n            class=\"btn btn-orange btn-small\"\n            disabled=\"disabled\"\n            ng-show=\"notification.updatingData\">\n            <i class=\"icon-spinner icon-spin\"></i>\n            Refreshing\n        </button>\n    </div>\n\n    <div ng-show=\"notification.dataUpdated\">\n        <p>Your gradebook data has been refreshed!</p>\n    </div>\n</div>";;
var path = '/views/gradebook/common/socket-notification/socket-notification.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
var _module_exports = "<div\n    class=\"spencil-loader-container\"\n    ng-show=\"loader.shouldShowLoadingIndicator()\">\n    <div class=\"spencil-loader with-loader-message\">\n        <span class=\"spencil-loader-message\">Loading Gradebook Data</span>\n    </div>\n</div>";;
var path = '/views/gradebook/common/spencil-loader/spencil-loader.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
angular
    .module('services')
    .factory('GradingScalesService', [
        '$httpParamSerializer', 
        '$http',

function (
    $httpParamSerializer, 
    $http
) {
    return {
        getPrimaryGradingScaleForGradingScaleSetId: getPrimaryGradingScaleForGradingScaleSetId,
    };

    // gets granding scales
    function getPrimaryGradingScaleForGradingScaleSetId(grading_scale_set_id) {
        var params = $httpParamSerializer({
            grading_scale_set_id: grading_scale_set_id,
        });
        var uri = '/api/v1/gradebook/grading-scales?' + params;

        return $http.get(uri)
            .then(_.property('results.grading_scale'));
    }

}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('ExternalIntegrationsController', [
    '$scope',
    '$rootScope',
    'GoogleClassroomService',
    'EdlinkService',

function (
    $scope,
    $rootScope,
    GoogleClassroomService,
    EdlinkService
) {
    var vm = this;

    vm.$onInit = init;
    vm.showCourses = showCourses;

    function init() {
        vm.scrollBarWidth = _.scrollBarWidth();

        // if the integration's already set (because there was only one or we
        // already chose one once)
        if (vm.externalIntegration) {
            showCourses(vm.externalIntegration);
        }
    }

    function showCourses(externalIntegration) {
        vm.externalIntegration = externalIntegration;
        $rootScope.lastExternalIntegration = externalIntegration; // cache for next time we launch the dialog
        vm.externalService = vm.externalIntegration.service;
        vm.nextPage();
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('gradebook', [
        'common',
        'services',
        'ngDialog',
        'ngRoute',
        'pikaday',
        'KeyCodes.Service'
    ]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import externalAssessmentsTemplate from './external-assessments.html';
import './external-assessments.controller.js';

angular
    .module('common')
    .component('externalAssessments', {
        bindings: {
            closeThisDialog: '&',
            previousPage: '&',
            nextPage: '&',
            selectedTermBin: '<',
            externalService: '<',
            externalProfile: '=',
            externalCourse: '<',
            externalAssessment: '=',
            externalIntegration: '<',
        },
        controller: 'ExternalAssessmentsController as externalAssessments',
        templateUrl: externalAssessmentsTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
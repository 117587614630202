import toolbarTemplate from './toolbar.html';
import './toolbar.controller.js';

angular
    .module('common')
    .component('toolbar', {
        controller: 'ToolbarController as toolbar',
        templateUrl: toolbarTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
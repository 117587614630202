angular
    .module('services')
    .factory('AssessmentService', [
        'AssessmentAnswerService',
        'AssessmentStudentService',
        '$http',
        '$q',

function (
    AssessmentAnswerService,
    AssessmentStudentService,
    $http,
    $q
) {

    var transformConfig = {
        transformResponse: function (data) {
            // remove info message so we dont get a growl
            return _.extend({}, JSON.parse(data), { info: [] });
        }
    };

    return {
        post,
        put,
        updateSections,
        recalculateScores,
        saveScores
    };

    function post(data) {
        var uri = '/api/v1/assessments/';
        return $http.post(uri, data, transformConfig)
            .then(handlePostPutResponse);
    }

    function put(assessmentId, data) {
        var uri = '/api/v1/assessments/' + assessmentId;

        return $http.put(uri, data, transformConfig)
            .then(handlePostPutResponse);
    }

    function handlePostPutResponse(response) {
        if (response && response.success) {
            return _.get(response, 'results.assessment');
        }

        // short-circuit promise chain.
        // error growls already displayed by http-interceptor.
        throw new Error("Assessment save failed");
    }

    function updateSections(assessmentId, data) {
        var uri = '/api/v1/assessments/update-sections/' + assessmentId;

        return $http.put(uri, data, transformConfig)
            .then(_.property('data'));
    }

    function recalculateScores(assessmentIds) {
        var uri = '/api/v1/bulk/assessments/recalculate-scores';

        $http.post(uri, { assessment_ids: assessmentIds }, transformConfig);

        // return empty promise instead of the post because we really want this to
        // resolve but be async so the user doesn't have to wait for a long (2min?)
        // calculation that doesn't affect the gradebook
        return $q.when();
    }

    function saveScores(assessmentStudents) {
        var assessmentIds = _.chain(assessmentStudents)
            .map('assessment_id')
            .uniq()
            .value();

        // assessment answers are only relevant for assessment_students where
        // we're editing actual results, not overriding (aka one-question)
        // in standards-view, all editable assessments will be one-question so
        // we won't have a for_override flag on a per-assessment basis
        var assessmentAnswers = _.chain(assessmentStudents)
            .filter((assessmentStudent) => assessmentStudent.for_override == false || assessmentStudent.for_override == undefined)
            .map(buildAssessmentAnswerObject)
            .value();

        var requests = [
            AssessmentAnswerService.updateBulk(assessmentAnswers),
            AssessmentStudentService.updateBulk(assessmentStudents),
        ];

        return $q.all(requests)
            // has to happen after others because it uses assessment_students for calculations
            .then(() => recalculateScores(assessmentIds))
        }

    function buildAssessmentAnswerObject(assessmentStudent) {
        var answerObj = {
            assessment_answer_id: assessmentStudent.assessment_answer_id,
            student_id: assessmentStudent.student_id,
            assessment_id: assessmentStudent.assessment_id,
            assessment_question_id: assessmentStudent.assessment_question_id,
            points: assessmentStudent.avg_score,
            active: assessmentStudent.missing ? 0 : assessmentStudent.active
        }

        if (assessmentStudent.enter_grades_by_level == 1) {
            answerObj.answer = assessmentStudent.levelName;
        }

        return answerObj;
    }

}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('services')
    .factory('SettingService', [
        '$http',

function (
    $http
) {
    var uri = '/api/v1/settings';
    var settings = {};
    var settingsPromise = null;
    var displaySettingKeys = {
        gradebook_view: 'timeline',
        gradebook_assessment_date_order: 'desc',
        gradebook_grade_color_coding: 1,
        gradebook_score_display: 'score',
        gradebook_google_classroom_match_name_only: 0,
        show_gradebook_google_classroom_match_name_only: 0,
    };

    init();

    return {
        loadAll: loadAll,
        get: get,
        getDisplaySettings: getDisplaySettings,
        save: save
    };

    function init() {
        settingsPromise = $http.get(uri)
            .then(response => {
                settings = _.get(response, 'results.settings[""]');
                return settings;
            });
    }

    function loadAll() {
        return settingsPromise;
    }

    function get(key, defaultValue = null) {
        const val = _.get(settings, key, defaultValue);

        return val === null ? defaultValue : val;
    }

    function getDisplaySettings() {
        var settings = {};
        _.each(displaySettingKeys, (defaultValue, settingKey) => {
            settings[settingKey] = this.get(settingKey, defaultValue);
        });
        return settings;
    }

    function save(newSettings, districtId = null, schoolId = null, userId = null) {
        return $http({
                method: 'POST',
                url: uri,
                data: {
                    settings: newSettings,
                    district_id: districtId,
                    school_id: schoolId,
                    user_id: userId
                },
                transformResponse: function (data) {
                    // remove info message so we dont get a growl
                    return _.extend({}, JSON.parse(data), { info: [] });
                }
            })
            .then(response => {
                _.each(newSettings, (settingValue, settingKey) => settings[settingKey] = settingValue);
                return response;
            });
    }
}])
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
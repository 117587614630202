import tssSelectTemplate from './tss-select.html';

angular
    .module('common')
    .directive('tssSelect', [ '$timeout',
        '$document',
        '$sce', function (
    $timeout,
    $document,
    $sce,
) {
    return {
        restrict: 'E',
        require: 'ngModel',
        scope: {
            ngDisabled: '&',
            ngFocus: '&',
            ngBlur: '&',
            ngModel: '=',
            onChange: '&',
            tssSelectSettings: '=',
            tssSelectOptions: '=',
        },
        templateUrl: tssSelectTemplate,
        link: tssSelectLink
    };

    function tssSelectLink($scope, $element) {
        $scope.settings = $scope.tssSelectSettings = angular.extend({
            loadingSpinner: false,
            multi: false,
            placeholder: 'No Option Selected',
            valueKey: 'value',
            labelKey: 'label',
            groupKey: 'group',
            icon: _.noop
        }, $scope.tssSelectSettings || {});
        $scope.tssSelectOptions = $scope.tssSelectOptions || null;

        $scope.toggleOpen = toggleOpen;
        $scope.toggleOption = toggleOption;
        $scope.getButtonLabel = getButtonLabel;
        $scope.isSelected = isSelected;
        $scope.isDisabled = isDisabled;
        $scope.clearValue = clearValue;
        $scope.hasOptions = hasOptions;
        $scope.selectAll = selectAll;
        $scope.getOptionIcon = getOptionIcon;
        $scope.filteredOptions = {};

        // Build options
        $scope.$watch('tssSelectOptions', buildOptions);

        function toggleOpen() {
            $scope.open = !$scope.open;

            if ($scope.open) {
                // Will occur after digest
                $timeout(function() {
                    $element.find('input').focus();
                });
            }
        };

        function toggleOption(option) {
            if (option.isGroup) {
                return;
            }

            if (isDisabled(option)) {
                return;
            }

            var isSelected = $scope.isSelected(option);

            if ($scope.settings.multi) {
                if (isSelected) {
                    $scope.ngModel = _.without($scope.ngModel, option.value);
                } else {
                    $scope.ngModel = _.isArray($scope.ngModel) ? $scope.ngModel : [];
                    $scope.ngModel.push(option.value);
                }
            } else {
                $scope.ngModel = isSelected ? null : option.value;

                $scope.open = false;
            }

            // ngModel wont propogate back until digest cycle completes
            // so we need to make sure that happens before we try to use that value
            fireOnChange();
        };

        // Gets button label or placeholder if label is null
        function getButtonLabel() {
            var values = $scope.settings.multi ? ($scope.ngModel || []) : ($scope.ngModel ? [$scope.ngModel] : []);

            var flattenedOptions = _.reduce($scope.groupedOptions, (flattenedOptions, value, key) => {
                return flattenedOptions.concat(value);
            }, []);

            var labels = _.chain(values)
                .map(value => {
                    var option = _.find(flattenedOptions, { value: value });
                    var label = _.get(option, 'label');

                    if (!label) {
                        return label;
                    }

                    var iconString = getOptionIcon(option)
                    var iconHtml = iconString ? ` <i class="${iconString}"></i>` : '';
                    return label + iconHtml;
                })
                .filter(label => label != undefined)
                .value();

            return $sce.trustAsHtml(labels.length ? labels.join(', ') : $scope.settings.placeholder);
        }

        // Gets option is selected
        function isSelected(option) {
            if (option.isGroup) {
                return false;
            }
            if ($scope.settings.multi) {
                return _.indexOf($scope.ngModel || [], option.value) !== -1;
            } else {
                return $scope.ngModel == option.value;
            }
        };

        function isDisabled(option) {
            return _.get(option, 'option_disabled', false);
        }

        function clearValue() {
            $scope.ngModel = $scope.settings.multi ? [] : null;

            if (!$scope.settings.multi) {
                $scope.open = false;
            }

            // ngModel wont propogate back until digest cycle completes
            // so we need to make sure that happens before we try to use that value
            fireOnChange();
        };

        function buildOptions() {
            var sortedOptions = _.chain($scope.tssSelectOptions)
                .map(option => {
                    return _.extend({}, option, {
                        value: option[$scope.settings.valueKey],
                        label: option[$scope.settings.labelKey],
                    });
                })
                .orderBy(_.get($scope.settings, 'orderKey'))
                .value();
            var optionsAndGroups = Functions.getGroupedOptions(sortedOptions, $scope.settings.groupKey);

            $scope.groupedOptions = optionsAndGroups.groupedOptions;
            $scope.groupedOptionKeys = optionsAndGroups.groupedOptionKeys;
        }

        function hasOptions() {
            return _.has($scope.tssSelectOptions, 'length');
        }

        function handleDestroyEvent() {
            $document.off('mousedown', handleDocumentMouseDown);
        }

        // Closes on all document clicks outside of this select
        function handleDocumentMouseDown(event) {
            var $target = angular.element(event.target);

            if ($scope.open && !$target.closest($element).length) {
                $timeout(function() {
                    $scope.open = false;
                });
            }
        }

        function selectAll() {
            var flattenedOptions = _.reduce($scope.filteredOptions, (all, values) => {
                return all.concat(values)
            }, []);

            $scope.ngModel = _.map(flattenedOptions, 'value');

            // ngModel wont propogate back until digest cycle completes
            // so we need to make sure that happens before we try to use that value
            fireOnChange();
        }

        function fireOnChange() {
            var onChange = $scope.onChange() || _.noop;
            $timeout(() => {
                onChange($scope.ngModel);
            });
        }

        function getOptionIcon(option) {
            return _.invoke($scope.settings, 'icon', option);
        }

        // Event bindings
        $document.on('mousedown', handleDocumentMouseDown);
        $scope.$on('$destroy', handleDestroyEvent);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('services')
    .factory('HttpInterceptor',[
        'AlertService',
    
function (
) {
    return {
        response: response,
        responseError: responseError,
    }

    function response(res) {
        const contentType = res.headers('content-type') || '';

        handleResponse(res);

        return 'data' in res && contentType.indexOf('application/json') >= 0
            ? res.data
            : res;
    }

    function responseError(err) {
        handleResponse(err);

        return 'data' in err
            ? err.data
            : err;
    }

    function handleResponse(response) {
        handleErrors(response);
        handleInfo(response);
    }

    function handleErrors(response) {
        var data = _.get(response, 'data');
        var errors =
            _.isArray(data) // handle bulk responses
                ? _.chain(data).pluck('errors').flatten().uniq().value()
                : _.get(data, 'errors')
        ;

        angular.forEach(errors || [], function(error) {
            AlertService.error(error);
        });
    }

    function handleInfo(response, defaultInfo) {
        var messages = _.get(response, 'data.info', []);

        angular.forEach(messages, function(message) {
            AlertService.info(message);
        });
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
var _module_exports = "<div class=\"tss-switch tss-switch-ng\">\n    <div \n        class=\"on\"\n        ng-repeat=\"option in ::options\"\n        ng-click=\"setModel(option.value)\"\n        ng-class=\"{\n            'selected': option.value == ngModel\n        }\">\n        {{ ::option.label }}\n    </div>\n</div>\n";;
var path = '/views/gradebook/common/tss-switch/tss-switch.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
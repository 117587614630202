import studentCellTemplate from './assessment-objective-score-cell.html';
import * as helpers from '../../../helpers';

angular
    .module('common')
    .directive('assessmentObjectiveScoreCell', [
        'ngDialog',
        'SettingService',
        '$keyCodes',
        '$rootScope',
        '$timeout',
        
    function (
        ngDialog,
        SettingService,
        $keyCodes,
        $rootScope,
        $timeout
) {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            assessment: '<',
            index: '<',
            assessmentObjectiveScore: '=',
            gradingScale: '<'
        },
        templateUrl: studentCellTemplate,
        link: assessmentObjectiveScoreCellLink
    };

    function assessmentObjectiveScoreCellLink($scope, $element) {
        var watches = [];
        var clonedAssessmentObjective;
        var assessmentId = $scope.assessment.assessment_id;
        $scope.modelKey = 'avg_score';
        $scope.placeholderKey = 'avg_score';
        $scope.assessment.showComments = false;
        $scope.toggleComments = toggleComments;

        $scope.presentMissingOrExcused = getPresentMissingOrExcused();
        $scope.scoreColor = getScoreColor();
        $scope.displayName = getDisplayName();
        $scope.titleName = getTitleName();
        $scope.assessmentObjectiveScore.assessment_student.enter_grades_by_level = $scope.assessment.enter_grades_by_level;
        $scope.gradingScaleLevelSelectOptions = getGradingScalelevelOptions();


        init();

        function init() {
            $scope.$on('focusAssessmentObjective.' + $scope.index, focusInput);
            $scope.$on('editingAssessmentObjectiveScores', onEditingAssessmentObjectiveScores);
            $scope.$on('cancelEditingAssessmentObjectiveScores.' + assessmentId, revertAssessmentObjective);
            $scope.$on('$destroy', handleDestroy);
        }

        function handleDestroy() {
            clonedAssessmentObjective = null;
        }

        function onEditingAssessmentObjectiveScores(event, assessment) {
            if ($scope.assessment != assessment) {
                return;
            }

            // clone assessment objective score for reverting
            clonedAssessmentObjective = _.cloneDeep($scope.assessmentObjectiveScore);

            // put focus in first element
            if ($scope.index == 0) {
                focusInput();
            }
        }

        function revertAssessmentObjective() {
            // we want to overwrite the original object here so that angular
            // knows to update this assessment objective score in the parent view
            _.extend($scope.assessmentObjectiveScore, clonedAssessmentObjective);
        }

        function focusInput() {
            $timeout(() => $element.find('input').select());
        }

        function getGradingScalelevelOptions() {
            var gradingScaleLevels = [];
            // if not entering grades by level, we're done here
            if ($scope.assessment.enter_grades_by_level == 1) {
                var allowedGradingLevelIds = _.chain($scope.assessment.allowed_grading_scale_level_ids)
                    .split(',')
                    .compact()
                    .value();

                // if no specifically-allowed levels, all levels are allowed
                // otherwise, only levels in the allowed list are allowed
                if (_.isEmpty(allowedGradingLevelIds)) {
                    gradingScaleLevels = $scope.gradingScale.levels;
                } else {
                    gradingScaleLevels = _.chain($scope.gradingScale.levels)
                    .filter(level => {
                        return _.includes(allowedGradingLevelIds, level.grading_scale_level_id);
                    })
                    .value();
                }
            }

            return gradingScaleLevels;
        }

        function getScoreColor() {
            if ($scope.presentMissingOrExcused == 'present') {
                var gradingScaleLevelIndex = $scope.assessmentObjectiveScore.levelIndex;
                return parseInt(SettingService.get('gradebook_grade_color_coding', 1)) == 1
                    ? $scope.gradingScale.colorScale(gradingScaleLevelIndex)
                    : null;
            }
            return null;
        }

        function getScoreDisplayMode() {
            return SettingService.get('gradebook_score_display', 'score');
        }

        // excused == inactive
        // missing == active + missing
        // present == active + !missing
        function getPresentMissingOrExcused() {
            var status = 'excused';
            if (_.get($scope.assessmentObjectiveScore, 'assessment_student.active') == 1) {
                if (_.get($scope.assessmentObjectiveScore, 'assessment_student.missing') == 1) {
                    status = 'missing';
                } else {
                    status = 'present';
                }
            }
            return status;
        }

        // if present, display actual grade
        // otherwise, display missing or excused
        function getTitleName() {
            if ($scope.presentMissingOrExcused == 'present') {
                var levelName = _.get($scope.assessmentObjectiveScore, 'levelName', '');
                var levelAbbreviation = _.get($scope.assessmentObjectiveScore, 'levelAbbreviation', null);
                var roundedScore = helpers.getRoundedScore($scope.assessmentObjectiveScore, 'avg_score');

                return `${!_.isNull(roundedScore) ? `${levelName}${levelAbbreviation ? ` (${levelAbbreviation})` : ''} ${roundedScore}%` : ''}`;
            }
            return _.startCase($scope.presentMissingOrExcused);
        }

        // if present, display actual grade
        // otherwise, display missing or excused
        function getDisplayName() {
            if ($scope.presentMissingOrExcused == 'present') {
                var levelName = _.get($scope.assessmentObjectiveScore, 'levelName', '');
                var levelAbbreviation = _.get($scope.assessmentObjectiveScore, 'levelAbbreviation', null);
                var roundedScore = helpers.getRoundedScore($scope.assessmentObjectiveScore, 'avg_score');

                switch (getScoreDisplayMode()) {
                    case 'score':
                        return `${!_.isNull(roundedScore) ? `${roundedScore}%` : ''}`;
                    case 'grade-level':
                        return levelAbbreviation || levelName;
                    case 'score-and-grade-level':
                        return `${!_.isNull(roundedScore) ? `${roundedScore}% ${levelAbbreviation || levelName}` : ''}`;
                }
            }
            return $scope.presentMissingOrExcused;
        }

        // if you're NOT editing scores and you click a comment bubble, show the comment
        // if you ARE editing scores and you click a comment bubble, open comments for edit
        function toggleComments(event) {
            focusInput();
            if ($scope.assessment.editingAssessmentObjectiveScores) {
                $scope.assessment.showComments = !$scope.assessment.showComments;
            }
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
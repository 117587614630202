var _module_exports = "<div\n    ng-click=\"headerCell.setSort()\"\n    class=\"sortable-header-cell\"\n    ng-class=\"{\n        'sorted': headerCell.isSorted(),\n    }\">\n    <ng-transclude />\n    <i\n        ng-class=\"{\n            'icon-long-arrow-up': headerCell.sortedColumn == headerCell.sortColumn || !headerCell.isSorted(),\n            'icon-long-arrow-down': headerCell.sortedColumn == '-' + headerCell.sortColumn,\n            'preview': !headerCell.isSorted()\n        }\">\n    </i>\n</div>\n";;
var path = '/views/gradebook/common/sortable-header-cell/sortable-header-cell.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
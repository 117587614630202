angular
    .module('services')
    .factory('AssessmentStudentService', [
        '$http',
        '$httpParamSerializer', 
    function (
        $http,
        $httpParamSerializer
    ) {

    return {
        get,
        post,
        updateBulk
    };

    function get(params, options) {
        var uri = '/api/v1/assessment-students';
        return $http.get(uri + '?' + $httpParamSerializer(params), options)
            .then(_.property('results.assessment_students'))
    };

    function post(data, options) {
        var uri = '/api/v1/assessment-students/old';
        return $http.post(uri, data, options)
            .then(handlePostResponse);
    }

    function handlePostResponse(response) {
        if (response && response.success) {
            return response;
        }

        // short-circuit promise chain.
        // error growls already displayed by http-interceptor.
        throw new Error("Assessment Students save failed");
    }

    // update multiple assessment students at once, creating new ones where needed
    function updateBulk(data, options) {
        var uri = '/api/v1/bulk/assessment-students';
        return $http.put(uri, { bulk_data: data }, options);
    }
 }])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
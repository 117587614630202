angular
    .module('services')
    .factory('AssessmentQuestionService', [
        '$http',

function (
    $http,
) {

    var transformConfig = {
        transformResponse: function (data) {
            // remove info message so we dont get a growl
            return _.extend({}, JSON.parse(data), { info: [] });
        }
    };

    return {
        post: post,
        put: put,
        getAll: getAll,
        getOne: getOne,
        updateBulk: updateBulk,
    };

    function post(assessmentId, data) {
        var uri = '/api/v1/assessments/' + assessmentId + '/questions';

        return $http.post(uri, data, transformConfig)
            .then(_.property('results.assessment_question'));
    }

    function put(assessmentId, assessmentQuestionId, data) {
        var uri = '/api/v1/assessments/' + assessmentId + '/questions/' + assessmentQuestionId;

        return $http.put(uri, data, transformConfig)
            .then(_.property('results.assessment_question'));
    }

    function getAll(assessmentId) {
        var uri = '/api/v1/assessments/' + assessmentId + '/questions';

        return $http.get(uri)
            .then(_.property('results.assessment_questions'));
    }

    function getOne(assessmentId, assessmentQuestionId) {
        var uri = '/api/v1/assessments/'
            + assessmentId
            + '/questions/'
            + assessmentQuestionId;

        return $http.get(uri)
            .then(_.property('results.assessment_question'));
    }

    // update multiple assessment questions at once, creating new ones where needed
    function updateBulk(data, options) {
        var uri = '/api/v1/bulk/assessment-questions';
        return $http.put(uri, { bulk_data: data }, options);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
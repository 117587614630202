import tssCheckboxTemplate from './tss-checkbox.html';

angular
    .module('common')
    .directive('tssCheckbox', [

function () {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            ngModel: '=',
            tssCheckboxSettings: '='
        },
        templateUrl: tssCheckboxTemplate,
        link: tssCheckboxLink
    };

    function tssCheckboxLink($scope) {
        $scope.settings = angular.extend({
            label: '',
            right: false,
        }, $scope.tssCheckboxSettings || {});

        $scope.toggle = toggle;
        $scope.isChecked = isChecked;

        function toggle() {
            $scope.ngModel = !$scope.ngModel;
        };

        function isChecked() {
            return $scope.ngModel;
        };
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('SocketNotificationController', [
        '$timeout', 

function (
    $timeout
) {
    var vm = this;

    vm.getNotificationTitle = getNotificationTitle;
    vm.getNotificationText = getNotificationText;
    vm.updateData = updateData;
    vm.close = close;

    init();

    function init() {
        vm.settings = _.extend({}, {
            showClose: _.get(vm, 'showClose', true),
            offlineTitle: _.get(vm, 'offlineTitle', 'Live Updates Offline'),
            offlineText: _.get(vm, 'offlineText', 'We\'re currently unable to notify you of changes to grades. Use this button to refresh manually.'),
            outOfDateTitle: _.get(vm, 'outOfDateTitle', 'Grade Updates Available'),
            outOfDateText: _.get(vm, 'outOfDateText', 'Some of these grades have changed since you loaded this page. Use this button to view the latest data.'),
        });
    }

    function getNotificationTitle() {
        return vm.socket.connected ? vm.settings.outOfDateTitle : vm.settings.offlineTitle;
    }

    function getNotificationText() {
        return vm.socket.connected ? vm.settings.outOfDateText : vm.settings.offlineText;
    }

    function updateData() {
        setUpdatingData(true);

        vm.socket.updateFunction()
            .then(gradebookDataHasBeenRefreshed)
            .then(closeIfConnected)
            .then(() => setUpdatingData(false));
    }

    function setUpdatingData(value) {
        vm.updatingData = value;
    }

    function gradebookDataHasBeenRefreshed() {
        vm.dataUpdated = true;
        return $timeout(() => vm.dataUpdated = false, 3000);
    }

    function closeIfConnected() {
        if (vm.socket && vm.socket.connected) {
            close();
        }
    }

    function close() {
        vm.socket.connected = true;
        vm.socket.gradebookIsOutdated = false;
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('services')
    .factory('SectionService', [
        '$http',
        '$httpParamSerializer',

function (
    $http,
    $httpParamSerializer
) {
    return {
        get: get,
        getForSectionIds: getForSectionIds,
        getNamesForSectionIds: getNamesForSectionIds,
        getSectionPeriodIdsForSectionIds: getSectionPeriodIdsForSectionIds
    };

    var sections = [];

    function get(schoolId, courseId, termId, staffMemberId) {
        var params = $httpParamSerializer({
            'school_ids': schoolId,
            'course_ids': courseId,
            'term_ids': termId,
            'my_staff_member_ids': staffMemberId,
            'exclude_empty_sections': 1
        });

        var uri = '/api/v1/section-summary?' + params;
        return $http.get(uri)
            .then(setSections);
    }

    function setSections(response) {
        sections = _.get(response, 'results.sections');
        return sections;
    }

    function findById(sectionId) {
        return _.find(sections, { section_id: sectionId });
    }

    function getForSectionIds(sectionIds) {
        return _.map(sectionIds, findById);
    }

    function getNamesForSectionIds(sectionIds) {
        return _.map(sectionIds, sectionId => {
            var section = findById(sectionId);
            return _.get(section, 'display_name');
        });
    }

    function getSectionPeriodIdsForSectionIds(sectionIds) {
        return _.chain(sectionIds)
            .map(findById)
            .map('section_period_ids')
            .reduce((allPeriodIds, sectionPeriodId) => {
                return allPeriodIds.concat(sectionPeriodId);
            }, [])
            .value();
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
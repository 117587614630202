angular
    .module('services')
    .factory('GoogleClassroomService', [
    '$http',
    '$httpParamSerializer',
    '$q',
    '$route',
    '$timeout',
    'AssessmentStudentService',
    'SettingService',

function (
    $http,
    $httpParamSerializer,
    $q,
    $route,
    $timeout,
    AssessmentStudentService,
    SettingService
) {
    // Array of API discovery doc URLs for APIs used by the quickstart
    var GOOGLE_CLASSROOM_DISCOVERY_DOCS = [
        "https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest"
    ];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var GOOGLE_CLASSROOM_SCOPES = [
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/classroom.courses.readonly",
        "https://www.googleapis.com/auth/classroom.rosters.readonly",
        "https://www.googleapis.com/auth/classroom.student-submissions.students.readonly",
    ];

    var GOOGLE_CLASSROOM_SENSITIVE_SCOPES = [
        "https://www.googleapis.com/auth/classroom.profile.emails",
        "https://www.googleapis.com/auth/classroom.profile.photos",
    ];

    return {
        init: init,
        getCourses: getCourses,
        getAssessments: getAssessments,
        getStudents: getStudents,
        getAssessmentStudents: getAssessmentStudents,
        getProfile: getProfile,
        handleUseAnotherAccount: handleUseAnotherAccount,
        normalizeStudents: normalizeStudents,
        normalizeAssessment: normalizeAssessment,
        normalizeCourse: normalizeCourse,
        normalizeAssessmentStudents: normalizeAssessmentStudents,
    };

    /**
    *  On load, called to load the auth2 library and API client library.
    */
    function init() {
        var d = $q.defer();
        var env = angular.element('.js-env');
        var apiKey = env.data('google-api-key');
        var clientId = env.data('google-client-id');

        gapi.load('client:auth2', () => handleGoogleClassroomAuth(apiKey, clientId).then(d.resolve));

        return d.promise;
    }

    /**
    *  Initializes the API client library and sets up sign-in state
    *  listeners.
    */
    function handleGoogleClassroomAuth(apiKey, clientId) {
        // deafult is to ask for student emails
        // don't ask for them if they've turned on name only matching
        var nameOnly = SettingService.get('gradebook_google_classroom_match_name_only') == 1;
        var scopes = nameOnly
            ? GOOGLE_CLASSROOM_SCOPES
            : GOOGLE_CLASSROOM_SCOPES.concat(GOOGLE_CLASSROOM_SENSITIVE_SCOPES);

        // Check if gapi.auth2 has already been initialized
        var authInstance = gapi.auth2.getAuthInstance();
        
        if (!authInstance) {
            // If not initialized, proceed with the initialization
            var initGoogleClient = gapi.client.init({
                apiKey: apiKey,
                clientId: clientId,
                discoveryDocs: GOOGLE_CLASSROOM_DISCOVERY_DOCS,
                scope: scopes.join(' '), 
                enable_granular_consent: true,  // Enable granular permissions consent
                include_granted_scopes: true    // Include already granted scopes
            });
    
            return $q.when(initGoogleClient)
                .then(attemptGoogleSignInIfNecessary);

        } else {
            // If already initialized, just proceed with the sign-in process
            return $q.when(attemptGoogleSignInIfNecessary());
        }
    }

    /**
     * Called when the signed in status changes, to update the UI
     * appropriately. After a sign-in, the API is called.
     */
    function attemptGoogleSignInIfNecessary() {
        return isSignedIn() ? handleSuccessfulSignIn() : showGoogleSignInPrompt();
    }

    function isSignedIn() {
        return gapi.auth2.getAuthInstance().isSignedIn.get();
    }

    function showGoogleSignInPrompt() {
        // select_account ensures that even if you're only signed in to one
        // account currently it still shows you the popup. if you leave this
        // param out then it'll just immediately close the popup.
        var signInOptions = {
            prompt: 'select_account'
        };

        return $q.when(gapi.auth2.getAuthInstance().signIn(signInOptions))
            .then(validateSelectedScopes, handleFailedSignIn);
    }

    function validateSelectedScopes(user) {
        
        var grantedScopes = user.getGrantedScopes(); // Retrieve the list of scopes (permissions) that have been granted by the user
        
        var requiredScopes = Object.values(GOOGLE_CLASSROOM_SCOPES).concat(Object.values(GOOGLE_CLASSROOM_SENSITIVE_SCOPES));
        // Combine the required and sensitive scopes into a single array to form the full list of necessary permissions
    
        var missingScopes = requiredScopes.filter(scope => !grantedScopes.includes(scope));
        // Identify any scopes that are required but have not been granted by the user
    
        if (missingScopes.length > 0) {
            // If there are any missing scopes, handle the error
            
            showPermissionError(); // Display an error message indicating that the necessary permissions were not granted
            return attemptGoogleSignOut().then(handleFailedSignIn); 
            // Sign out the user and handle the failed sign-in attempt due to missing permissions
        }
    
        return handleSuccessfulSignIn(); // If all required scopes are granted, proceed with the successful sign-in process
    }

    function attemptGoogleSignOut() {
        return $q.when(gapi.auth2.getAuthInstance().signOut());
    }

    function handleSuccessfulSignIn() {
        return $q.when(getProfile(true));
    }

    function handleFailedSignIn() {
        return $q.when(getProfile(false));
    }

    function showPermissionError() {
        Growl.error({
            message: "Schoolrunner’s Google Classroom integration requires all requested permissions. Please re-authenticate and select all requested permissions."
        });
    }

    function handleUseAnotherAccount() {
        return attemptGoogleSignOut()
            .then(showGoogleSignInPrompt);
    }

    function getCourses() {
        let api = gapi.client.classroom.courses;
        let params = {
            teacherId: 'me', // only courses I created so that we don't get a 403 when trying to load assessments for this course
            courseStates: ['ACTIVE'] // NOT ARCHIVED, PROVISIONED, DECLINED
        };

        return getFullResource(api, params, 'courses', 'Courses');
    }

    function getAssessments(googleCourse) {
        let api = gapi.client.classroom.courses.courseWork;
        let params = {
            courseId: googleCourse.id
        };

        return getFullResource(api, params, 'courseWork', 'Assessments');
    }

    function getStudents(googleCourse) {
        let api = gapi.client.classroom.courses.students;
        let params = {
            courseId: googleCourse.id
        };

        return $q.when(getFullResource(api, params, 'students', 'Students'))
            .then(normalizeStudents);
    }

    function normalizeStudents(students) {
        return _.chain(students)
            .each(normalizeStudent)
            .keyBy('userId')
            .value();
    }

    function normalizeStudent(externalStudent) {
        externalStudent.email = externalStudent.profile.emailAddress;
        externalStudent.firstLast = externalStudent.profile.name.fullName;
        externalStudent.imageUrl = externalStudent.profile.photoUrl;
        externalStudent.externalId = externalStudent.userId;
    }

    function getAssessmentStudents(googleCourse) {
        let api = gapi.client.classroom.courses.courseWork.studentSubmissions;
        let params = {
            courseId: googleCourse.id,
            courseWorkId: '-'
        };

        return $q.when(getFullResource(api, params, 'studentSubmissions', 'Results'))
            .then(response => _.groupBy(response, 'courseWorkId'))
    }

    function getFullResource(api, params, resultKey, errorKey) {
        let allResults = [];

        const getResourcePage = pageToken => $q.when(api.list(_.extend(params, {pageToken})))
            .then(response => {
                if (!response || !response.result || !response.result[resultKey]) {
                    return allResults;
                }

                allResults = allResults.concat(response.result[resultKey]);

                return response.result['nextPageToken']
                    ? getResourcePage(response.result['nextPageToken'])
                    : allResults;
            }, () => showGoogleApiError(errorKey));

        return getResourcePage();
    }

    function getProfile(isSignedIn) {
        var profile = isSignedIn ? gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile() : null;

        return {
            isSignedIn: isSignedIn,
            imageUrl: profile ? profile.getImageUrl() : undefined
        };
    }

    function normalizeAssessment(externalAssessment) {
        var courseWide = (externalAssessment.assigneeMode == "ALL_STUDENTS");
        var dueDateTime = 'dueDate' in externalAssessment
                ? externalAssessment.dueDate.year
                    + '-' + _.padStart(externalAssessment.dueDate.month, 2, '0')
                    + '-' + _.padStart(externalAssessment.dueDate.day, 2, '0')
                    + ' ' + _.padStart(externalAssessment.dueTime.hours, 2, '0')
                    + ':' + _.padStart(externalAssessment.dueTime.minutes, 2, '0')
                    + ':00'
                : externalAssessment.creationTime;
        var tz = SettingService.get('tz');
        var date = moment.tz(dueDateTime, 'UTC').tz(tz).format('YYYY-MM-DD');

        externalAssessment.source = 'google';
        externalAssessment.name = externalAssessment.title;
        externalAssessment.courseWide = courseWide;
        externalAssessment.date = date;
        externalAssessment.lastUpdated = externalAssessment.updateTime;
    }

    function normalizeCourse(externalCourse, externalAssessments) {
        var gradedAssessments = _.filter(externalAssessments, externalAssessment => externalAssessment.maxPoints); // if this is missing then it's not graded
        var maxDate = null;
        var lastUpdatedAssessmentTitle = null;
        
        externalCourse.assessments = gradedAssessments;

        _.each(gradedAssessments, function(externalAssessment) {
            var date = externalAssessment.updateTime;

            if (!maxDate || (date && date > maxDate)) {
                maxDate = date;
                lastUpdatedAssessmentTitle = externalAssessment.title;
            }
        });

        externalCourse.url = externalCourse.alternateLink;
        externalCourse.numAssessments = gradedAssessments.length;

        if (maxDate) {
            externalCourse.lastUpdatedTimestampRelative = moment(maxDate).fromNow();
            externalCourse.lastUpdatedTimestampFormatted = moment(maxDate).format("dddd, M/D/YYYY h:mma");
            externalCourse.lastUpdatedAssessmentTitle = lastUpdatedAssessmentTitle;
        }
    }

    function normalizeAssessmentStudents(
        externalAssessment,
        externalStudentsByExternalId,
        externalStudentResultsByExternalAssessmentId
    ) {
        var pointsPossible = externalAssessment.maxPoints;
        var externalStudentResults = _.get(externalStudentResultsByExternalAssessmentId, externalAssessment.id) || [];
        var numGrades = 0;

        _.each(externalStudentResults, function(externalStudentResult) {
            var score = _.get(externalStudentResult, 'draftGrade');
            var externalStudent = _.get(externalStudentsByExternalId, externalStudentResult.userId);

            externalStudentResult.externalStudent = externalStudent;
            externalStudentResult.missing = !_.isFinite(score);
            externalStudentResult.avgScore = _.isFinite(score) ? (100 * score / pointsPossible) : null;

            numGrades += externalStudentResult.missing ? 0 : 1;
        });

        externalAssessment.url = externalAssessment.alternateLink;
        externalAssessment.externalStudentResults = externalStudentResults;
        externalAssessment.numStudentResults = numGrades;
    }

    function showGoogleApiError(dataset, leaveDialogOpen) {
        var loginInfo = leaveDialogOpen ? "you're still logged in and " : '';
        var msg = `Unable to load your Google ${dataset}. Make sure ${loginInfo}your account has Google Classroom access.`;

        Growl.error({ message: 'Error: ' + msg });
        return attemptGoogleSignOut()
            .then(handleFailedSignIn);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
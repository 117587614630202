var _module_exports = "<div class=\"student-cell\">\n    <a ng-click=\"$parent.openLinkInNewTab($event)\" href=\"/student/{{ ::cell.student.student_id }}\" tooltip>\n        <img class=\"avatar\" ng-src=\"{{ ::cell.student.photo }}\" />\n        <span>\n            {{ ::cell.student.display_name }}\n        </span>\n    </a>\n    <span class=\"edit-student-scores\">\n        <i\n            class=\"icon-pencil hide-while-editing hide-while-saving\"\n            ng-click=\"cell.showStudentAssessmentsEditModal()\">\n        </i>\n    </span>\n</div>\n";;
var path = '/views/gradebook/common/student-cell/student-cell.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
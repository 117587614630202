import tssPopoverTemplate from './tss-popover.html';

angular
    .module('common')
    .directive('tssPopover', [ '$compile',
        '$document',
        '$http',
        '$templateRequest',
        '$timeout',
        '$window', 

function(
    $compile,
    $document,
    $http,
    $templateRequest,
    $timeout,
    $window,
) {
    return {
        transclude: true,
        restrict: 'A',
        scope: {
            tssPopoverSettings: '='
        },
        templateUrl: tssPopoverTemplate,
        link: tssPopoverLink
    };

    function tssPopoverLink($scope, $element) {
        var $body = $document.find('body');
        var $toggle;
        var $contents;
        var watches = [];

        $scope.open = false;
        $scope.settings = {};

        init();

        function init() {
            $toggle = $element;
            $contents = $element.find('.tss-popover-ng').detach();

            setBindings();
            setWatches();
        }

        function setBindings() {
            $toggle.on('click', handleClick);
            // listen for close event
            // emitted to keep only one popover open
            $contents.on('close', close);

            $scope.$on('$destroy', handleDestroy);
        }

        function handleDocumentClick() {
            if ($scope.open) {
                close();
            }
        }

        function handleScrollOrResize() {
            if ($scope.open) {
                $contents.css(getPosition());
            }
        }

        function handleScrollOrResizeBiaxialScroll() {
            if ($scope.open) {
                // reposition popover
                $contents.css(getPosition());

                // do we need to hide because the toggle is out of view
                var scrollContainerOffset = $('.biaxial-scroll-rows').offset();
                scrollContainerOffset.bottom = scrollContainerOffset.top + $('.biaxial-scroll-rows').height();
                scrollContainerOffset.right = scrollContainerOffset.left + $('.biaxial-scroll-rows').width();

                var toggleOffset = $toggle.offset();
                var calculatedTop = toggleOffset.top - scrollContainerOffset.top;
                var calculatedLeft = toggleOffset.left - scrollContainerOffset.left;

                if (calculatedTop < 1
                    || calculatedLeft < 1
                    || toggleOffset.left > scrollContainerOffset.right
                    || toggleOffset.top > scrollContainerOffset.bottom
                ) {
                    close()
                }
            }
        }

        function setWatches() {
            watches.push($scope.$watch('tssPopoverSettings', buildSettings));
        }

        function open() {
            // only attach click, resize and scroll handles when open
            angular.element($window).on('click', handleDocumentClick);
            angular.element($window).on('resize scroll', handleScrollOrResize);
            angular.element('.biaxial-scroll-rows').on('scroll', handleScrollOrResizeBiaxialScroll);

            // set width before opening to make sure we have the contents
            const width = parseInt($scope.settings.width);
            $contents.width(width);
            $contents.find('.popover-contents').width(width);

            $scope.open = true;
            $toggle.addClass('open');
            $contents.css(getPosition()).appendTo($body);
            $timeout(function() {
                $contents.addClass('open');
            }, 10);
        }

        function close() {
            angular.element($window).off('click', handleDocumentClick);
            angular.element($window).off('resize scroll', handleScrollOrResize);
            angular.element('.biaxial-scroll-rows').off('scroll', handleScrollOrResizeBiaxialScroll);

            $scope.open = false;
            $toggle.add($contents).removeClass('open');
            $contents.detach();
        }

        function handleClick(event) {
            // handle the case where you only want popover behavior conditionally.
            // like comment bubble opens popover when not in edit mode,
            // but opens comment edits when in edit mode
            if ($scope.settings.noop) {
                _.noop();
            } else {
                // stop propagation so that headers dont sort
                event.stopPropagation();

                // close other popovers except this one
                angular.element('.tss-popover-ng.open').not($contents).trigger('close');

                if(!$scope.open && $scope.settings.templateUrl) {
                    compileTemplateAndOpen();
                } else {
                    toggle();
                }
            }
        }

        // toggle open/close
        function toggle() {
            $scope.open ? close() : open();
        }

        // compiles template, replaces content, and opens
        function compileTemplateAndOpen() {
            if($scope.settings.dataUrl) {
                fetchContentData();
            } else {
                $scope.popoverContentsData = {};
            }

            $templateRequest($scope.settings.templateUrl).then(function(html){
                var $tpl = $compile(html)($scope);
                $contents.html($tpl);
                open();
            });
        }

        // fetches dynamic data
        function fetchContentData() {
            $http.get($scope.settings.dataUrl)
                .then(function(response) {
                    $scope.popoverContentsData = _.extend({}, response.results, $scope.settings.extraData);
                    $scope.helpers = $scope.settings.helpers;
                });
        }

        function handleDestroy() {
            $toggle.off('click', handleClick);
            $contents.off('close', close);

            // Remove all watches
            angular.forEach(watches, function(watch) {
                watch.call();
            });
        }

        // Handles click, scroll, or resize on document and window
        // Closes on all document clicks outside of this popover
        function handleCloseContentsEvents(event) {
            var $target = angular.element(event.target),
                inContents = $target.closest($contents).length;

            if ($scope.open
                && !$target.is($toggle)
                && ((inContents && $scope.settings.closeOnContentsClick) || !inContents)
            ) {
                close();
            }
        }

        function getPosition() {
            var toggleCenter = $toggle.outerWidth() / 2,
                offset = $toggle[0].getBoundingClientRect(),
                offsetCenter = $contents.outerWidth() / 2 - toggleCenter,
                offsetTop = $toggle.outerHeight() + offset.top - parseInt($body.css('margin-top')),
                offsetLeft = offset.left - offsetCenter,
                zIndex = Math.max($contents.zIndex(), $toggle.zIndex()),
                cssAttributes = {};

            if ($scope.settings.left) {
                offsetLeft = offset.left + toggleCenter;
            } else if ($scope.settings.right) {
                offsetLeft = offset.left - toggleCenter - offsetCenter * 2;
            }

            cssAttributes.top = offsetTop + ($window.pageYOffset || $document[0].documentElement.scrollTop);
            cssAttributes.left = offsetLeft + ($window.pageXOffset || $document[0].documentElement.scrollLeft);

            if (zIndex) {
                cssAttributes.zIndex = zIndex;
            }

            return cssAttributes;
        }

        // Merges default settings with passed settings
        function buildSettings() {
            $scope.settings = _.extend({}, {
                width: 200,
                left: false,
                right: false,
                closeOnContentsClick: false,
                noop: false,
                dataUrl: null,
                templateUrl: null,
                extraData: null,
                helpers: null
            }, $scope.tssPopoverSettings);
        }
    }
}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
var _module_exports = "<div class=\"external-integrations-container\">\n    <section class=\"white\">\n        <h3>\n            <span class=\"external-logo logo\"></span>\n            Import Assessment Results\n        </h3>\n        <div class=\"app-buttons\">\n            <div ng-repeat=\"externalIntegration in externalIntegrations.externalIntegrations\"\n                ng-click=\"externalIntegrations.showCourses(externalIntegration)\"\n                class=\"app-button-wrapper\"\n                >\n                <div class=\"app-button\">\n                    <div class=\"app-icon\">\n                        <img ng-src=\"{{externalIntegration.icon}}\">\n                    </div>\n                    <div class=\"app-name\">\n                        {{externalIntegration.name}}\n                    </div>\n                </div>\n            </div>\n        </div>\n    </section>\n    <section class=\"bg-gray-09 position-bottom\">\n        <div class=\"row\">\n            <div class=\"col span-12 padding-top-xsm\">&nbsp;</div>\n            <div class=\"col span-12\">\n                <div class=\"tab-btn-group\">\n                    <button\n                        class=\"btn btn-gray close-button\"\n                        ng-click=\"externalIntegrations.closeThisDialog()\">\n                        Close\n                    </button>\n                </div>\n            </div>\n        </div>\n    </section>\n</div>\n";;
var path = '/views/gradebook/views/assessment-add-edit/external-integrations/external-integrations.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
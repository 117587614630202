// parse and sort levels
// add display and title names for levels
// add color scale
export function cleanGradingScale(gradingScale) {
    var levels = _.get(gradingScale, 'levels', []);
    var parsedOrderedLevels = _.chain(levels)
        .map(formatGradingScaleLevel)
        .orderBy('min_value', 'desc')
        .value();
    var colorScale = Functions.getFontColorScale(levels);

    return _.extend({}, gradingScale, { levels: parsedOrderedLevels, colorScale: colorScale });
}

function formatGradingScaleLevel(level) {
    var minVal = _.isNull(level.min_value)
        ? 0
        : parseFloat(level.min_value);
    var displayName = level.abbreviation || level.name;
    var titleName = level.abbreviation ? `${level.abbreviation} - ${level.name}` : level.name;

    return _.extend({}, level, { min_value: minVal, display_name: displayName, title_name: titleName });
}

export function getRoundedScore(object, key) {
    var score = _.get(object, key, null);
    return !_.isNil(score) ? Math.round(score) : null;
}

export function cleanAssessmentStudent(assessment, assessmentStudent, gradingScales, courseId, students) {
    var keysToRemove = ['edit_key'],
        returnValue = assessmentStudent;

    if (assessmentStudent.edit_key == 'score_override'
        || (assessmentStudent.edit_key == 'avg_score' && assessmentStudent.missing == 1)) {
        keysToRemove.push('avg_score');
    }

    returnValue = _.omit(assessmentStudent, keysToRemove);

    // if we have no score then the student is missing
    // set this here because assessment import doesn't handle the case where
    // assessment student record is NOT marked missing and we DONT hasData for student
    // this check is for when the user backspaced the score but didn't click "Missing"
    if (_.get(returnValue, 'avg_score') === '') {
        returnValue.missing = 1;
    }

    // when entering grades by level
    // set each students' assessment_answers.answer = grading_scale_level.name
    // since that's what the Add Assessment page expects
    // ignore students marked excused, missing, or ones that have been left blank
    if (assessment.enter_grades_by_level == 1
            && assessmentStudent.active == 1
            && assessmentStudent.missing == 0
            && 'avg_score' in returnValue
            && !_.isNull(returnValue.avg_score)) {
        var gradingScaleLevels = gradingScales[assessment.grading_scale_id].levels;
        returnValue['answer'] = Functions.getScoreLevel(returnValue.avg_score, gradingScaleLevels, true, true).name;
    }

    var student = _.find(students, s => s.student_id == assessmentStudent.student_id);
    
    returnValue['course_id'] = courseId;
    returnValue['section_period_id'] = student ? student.section_period_id : null;

    return returnValue;
}

export function confirmUnsavedEdits(editingAssessment) {
    var assessmentStudents = editingAssessment
        ? editingAssessment.getAssessmentStudents()
        : [];
    var isChanged = assessmentStudents.length;

    return !isChanged || confirm("It looks like you have unsaved edits. Are you sure?")
}

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
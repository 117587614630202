import './assessment-bucket-cell/assessment-bucket-cell.component.js';
import './assessment-bucket-header-cell/assessment-bucket-header-cell.component.js';
import addEditAssessmentModalTemplate from '../assessment-add-edit/assessment-add-edit.html';
import * as helpers from '../../helpers'

angular
    .module('gradebook')
    .controller('BucketedGridController', [
        '$rootScope',
        '$scope',
        '$route',
        '$routeParams',
        '$location',
        '$q',
        '$timeout',
        '$window',
        'GradebookService',
        'ngDialog',
        'AssessmentService',
        'SettingService',
        'TermBinService',
        'SectionService',
        'SocketService',
        'settings', 

    function($rootScope,
        $scope,
        $route,
        $routeParams,
        $location,
        $q,
        $timeout,
        $window,
        GradebookService,
        ngDialog,
        AssessmentService,
        SettingService,
        TermBinService,
        SectionService,
        SocketService,
        settings
    ) {
    var watches = [];
    var fetchGradebookDataPromise;
    var promiseCanceler;
    var courseListenerDeregister;
    var previouslyOpenBucketIds = [];

    $scope.filters = { sort: 'section_number' };
    $scope.bucketCounts = {};
    $scope.socket = null;

    $scope.getSetting = SettingService.get;
    $scope.getAssessmentStudentsForAssessment = getAssessmentStudentsForAssessment;
    $scope.getVisibleCellCount = getVisibleCellCount;
    $scope.isAssessmentBucketOpen = isAssessmentBucketOpen;
    $scope.sortStudents = sortStudents;
    $scope.getSortKey = getSortKey;
    $scope.onAfterAssessmentScoreSave = fetchDataKeepingOpenBuckets;
    $scope.onAfterAssessmentDeactivation = fetchDataKeepingOpenBuckets;
    $scope.promiseIsNull = promiseIsNull;
    $scope.goToCoursePage = goToCoursePage;
    $scope.openLinkInNewTab = openLinkInNewTab;
    $scope.getSelectedSectionNames = getSelectedSectionNames;
    $scope.hasRequiredParams = hasRequiredParams;
    $scope.getColor = getColor;
    $scope.getEditingClasses = getEditingClasses;
    var hasEditingCellsClass = 'has-editing-cells';
    var editingWithCommentsClass = 'with-comments';

    init();

    function init() {
        setupSocket();
        fetchData();
        setupWatches();

        // extend so that previousRoute is a new object, otherwise it will reference
        // the same object as $route.current and will change when current route changes
        $route.previous = _.extend({}, $route.current);
    }

    function fetchData() {
        var env = angular.element('.js-env');
        var termId = env.data('term-id');
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        var order = SettingService.get('gradebook_assessment_date_order');
        promiseCanceler = $q.defer();

        if (courseId && sectionIds && termBinId) {
            $rootScope.$broadcast('loadingGradebookData', true);
            // load selected term bin every time we fetch data
            TermBinService.findById(termBinId)
                .then(termBin => $scope.selectedTermBin = termBin);

            fetchGradebookDataPromise = GradebookService.getBucketedData(
                courseId,
                sectionIds,
                termBinId,
                termId,
                order,
                { timeout: promiseCanceler.promise }
            );
            return fetchGradebookDataPromise
                .then(processAssessmentData)
                .then(() => $timeout(scrollAssessmentIntoView))
                .then(editAssessmentStudentScores)
                .then(clearFocusedAssessmentParams)
                .then(() => fetchGradebookDataPromise = null)
                .catch(_.noop)
                .finally(() => $rootScope.$broadcast('loadingGradebookData', false));
        } else {
            return $timeout(resetGradebookData);
        }
    }

    function fetchDataAndCloseDialog() {
        return fetchData()
            .then(ngDialog.closeAll);
    }

    function fetchDataKeepingOpenBuckets() {
        previouslyOpenBucketIds = _.chain($scope.buckets)
            .filter('open')
            .map('grading_methodology_bucket_id')
            .value();

        return fetchData()
            .then(ngDialog.closeAll);
    }

    function processAssessmentData(response) {
        if (!response) {
            return $q.reject();
        }

        var courseGradingScale = _.get(response, 'results.course_grading_scale');
        var allGradingScales = _.get(response, 'results.all_grading_scales');

        $scope.course_grading_scale = helpers.cleanGradingScale(courseGradingScale);
        $scope.grading_scales = _.mapValues(allGradingScales, helpers.cleanGradingScale);
        $scope.students = _.get(response, 'results.students');

        // open buckets previously open (do before setting on scope to avoid jump)
        var buckets = _.get(response, 'results.bucketed_assessments');
        if (_.size(previouslyOpenBucketIds) > 0) {
            buckets = _.chain(buckets)
                .map(bucket => {
                    if (_.includes(previouslyOpenBucketIds, bucket.grading_methodology_bucket_id)) {
                        bucket.open = true;
                    }
                    return bucket;
                })
                .value();

            previouslyOpenBucketIds = [];
        }

        // get all assessments from buckets
        var assessments = _.reduce(buckets, (assessments, bucket) => {
            return assessments.concat(bucket.assessments);
        }, []);

        // if all we have is empty buckets, show the empty state
        $scope.buckets = assessments.length ? buckets : [];

        // key assessments by assessment_id for fast lookup
        $scope.assessmentsKeyed = _.keyBy(assessments, 'assessment_id');

        $scope.assessmentStudentsByStudent = _.chain($scope.students)
            .keyBy('student_id')
            .mapValues('bucketed_assessments')
            .mapValues((buckets) => _.flatMap(buckets, 'assessment_students'))
            .value();

        return response;
    }

    function scrollAssessmentIntoView() {
        var assessmentId = $routeParams.focused_assessment_id;
        if (assessmentId) {
            var bucket = _.find($scope.buckets, bucket => _.includes(bucket.assessment_ids, assessmentId));
            bucket.open = true;
            return $timeout(() => {
                var assessmentColumn = angular.element('#assessment-header-cell-' + assessmentId);
                var columnOffset = assessmentColumn.offset();
                var container = angular.element('.biaxial-scroll-rows');
                container.scrollLeft(columnOffset.left - container.offset().left);
            });
        }
    }

    function editAssessmentStudentScores() {
        var shouldEditScores = $routeParams.editing_scores == 1;
        if (shouldEditScores) {
            var assessmentId = $routeParams.focused_assessment_id;
            var assessment = _.chain($scope.buckets)
                .reduce((assessments, bucket) => {
                    return assessments.concat(bucket.assessments);
                }, [])
                .find({ assessment_id: assessmentId })
                .value();
            assessment.editingAssessmentStudentScores = 1;
            assessment.getAssessmentStudents = () => getAssessmentStudentsForAssessment(assessment)
            $rootScope.$broadcast('editingAssessmentStudentScores', assessment);
        }
    }

    function clearFocusedAssessmentParams() {
        $route.updateParams(_.extend({}, $routeParams, { focused_assessment_id: null, editing_scores: null }));
    }

    // get an array of all assessment students for this assessment id
    // from an array of students that looks like:
        // [
        //     {
        //         student_id: <id>,
        //         bucketed_assessments: [
        //             { assessment_students: [{},{},{}] },
        //             { assessment_students: [{},{},{}] },
        //         ]
        //     },
        //     {
        //         student_id: <id>,
        //         bucketed_assessments: [
        //             { assessment_students: [{},{},{}] },
        //             { assessment_students: [{},{},{}] },
        //         ]
        //     },
        // ]
    function getAssessmentStudentsForAssessment(assessment, ignoreChanged) {
        var filterBy = {
            assessment_id: assessment.assessment_id
        }

        if (!ignoreChanged) {
            filterBy['changed'] = true;
        }

        return _.chain($scope.students)
            .flatMap('bucketed_assessments')
            .flatMap('assessment_students')
            .filter(filterBy)
            .map(assessmentStudent => helpers.cleanAssessmentStudent(assessment, assessmentStudent, $scope.grading_scales, $routeParams.course_id, $scope.students))
            .value();
    }

    function resetGradebookData() {
        $scope.assessments = null;
        $scope.assessmentsKeyed = null;
        $scope.students = null;
        $scope.grading_scales = null;
        $scope.course_grading_scale = null;

        $scope.filters = { sort: 'section_number' };
    }

    function setupWatches() {
        // watches.push($scope.$watch('buckets', () => $timeout(openBucket)));
        watches.push($scope.$on('settingsChanged', fetchDataKeepingOpenBuckets));
        watches.push($scope.$on('$routeUpdate', handleRouteChange));
        $scope.$on('$destroy', handleDestroy);
        $window.onbeforeunload = handleOnBeforeUnload;
    }

    function handleOnBeforeUnload() {
        var editingAssessment = _.find($scope.assessments, { editingAssessmentStudentScores: true });
        var modalIsVisible = angular.element('.ngdialog').length;

        if (editingAssessment || modalIsVisible) {
            return 'It looks like you have unsaved edits!';
        }
    }

    function handleRouteChange(event, route) {
        // we dont want to fetch data if the route change was only the result
        // of clearing the focused assessment (which we do so that the assessment
        // doesn't scroll into view every time we change filters)
        if (!_.get($route.previous, 'params.focused_assessment_id')) {
            promiseCanceler.resolve();
            resetGradebookData();
            fetchDataAndCloseDialog();
        };
        // extend so that previous route is a new object, otherwise it will
        // reference the same object as route and will change when route changes
        $route.previous = _.extend({}, route);
    }

    function openBucket() {
       var assessmentIdInEditMode = _.some($scope.assessments, 'editing'),
           assessment = _.find($scope.assessments, function(assessment) {
               return assessment.assessment_id == assessmentIdInEditMode;
           }),
           assessmentBucketId = (assessment || {}).grading_methodology_bucket_id;

       if(assessmentIdInEditMode && assessmentBucketId) {
           // Grab assessment's course grading bucket ID to see if bucket is already open
           // If the bucket is collapsed, open it
           if (!$scope.assessmentBucketIsOpen(assessmentBucketId)) {
               $scope.toggleAssessmentBucket(assessmentBucketId);
           }

           // Make sure that the open bucket change has been redrawn
           // and then scroll that shit!
           $timeout(scrollAssessmentInEditModeIntoView);
       }
    }

    // count of buckets plus number of assessments in open buckets
    function getVisibleCellCount() {
        return _.chain($scope.buckets)
            .reduce((sum, bucket) => {
                var visibleAssessmentCount = 0;
                if (bucket.open) {
                    visibleAssessmentCount = bucket.assessments.length;
                }
                return sum + visibleAssessmentCount + 1;
            }, 0)
            .value();
    }

    function handleDestroy() {
        angular.forEach(watches, function(watch) {
            watch.call();
        });
    }

    function isAssessmentBucketOpen(bucket) {
        var assessmentBucket = _.find($scope.buckets, { grading_methodology_bucket_id: bucket.grading_methodology_bucket_id });
        return assessmentBucket.open;
    }

    // if bucket and assessment provided, looking for assessment's gradebook score
    // if only bucket provided, looking for bucket score
    function getSortKey(bucket, assessment) {
        var bucketId = bucket.grading_methodology_bucket_id;
        var assessmentId = assessment ? assessment.assessment_id : -1;
        return `bucket[${bucketId}].assessments[${assessmentId}]`;
    }

    function sortStudents(sortKey) {
        // if removing sort, default to ordering by section and student
        if (sortKey == '') {
            $scope.students = _.orderBy($scope.students, ['section_number', 'display_name'], ['asc', 'asc']);
        } else {
            // `-section_number` would be desc, `section_number` would be asc
            var order = sortKey.substr(0, 1) == '-' ? 'desc' : 'asc';
            // sort by function because sortKey might be nested e.g. bucket.score
            $scope.students = _.orderBy($scope.students, _.partial(dataSort, sortKey), [order]);
        }
    }

    // if sorting by score, the sortKey will be something like bucket[10].assessments[4]
    // otherwise it will be a simple key on the student object
    function dataSort(sortKey, student) {
        // trim leading `-` from a col sorted descending
        sortKey = sortKey.replace(/^-/, '');
        var matches = sortKey.match(/bucket\[(\d+)\]\.assessments\[(-?\d+)\]/);
        if (matches && matches.length) {
            var bucketId = matches[1];
            var bucket = _.find(student.bucketed_assessments, ['grading_methodology_bucket_id', bucketId]);
            var assessmentId = matches[2];
            // assessmentId of -1 indicates we want the bucket, not a specific assessment
            if (assessmentId && assessmentId > -1) {
                var assessmentStudent = _.find(bucket.assessment_students, ['assessment_id', assessmentId]);

                if (assessmentStudent.missing == 1 && assessmentStudent.active == 1) {
                    return -1;
                }
                if (assessmentStudent.active == 0) {
                    return -2;
                }

                return parseFloat(_.get(assessmentStudent, 'gradebook_score', -3));
            } else {
                return parseFloat(_.get(bucket, 'score', -1));
            }
        }

        return _.get(student, sortKey);
    }

    function promiseIsNull() {
        return !fetchGradebookDataPromise;
    }

    function hasRequiredParams() {
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        return courseId && sectionIds && termBinId;
    }

    function goToCoursePage(goToGradingMethodologies) {
        $window.open('/course/' + $routeParams.course_id + (goToGradingMethodologies ? '#grading-methodologies' : ''), '_blank');
    }


    function openLinkInNewTab($event) {
        $event.preventDefault();
        $window.open($event.currentTarget.href, '_blank');
    }

    function getSelectedSectionNames() {
        var sectionIds = _.split($routeParams.section_ids, ',');
        return _.join(SectionService.getNamesForSectionIds(sectionIds), ', ');
    }

    function getColor(index) {
        var colors = ['blue','green','yellow','orange','red'];
        var numColors = colors.length;
        var idx = index;
        if (index > numColors - 1) {
            idx = index % numColors;
        }
        return colors[idx];
    }

    function getEditingClasses(buckets) {
        var className = '';

        var assessments = _.chain(buckets)
            .map('assessments')
            .flatten()
            .value();

        _.each(assessments, (assessment) => {
            if (assessment.editingAssessmentStudentScores) {
                className = `${hasEditingCellsClass}${assessment.showComments ? ` ${editingWithCommentsClass}` : ''}`;
                return false;
            }
        });

        return className;
    }

    //--------------------------
    // Socket Stuff
    //--------------------------

    function setupSocket() {
        $scope.socket = SocketService.init({
            scope: $scope,
            updateFunction: fetchDataKeepingOpenBuckets
        });
    }
}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
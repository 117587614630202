import spencilLoaderTemplate from './spencil-loader.html';
import './spencil-loader.controller.js';

angular
    .module('common')
    .component('spencilLoader', {
        controller: 'SpencilLoaderController as loader',
        templateUrl: spencilLoaderTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import assessmentBucketHeaderCellTemplate from './assessment-bucket-header-cell.html';
import './assessment-bucket-header-cell.controller.js';

angular
    .module('common')
    .component('assessmentBucketHeaderCell', {
        bindings: {
            bucket: '=',
            color: '@'
        },
        controller: 'AssessmentBucketHeaderCellController as cell',
        templateUrl: assessmentBucketHeaderCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('services')
    .factory('CourseService', [
        '$http',

function CourseService(
    $http
) {
    return {
        findById: findById,
        get: get,
        getCourseDefaultGradingScaleSetId: getCourseDefaultGradingScaleSetId,
        getNameForCourseId: getNameForCourseId,
        getStandardsBasedGradingForCourseId: getStandardsBasedGradingForCourseId,
        getEnterGradesByLevelForCourseId: getEnterGradesByLevelForCourseId,
        getDefaultGradingScaleSetIdForCourseId: getDefaultGradingScaleSetIdForCourseId,
    };

    var courses = [];

    // gets courses
    function get(options) {
        var uri = '/api/v1/gradebook/courses';

        return $http.get(uri, options)
            .then(setCourses);
    }

    function setCourses(response) {
        courses = _.get(response, 'results.courses');
        return courses;
    }

    function findById(courseId) {
        return _.find(courses, {course_id: courseId});
    }

    function getCourseDefaultGradingScaleSetId(courseId) {
        var course = findById(courseId);
        return _.get(course, 'default_grading_scale_set_id', null);
    }

    function getNameForCourseId(courseId) {
        var course = findById(courseId);
        return _.get(course, 'name', '');
    }

    function getStandardsBasedGradingForCourseId(courseId) {
        var course = findById(courseId);
        return _.get(course, 'standards_based_grading');
    }

    function getEnterGradesByLevelForCourseId(courseId) {
        var course = findById(courseId);
        return _.get(course, 'enter_grades_by_level');
    }

    function getDefaultGradingScaleSetIdForCourseId(courseId) {
        var course = findById(courseId);
        return _.get(course, 'default_grading_scale_set_id');
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
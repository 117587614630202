angular
    .module('gradebook')
    .factory('GradebookService',  ['$httpParamSerializer',
        '$http', function (
    $httpParamSerializer,
    $http
) {
    return {
        getTimelineData: getTimelineData,
        getBucketedData: getBucketedData,
        getBucketedStandardsData: getBucketedStandardsData
    };

    function getTimelineData(courseId, sectionIds, termBinId, termId, order, externalSource, expand, options) {
        var params = $httpParamSerializer({
            course_id: courseId,
            section_ids: sectionIds,
            term_bin_id: termBinId,
            term_id: termId,
            order: order,
            external_source: externalSource,
            expand: expand,
        });

        var uri = '/api/v1/gradebook/timeline/data?' + params;
        return $http.get(uri, options);
    }

     function getBucketedData(courseId, sectionIds, termBinId, termId, order, options) {
        var params = $httpParamSerializer({
            course_id: courseId,
            section_ids: sectionIds,
            term_bin_id: termBinId,
            term_id: termId,
            order: order,
        });

        var uri = '/api/v1/gradebook/bucketed-methodologies/data?' + params;
        return $http.get(uri, options);
    }

     function getBucketedStandardsData(courseId, sectionIds, termBinId, termId, order, options) {
        var params = $httpParamSerializer({
            course_id: courseId,
            section_ids: sectionIds,
            term_bin_id: termBinId,
            term_id: termId,
            order: order,
        });

        var uri = '/api/v1/gradebook/bucketed-standards/data?' + params;
        return $http.get(uri, options);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('ToolbarController', [
        '$scope',

function (
    $scope
) {
    var vm = this;

    vm.scrollGridLeft = scrollGridLeft;
    vm.scrollGridRight = scrollGridRight;

    vm.$onInit = init;

    function init() {

    }

    function scrollGridLeft() {
        scrollGrid('left', 550, 700);
    }

    function scrollGridRight() {
        scrollGrid('right', 550, 700);
    }

    // direction == left or right
    // increment == how far to scroll
    // duration == animation speed
    function scrollGrid(direction, increment, duration) {
        var container = angular.element('.biaxial-scroll-rows');
        var plusOrMinus = direction == 'left' ? '-=' : '+=';
        container.animate({ scrollLeft: `${plusOrMinus}${increment}` }, duration);
    }

}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
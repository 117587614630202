angular
    .module('services')
    .factory('TermBinService', [
        '$http',

function (
    $http
) {
    var uri = '/api/v1/term-bin';
    var termBinsPromise = null;

    init();

    return {
        get: get,
        findById: findById,
        getNameForTermBinId: getNameForTermBinId,
        getDateDesc: getDateDesc
    };

    function init() {
        termBinsPromise = $http.get(uri);
    }

    function get() {
        return termBinsPromise
            .then(_.property('results.term_bins'));
    }

    function getNameForTermBinId(termBinId, nameKey = 'short_name') {
        return findById(termBinId)
            .then(termBin => _.get(termBin, nameKey, ''));
    }

    function findById(termBinId) {
        return get()
            .then(termBins => _.find(termBins, {term_bin_id: termBinId}));
    }

    function getDateDesc(dateString) {
        return $http.get('/date/desc/' + dateString);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
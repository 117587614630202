import scoreInputTemplate from './score-input.html';
import './score-input.controller.js';

angular
    .module('common')
    .component('scoreInput', {
        bindings: {
            model: '=',
            modelKey: '@',
            placeholderKey: '@',
            onChange: '&',
            filldownSettings: '<',
            gradingScaleLevels: '<',
            hideMissingOption: '@',
            hideExcusedOption: '@',
            index: '<',
            disabled: '&'
        },
        controller: 'ScoreInputController as input',
        templateUrl: scoreInputTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import commentInputTemplate from './comment-input.html';
import './comment-input.controller.js';

angular
    .module('common')
    .component('commentInput', {
        bindings: {
            model: '=',
            modelKey: '@',
            placeholderKey: '@',
            onChange: '&',
            filldownSettings: '<',
            index: '<',
            disabled: '&'
        },
        controller: 'CommentInputController as input',
        templateUrl: commentInputTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
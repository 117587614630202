angular
    .module('common')
    .controller('ExternalProfileController', [
        '$scope',

function (
    $scope,
) {
    var vm = this;

    vm.$onInit = init;
    vm.handleUseAnotherAccount = handleUseAnotherAccount;

    function init() {
        setImageUrl();
        $scope.$watch('externalProfile.externalProfile', setImageUrl);
    }

    function setImageUrl() {
        vm.image_url = vm.externalProfile && vm.externalProfile.isSignedIn
            ? vm.externalProfile.imageUrl || '/dist/missingphoto.png'
            : null;
    }

    function handleUseAnotherAccount() {
        vm.externalProfile = _.extend({}, vm.externalService.getProfile(), {leaveDialogOpen: true});
        vm.externalService.handleUseAnotherAccount()
            .then(externalProfile => vm.externalProfile = externalProfile);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
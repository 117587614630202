import externalProfileTemplate from './external-profile.html';
import './external-profile.controller.js';

angular
    .module('common')
    .component('externalProfile', {
        bindings: {
            externalProfile: '=',
            externalService: '=',
        },
        controller: 'ExternalProfileController as externalProfile',
        templateUrl: externalProfileTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('services')
    .factory('ExternalAssessmentService', [
        '$timeout',
        '$route',
        '$routeParams',
        '$q',
        'AssessmentStudentService',
        'AssessmentQuestionService',
        'ObjectiveService',
        'SettingService',
    
function (
    $timeout,
    $route,
    $routeParams,
    $q,
    AssessmentStudentService,
    AssessmentQuestionService,
    ObjectiveService,
    SettingService
) {
    return {
        diffExternalAndSrAssessments: diffExternalAndSrAssessments,
        storeResultsToSchoolrunner: storeResultsToSchoolrunner,
    };

    function diffExternalAndSrAssessments(
        srAssessments,
        srGradebookStudents,
        allSrStudents,
        externalAssessments,
        externalStudentsByExternalId,
        externalStudentResultsByAssessmentId,
        courseId
    ) {
        var srStudentsByEmail = _.chain(allSrStudents)
                .filter(srStudent => srStudent.active == 1)
                .groupBy(srStudent => _.toLower(srStudent.email))
                .mapValues(srStudents => {
                    return srStudents.length > 1 ? null : _.get(srStudents, '0'); // handle dupes
                })
                .value();
        var srStudentsByFirstLast = _.chain(allSrStudents)
                .filter(srStudent => srStudent.active == 1)
                .groupBy(srStudent => _.toLower((`${srStudent.first_name} ${srStudent.last_name}`).trim()))
                .mapValues(srStudents => {
                    return srStudents.length > 1 ? null : _.get(srStudents, '0'); // handle dupes
                })
                .value();
        var srStudentsByExternalStudentId = {};

        _.each(externalStudentsByExternalId, function(externalStudent) {
            var email = _.toLower(externalStudent.email);
            var firstLast = _.toLower((externalStudent.firstLast).trim());
            var srStudent = _.get(srStudentsByEmail, email);  

            if (!srStudent) {
                srStudent = _.get(srStudentsByFirstLast, firstLast);
            }

            if (srStudent) {
                srStudentsByExternalStudentId[externalStudent.externalId] = srStudent;
            }
        });

        diffAllAssessmentsRecursively(
            0,
            externalAssessments,
            externalStudentResultsByAssessmentId,
            srStudentsByExternalStudentId,
            srAssessments,
            srGradebookStudents,
            courseId
        );
    }

    /**
     * This is just to be able to kick off each iteration of the loop on a new
     * thread so the screen can repaint in between and we get that nice
     * rendering of each row's startus one-by-one instead of having to wait for
     * all of the diffs to be done to render anything.
     * 
     * @param  int i
     * @param  array externalAssessments
     * @param  Object externalStudentResultsByAssessmentId
     * @param  Object srStudentsByExternalStudentId
     * @param  array srAssessments
     * @param  Object srGradebookStudents
     */
    function diffAllAssessmentsRecursively(
        i,
        externalAssessments,
        externalStudentResultsByAssessmentId,
        srStudentsByExternalStudentId,
        srAssessments,
        srGradebookStudents,
        courseId
    ) {
        if (!externalAssessments || i >= externalAssessments.length) {
            return; // basecase
        }
        
        var externalAssessment = externalAssessments[i];

        diffOneAssessment(
            externalAssessment,
            externalStudentResultsByAssessmentId,
            srStudentsByExternalStudentId,
            srAssessments,
            srGradebookStudents,
            courseId
        );
        
        $timeout(() => diffAllAssessmentsRecursively(
            i + 1,
            externalAssessments,
            externalStudentResultsByAssessmentId,
            srStudentsByExternalStudentId,
            srAssessments,
            srGradebookStudents,
            courseId
        ), 100);
    }

    function diffOneAssessment(
        externalAssessment,
        externalStudentResultsByAssessmentId,
        srStudentsByExternalStudentId,
        srAssessments,
        srGradebookStudents,
        courseId
    ) {
        var title = externalAssessment.title;
        var externalAssessmentId = externalAssessment.id;
        var externalStudentResults = _.get(externalStudentResultsByAssessmentId, externalAssessmentId) || [];
        var externalStudentResultsBySrStudentId = keyStudentResultsBySrStudentId(externalStudentResults, srStudentsByExternalStudentId);
        var srAssessment = findSrAssessment(srAssessments, externalAssessment);
        var srAssessmentId = srAssessment ? srAssessment.assessment_id : null;
        var srAssessmentTypeId = srAssessment ? srAssessment.assessment_type_id : null;
        var srAssessmentQuestions = srAssessment ? _.filter(srAssessment.assessment_questions, q => q.active == 1) : null;
        var srAssessmentStudents = srAssessment ? getSrAssessmentStudents(srGradebookStudents, srAssessmentId) : [];
        var allStudentIds = _.union(_.keys(externalStudentResultsBySrStudentId), _.keys(srAssessmentStudents));
        var newAssessmentStudents = [];
        var hasChanges = false;
        var updates = [];

        externalAssessment.postAssessmentStudentsData = {
            assessment_id: srAssessmentId,
            assessment_students: newAssessmentStudents
        };

        // diff assessment students vs externalStudentResults
        _.each(allStudentIds, function(studentId) {
            var srAssessmentStudent = _.get(srAssessmentStudents, studentId);
            var srHasStudentResult = srAssessmentStudent && srAssessmentStudent.assessment_student_id;
            var externalStudentResult = _.get(externalStudentResultsBySrStudentId, studentId);
            var externalStudentAnswers = _.get(externalStudentResult, 'answers');
            var externalHasStudentResult = !!externalStudentResult;
            var srStudent = _.get(srStudentsByExternalStudentId, _.get(externalStudentResult, 'externalStudent.externalId'));
            var srGradebookStudent = _.find(srGradebookStudents, s => s.student_id == studentId);
            var srStudentAnswers = _.chain(srAssessmentStudent).get('assessment_answers').filter(a => a.active == 1).value();
            var sectionPeriodId = _.get(srGradebookStudent, 'section_period_id')
                || _.get(srAssessmentStudent, 'section_period_id');
            var newSrAssessmentStudent = {
                assessment_id: srAssessmentId,
                student_id: studentId,
                active: srAssessmentStudent ? srAssessmentStudent.active : 1,
                missing: srAssessmentStudent ? srAssessmentStudent.missing : 0,
                avg_score: srAssessmentStudent ? srAssessmentStudent.avg_score : null,
                score_override: srAssessmentStudent ? srAssessmentStudent.score_override : null,
                course_id: courseId,
                section_period_id: sectionPeriodId,
            };
            var srActive = newSrAssessmentStudent.active == 1;
            var srMissing = newSrAssessmentStudent.missing == 1;
            var srAvgScore = newSrAssessmentStudent.avg_score;
            var srHasScoreOverride = !_.isNull(newSrAssessmentStudent.score_override);

            newAssessmentStudents.push(newSrAssessmentStudent);

            if (!externalHasStudentResult || !srActive) {
                return true; // no google result or excused/deactived in SR--continue
            }

            var externalMissing = externalStudentResult.missing == 1 && !srHasScoreOverride; // don't change overridden score to missing!
            var externalAvgScore = externalStudentResult.avgScore;
            var isChanged = !srHasStudentResult // new score
                    || srMissing != externalMissing // missing changed
                    || (!externalMissing && !Tss.Number.floatsEqual(srAvgScore, externalAvgScore)); // score changed
            var answersChanged = false;

            isChanged = isChanged || (answersChanged = diffAssessmentAnswers(
                externalAssessment.questions,
                externalStudentAnswers,
                srAssessmentQuestions,
                srStudentAnswers,
            ));

            if (externalStudentAnswers) {
                newSrAssessmentStudent.answers = _.map(externalStudentAnswers, a => ({
                    question_number: a.question_number,
                    points: a.points,
                    answer: a.answer,
                }));
            }

            if (isChanged) {
                hasChanges = true;
                newSrAssessmentStudent.missing = externalMissing ? 1 : 0;
                newSrAssessmentStudent.avg_score = externalAvgScore;
                updates.push(_.extend({}, externalStudentResult, {
                    student: srStudent,
                    from: srHasStudentResult ? (srMissing ? 'missing' : Math.round(srAvgScore)) : 'n/a',
                    to: externalMissing ? 'missing' : (_.isNull(externalAvgScore) ? 'n/a' : Math.round(externalAvgScore)),
                    answersChanged: answersChanged,
                }));
            }
        });
        
        externalAssessment.updateDescription = updates;
        externalAssessment.unmappableResults = _.filter(externalStudentResults, externalStudentResult => {
            var srStudentId = externalStudentResult.sr_student_id;
            var externalMissing = externalStudentResult.missing == 1;
            var externalAvgScore = externalStudentResult.avgScore;

            externalStudentResult.to = externalMissing ? 'missing' : Math.round(externalAvgScore);
            
            return !srStudentId && !externalMissing;
        });
        
        if (!srAssessment) {
            externalAssessment.state = 'new'
        } else if (hasChanges) {
            externalAssessment.state = 'update';
        } else {
            externalAssessment.state = 'ok';
        }
    }

    function diffAssessmentAnswers(
        externalAssessmentQuestions,
        externalStudentAnswers,
        srAssessmentQuestions,
        srStudentAnswers
    ) {
        var externalNumQuestions = _.get(externalAssessmentQuestions, 'length');
        var srNumQuestions = _.get(srAssessmentQuestions, 'length');

        // 1 or none questions means unchanged
        if (!externalNumQuestions && srNumQuestions <= 1) {
            return false; // unchanged
        }

        if (externalNumQuestions != srNumQuestions) {
            return `Assessment now has ${externalNumQuestions} questions instead of ${srNumQuestions}`;
        }

        // same number of questions, check the question details and the answers
        for (let externalQuestion of externalAssessmentQuestions) {
            var externalAnswer = _.find(externalStudentAnswers, a => a.question_number == externalQuestion.question_number)
            var srQuestion = _.find(srAssessmentQuestions, q => q.question_number == externalQuestion.question_number);
            var srAnswer = srQuestion
                ? _.find(srStudentAnswers, a => a.assessment_question_id == srQuestion.assessment_question_id)
                : null;

            if (externalQuestion && externalAnswer && srQuestion && srAnswer) {
                // if the question or answer are different, then it's changed
                // else check the rest of the answers
                let reason = false;
                if ((reason = diffQuestion(externalQuestion, srQuestion))
                        || (reason = diffAnswer(externalAnswer, srAnswer))) {
                    return reason;
                }
            } else {
                if (!srQuestion) {
                    return `New question for ${externalQuestion.code}`;
                } else if (externalAnswer && !srAnswer) {
                    return `New answer for  ${externalQuestion.code}`;
                }

                return `Answer removed for ${externalQuestion.code}`;
            }
        }

        return false; // unchanged
    }

    function diffQuestion(externalQuestion, srQuestion) {
        if (externalQuestion.objective_code != _.get(srQuestion, 'objective.code')) {
            return `Standard changed`;
        }

        if (externalQuestion.correct_answer != srQuestion.correct_answer) {
            return `Correct answer changed`;
        }

        if (!Tss.Number.floatsEqual(externalQuestion.point_value, srQuestion.point_value)) {
            return `Question point value changed`;
        }

        return false;
    }

    function diffAnswer(externalAnswer, srAnswer) {
        if (externalAnswer.answer != _.get(srAnswer, 'answer')) {
            return `Student answer changed changed`;  
        }

        if (!Tss.Number.floatsEqual(externalAnswer.points, srAnswer.points)) {
            return `Points earned changed`;
        }

        return false;
    }

    function keyStudentResultsBySrStudentId(externalStudentResults, srStudents) {
        return _.chain(externalStudentResults)
            .map(externalStudentResult => _.extend(externalStudentResult, {
                sr_student_id: _.get(_.get(srStudents, _.get(externalStudentResult, 'externalStudent.externalId')), 'student_id')
            }))
            .filter('sr_student_id')
            .keyBy('sr_student_id')
            .value();
    }

    function getSrAssessmentStudents(students, assessmentId) { // foreach srStudent, pluck by assessmentId and then merge
        return _.chain(students)
            .flatMap('assessment_students')
            .filter(srAssessmentStudent => {
                return srAssessmentStudent.assessment_id == assessmentId
                    && srAssessmentStudent.assessment_student_id // ignore placeholder "records" that are in the UI but don't exist in the db
            })
            .keyBy('student_id')
            .value();
    }
    
    function findSrAssessment(srAssessments, externalAssessment) {
        return _.find(srAssessments, { name: externalAssessment.title });
    }

    function storeResultsToSchoolrunner(externalAssessment, srNewAssessmentAndQuestion, ignoreRefresh) {
        var srNewAssessmentId = _.get(srNewAssessmentAndQuestion, 'assessment.assessment_id');
        var data = externalAssessment.postAssessmentStudentsData;
        var refreshState = function() {
            externalAssessment.state = 'ok';

            if (!ignoreRefresh) {
                $route.reload(); // reload gradebook data
            }

            return srNewAssessmentAndQuestion; // for chaining
        };

        externalAssessment.previousState = externalAssessment.state;
        if (srNewAssessmentId) {
            data.assessment_id = srNewAssessmentId;
            externalAssessment.state = 'saving';
        } else {
            externalAssessment.state = 'updating';
        }

        return !data.assessment_students.length
            ? $q.when(refreshState())
            : $q.when(optionallyStoreMultipleAssessmentQuestions(externalAssessment, data.assessment_id))
                .then(() => AssessmentStudentService.post(data))
                .then(refreshState)
                .catch(_.partial(handleSaveFailed, externalAssessment));
    }

    function optionallyStoreMultipleAssessmentQuestions(externalAssessment, assessmentId) {
        var questions = _.get(externalAssessment, 'questions') || [];

        if (!questions.length) {
            return null;
        }

        var allAssessmentQuestions = null;
        var createNewObjectivesFromImport = parseInt(SettingService.get('create_new_objectives_from_import', 0)) == 1;

        return $q.all([
                AssessmentQuestionService.getAll(assessmentId),
                ObjectiveService.get($routeParams.course_id),
            ])
            .then(responses => {
                allAssessmentQuestions = responses[0];
                var objectives = _.get(responses[1], 'results.objectives');
                var newObjectives = _.chain(externalAssessment.questions)
                    .filter(q => !getObjectiveForQuestion(objectives, q))
                    .value();

                return createNewObjectivesFromImport && newObjectives.length
                    ? createNewObjectives(objectives, newObjectives)
                    : objectives;
            })
            .then(allObjectives => createBulkDataForAssessmentQuestions(assessmentId, allAssessmentQuestions, allObjectives, externalAssessment))
            .then(bulkData => AssessmentQuestionService.updateBulk(bulkData))
        ;
    }

    function createNewObjectives(allObjectives, newObjectives) {
        var env = angular.element('.js-env');
        var schoolId = env.data('school-id');
        var promises = [];
        var maxOrderKey = _.chain(allObjectives)
            .map(o => parseInt(o.order_key))
            .max()
            .value() || 0;

        for (let objective of newObjectives) {
            let params = {
                school_id: schoolId,
                course_id: $routeParams.course_id,
                code: objective.objective_code,
                description: objective.objective_description,
                order_key: ++maxOrderKey,
            };

            promises.push(ObjectiveService.post(params));
        }

        return $q.all(promises)
            .then(responses => {
                var newlyCreatedObjectives = _.map(responses, 'results.objective')

                return allObjectives.concat(newlyCreatedObjectives);
            })
    }

    function createBulkDataForAssessmentQuestions(assessmentId, assessmentQuestions, courseObjectives, externalAssessment) {
        var oldAssessmentQuestions = _.filter(assessmentQuestions, q => q.active == 1); // only care about active questions
        var oldQuestionNumbers = _.map(oldAssessmentQuestions, q => parseInt(q.question_number)); // parseInt so we don't have to worry about string vs int
        var newQuestionNumbers = _.map(externalAssessment.questions, q => parseInt(q.question_number));
        var assessmentQuestionsToDeactivate = _.chain(oldAssessmentQuestions)
            .filter(q => !_.includes(newQuestionNumbers, parseInt(q.question_number))) // get all old question_numbers that aren't in newQuestionNumbers
            .map(q => _.extend(q, { active: 0 })) // deactivate old mapping
            .value();
        var assessmentQuestionsToCreate = _.chain(externalAssessment.questions)
            .filter(q => !_.includes(oldQuestionNumbers, q.question_number)) // get all new question_numbers that aren't in oldQuestionNumbers
            .map(q => ({ // add new question
                assessment_id: assessmentId,
                question_number: q.question_number,
                question_name: q.question_number,
                correct_answer: q.correct_answer,
                point_value: q.point_value,
                objective_id: getObjectiveForQuestion(courseObjectives, q)
            }))
            .value();
        var assessmentQuestionsToUpdate = _.chain(oldAssessmentQuestions)
            .filter(q => _.includes(newQuestionNumbers, parseInt(q.question_number))) // get all old question_numbers that are in newQuestionNumbers
            .map(oldQuestion => { // update question
                var newQuestion = _.find(
                    externalAssessment.questions,
                    q => q.question_number == oldQuestion.question_number
                );

                return _.extend({}, oldQuestion, {
                    question_number: newQuestion.question_number,
                    question_name: newQuestion.question_number,
                    correct_answer: newQuestion.correct_answer,
                    point_value: newQuestion.point_value,
                    objective_id: getObjectiveForQuestion(courseObjectives, newQuestion)
                });
            })
            .value();

        return assessmentQuestionsToDeactivate
            .concat(assessmentQuestionsToCreate)
            .concat(assessmentQuestionsToUpdate);
    }

    function getObjectiveForQuestion(courseObjectives, question) {
        return _.chain(courseObjectives)
            .find(o => o.code == question.objective_code)
            .get('objective_id')
            .value();
    }

    function handleSaveFailed(externalAssessment, e) {
        if (externalAssessment && externalAssessment.previousState) {
            externalAssessment.state = externalAssessment.previousState;
        }
    }
}])
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import fillDownTemplate from './fill-down.html';
import './fill-down.controller.js';

angular
    .module('common')
    .component('fillDown', {
        transclude: true,
        bindings: {
            index: '=',
            value: '=',
            disabled: '=',
            fillDownSettings: '='
        },
        controller: 'FillDownController as fillDown',
        templateUrl: fillDownTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
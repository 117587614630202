angular
    .module('services')
    .factory('ObjectiveGradeService', [
        '$http',

function (
    $http,
) {
    var transformConfig = {
        transformResponse: function (data) {
            // remove info message so we dont get a growl
            return _.extend({}, JSON.parse(data), { info: [] });
        }
    };
    var baseUri = '/api/v1/objective-grades';

    return {
        post: post,
        put: put
    };

    function post(data) {
        var params = _.extend({}, data);
        delete params['objective_grade_id']; // shouldn't be an ID on a post

        return $http.post(baseUri, params, transformConfig)
            .then(_.property('results.objective_grade'));
    }

    function put(objectiveGradeId, data) {
        var uri = `${baseUri}/${objectiveGradeId}`;

        return $http.put(uri, data, transformConfig)
            .then(_.property('results.objective_grade'));
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
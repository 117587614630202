import studentAssessmentsEditTemplate from '../../views/student-assessments-edit/student-assessments-edit.html';
import * as helpers from '../../helpers';

angular
    .module('common')
    .controller('StudentCellController', [
        'ngDialog',
        '$scope',
        '$rootScope',

function (
    ngDialog,
    $scope,
    $rootScope
) {
    var vm = this;
    var editingAssessment = null;

    vm.$onInit = init;
    vm.showStudentAssessmentsEditModal = showStudentAssessmentsEditModal;

    function init() {
        $scope.$on('editingAssessmentStudentScores', (event, assessment) => editingAssessment = assessment);
        $scope.$on('cancelEditingAssessmentStudentScores', () => editingAssessment = null);
    }

    function showStudentAssessmentsEditModal() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            return;
        }

        ngDialog.open({
            controller: 'StudentAssessmentsEditController',
            template: studentAssessmentsEditTemplate,
            className: 'gradebook-dialog student-assessments-edit ngdialog-theme-default',
            data: {
                student: vm.student,
                assessments: vm.assessments,
                assessmentStudents: vm.assessmentStudents,
                gradingScales: vm.gradingScales,
                onAfterSave: vm.onAfterSave
            },
            showClose: false,
            closeByEscape: false,
            closeByDocument: false
        });
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import courseGradeOverrideTemplate from './course-grade-override-popover.html';
import * as helpers from '../../helpers';

angular
    .module('common')
    .controller('CourseGradeCellController', ['SettingService', 

function (
    SettingService
) {
    var vm = this;

    vm.getScoreDisplayMode = getScoreDisplayMode;
    vm.getCourseGradeScoreColor = getCourseGradeScoreColor;
    vm.getTitleName = getTitleName;
    vm.getDisplayName = getDisplayName;

    vm.$onInit = init;

    function init() {
        var baseUrl = `/api/v1/course-grades/${_.get(vm, 'courseGrade.courseGradeId')}`;
        var expands = `change_logs.user.staff_member`;
        var changeLogFilters = `{"filters":{"change_log_details.column=":["score_override",null]},"limit":1}`;

        vm.overridePopoverSettings = {
            width: 250,
            right: true,
            closeOnContentsClick: true,
            templateUrl: courseGradeOverrideTemplate,
            dataUrl: `${baseUrl}?expand=${expands}&course_grade_change_log_filters=${changeLogFilters}`,
            extraData: {
                scoreDisplayMode: getScoreDisplayMode(),
                gradingScaleLevels: vm.courseGradingScale.levels
            },
            helpers: {
                getGradingScaleLevelNameForScore: getGradingScaleLevelNameForScore,
                formatDateString: formatDateString
            }
        }
    }

    function getScoreDisplayMode() {
        return SettingService.get('gradebook_score_display', 'score');
    }

    function getCourseGradeScoreColor(gradingScaleLevelIndex) {
        return parseInt(SettingService.get('gradebook_grade_color_coding', 1)) == 1
            ? vm.courseGradingScale.colorScale(gradingScaleLevelIndex)
            : null;
    }

    function getGradingScaleLevelNameForScore(score, gradingScaleLevels) {
        var gradingScaleLevel = Functions.getScoreLevel(score, gradingScaleLevels, true, true);
        return gradingScaleLevel && (gradingScaleLevel.abbreviation || gradingScaleLevel.name);
    }

    function formatDateString(dateString) {
        return moment(dateString).format('MM/DD/YY');
    }

    function getTitleName() {
        var levelName = _.get(vm.courseGrade, 'levelName', '');
        var levelAbbreviation = _.get(vm.courseGrade, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore(vm.courseGrade, 'score');

        return `${!_.isNull(roundedScore) ? `${levelName}${levelAbbreviation ? ` (${levelAbbreviation})` : ''} ${roundedScore}%` : ''}`;
    }

    function getDisplayName() {
        var levelName = _.get(vm.courseGrade, 'levelName', '');
        var levelAbbreviation = _.get(vm.courseGrade, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore(vm.courseGrade, 'score');

        switch (getScoreDisplayMode()) {
            case 'score':
                return `${!_.isNull(roundedScore) ? `${roundedScore}%` : ''}`;
            case 'grade-level':
                return levelAbbreviation || levelName || '';
            case 'score-and-grade-level':
                return `${!_.isNull(roundedScore) ? `${roundedScore}% ${levelAbbreviation || levelName || ''}` : ''}`;
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import studentCellTemplate from './student-cell.html';
import './student-cell.controller.js';

angular
    .module('common')
    .component('studentCell', {
        bindings: {
            student: '<',
            assessments: '=',
            assessmentStudents: '=',
            gradingScales: '<',
            onAfterSave: '&'
        },
        controller: 'StudentCellController as cell',
        templateUrl: studentCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
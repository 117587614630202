angular
    .module('services')
    .factory('AssessmentAnswerService', [
        '$http',

function (
    $http
) {

    return {
        updateBulk
    };

    function updateBulk(data, options) {
        var uri = '/api/v1/bulk/assessment-answers';
        return $http.put(uri, { bulk_data: data }, options);
    }
 }])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('gradebook')
    .controller('SettingsController', [  
        '$scope',
        '$rootScope',
        '$location',
        'SettingService',
        '$route',
        '$window',
        '$timeout', 
        
    function($scope,
        $rootScope,
        $location,
        SettingService,
        $route,
        $window,
        $timeout
    ){
    $scope.saving = false;
    $scope.save = save;
    $scope.getSaveButtonText = getSaveButtonText;

    init();

    /**
     * Initializes modal controller
     */
    function init() {
        $scope.oldSettings = SettingService.getDisplaySettings();
        $scope.settings = SettingService.getDisplaySettings();

        // if the user setting for gradebook view is different then the page they are on,
        // meaning they went directly to the url instead of menu item, change the button value,
        // so that changing settings only changes view when necessary.
        var path = $location.path();
        if (!_.includes(path, $scope.settings.gradebook_view)) {
            var pathPieces = _.split(path, '/');
            $scope.settings.gradebook_view = _.last(pathPieces);
        }
    }

    function save() {
        // mark as saving
        $scope.saving = true;

        var env = angular.element('.js-env');
        var userId = env.data('user-id');
        var districtId = null;
        var schoolId = null;

        SettingService.save($scope.settings, districtId, schoolId, userId)
            .then(broadcastSettingChanges)
            .catch(handleSaveUserSettingsFail)
            .finally(() => changeViewsIfNeeded($scope.settings, $scope.oldSettings));
    }

    function getSaveButtonText() {
        return $scope.saving ? 'Saving...' : 'Save';
    }

    function broadcastSettingChanges() {
        $rootScope.$broadcast('settingsChanged', $scope.settings);
    }

    function handleSaveUserSettingsFail() {
        $scope.saving = false;
    }

    function changeViewsIfNeeded(settings, oldSettings) {
        var viewSetting = _.get(settings, 'gradebook_view');
        var isNameOnly = settings.gradebook_google_classroom_match_name_only == 1;
        var wasNameOnly = oldSettings.gradebook_google_classroom_match_name_only == 1;

        if (isNameOnly != wasNameOnly) {
            // Reload the page if they change the email vs name only setting
            // since Google doesn't seem to allow you to change which scopes
            // you ask for once you've init'ed once. Close the dialog first and
            // use $timeout so that we don't hit the onBeforeUnload warning.
            $scope.closeThisDialog();
            $timeout(() => $window.location.reload(), 1000);
        } else if (!_.includes($location.path(), viewSetting)) {
            $location.path(`/gradebook/${viewSetting}`);
        }
    }
}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
var _module_exports = "<div class=\"external-profile-photo-wrapper\">\n    <div class=\"external-profile-photo\"\n        ng-if=\"externalProfile.externalProfile.isSignedIn\"\n        tss-popover\n        tss-popover-settings=\"{right: true}\"\n        style=\"background-image: url( {{externalProfile.image_url}} )\">\n        <div class=\"popover-contents popover-menu\">\n            <div class=\"menu-action\" ng-click=\"externalProfile.handleUseAnotherAccount()\">\n                <i class=\"icon-user\"></i> Use another account\n            </div>\n        </div>\n    </div>\n</div>";;
var path = '/views/gradebook/views/assessment-add-edit/external-profile/external-profile.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
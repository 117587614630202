angular
    .module('services')
    .factory('ObjectiveService', [
        '$http',
        '$httpParamSerializer',

function (
    $http,
    $httpParamSerializer
) {
    return {
        get: get,
        post: post,
    };

    function get(courseId, expand) {
        var params = $httpParamSerializer({
            'course_ids': courseId,
            'active': 1,
            'below_rollup_level': 1,
            expand,
        });

        var uri = '/api/v1/objectives?' + params;
        return $http.get(uri);
    }

    function post(params) {
        var uri = '/api/v1/objectives';
        return $http.post(uri, params);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
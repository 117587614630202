var _module_exports = "<div class=\"cell assessment-bucket-header-cell border-top-color-{{ ::cell.color }}\">\n    <div\n        class=\"overflow-ellipsis\"\n        title=\"{{ ::cell.bucket.name }}\"\n        tss-tooltip>\n        {{ ::cell.bucket.name }}\n    </div>\n    <div class=\"clearfix\">\n        <div class=\"font-small text-sr-gray-04 pull-left\">\n            ({{ ::cell.bucket.weight }}%)\n        </div>\n        <i\n            ng-click=\"cell.toggleBucket($event)\"\n            ng-class=\"{\n                'icon-plus-sign-alt': !cell.bucket.open,\n                'icon-minus-sign-alt': cell.bucket.open,\n                'disabled': !cell.bucket.assessments.length\n            }\"\n            class=\"pull-right icon-action\">\n        </i>\n        <div\n            class=\"badge pull-right badge-light-gray\"\n            tss-tooltip\n            title=\"{{ ::cell.bucket.name }} contains {{ ::cell.bucket.assessments.length }} {{ ::cell.bucket.assessments.length == 1 ? 'assessment' : 'assessments' }}.\">\n            {{ cell.bucket.assessments.length }}\n        </div>\n    </div>\n</div>\n";;
var path = '/views/gradebook/views/bucketed-grid/assessment-bucket-header-cell/assessment-bucket-header-cell.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
import * as helpers from '../../../helpers';

angular
    .module('common')
    .controller('AssessmentBucketCellController', [
        'SettingService',
        
function(
    SettingService
) {
    var vm = this;
    vm.$onInit = init;

    function init() {
        vm.scoreColor = getScoreColor();
        vm.titleName = getTitleName();
        vm.displayName = getDisplayName();
    }

    function getScoreDisplayMode() {
        return SettingService.get('gradebook_score_display', 'score');
    }

    function getScoreColor() {
        var gradingScaleLevelIndex = vm.bucket.levelIndex;
        return parseInt(SettingService.get('gradebook_grade_color_coding', 1)) == 1
            ? vm.courseGradingScale.colorScale(gradingScaleLevelIndex)
            : null;
    }

    function getTitleName() {
        var levelName = _.get(vm.bucket, 'levelName', '');
        var levelAbbreviation = _.get(vm.bucket, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore(vm.bucket, 'score');

        return `${!_.isNull(roundedScore) ? `${levelName}${levelAbbreviation ? ` (${levelAbbreviation})` : ''} ${roundedScore}%` : ''}`;
    }

    function getDisplayName() {
        var levelName = _.get(vm.bucket, 'levelName', '');
        var levelAbbreviation = _.get(vm.bucket, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore(vm.bucket, 'score');

        switch (getScoreDisplayMode()) {
            case 'score':
                return `${!_.isNull(roundedScore) ? `${roundedScore}%` : ''}`;
            case 'grade-level':
                return levelAbbreviation || levelName;
            case 'score-and-grade-level':
                return `${!_.isNull(roundedScore) ? `${roundedScore}% ${levelAbbreviation || levelName}` : ''}`;
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
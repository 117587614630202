angular
    .module('common')
    .controller('ExternalAssessmentsController', [
        '$http',
        '$q',
        '$route',
        '$routeParams',
        '$scope',
        '$timeout',
        'ExternalAssessmentService',
        'GradebookService',
        'SettingService',

function (
    $http,
    $q,
    $route,
    $routeParams,
    $scope,
    $timeout,
    ExternalAssessmentService,
    GradebookService,
    SettingService
) {
    var vm = this;
    
    vm.$onInit = init;
    vm.handleAssessmentClick = handleAssessmentClick;
    vm.updateAll = updateAll;
    vm.assessments = null;
    vm.inTermBinFilter = true;
    vm.inTermBinFilterSettings = {}

    function init() {
        $scope.$watch('externalAssessments.externalCourse', externalCourse => showAssessments(externalCourse));
        $scope.$watch('externalAssessments.inTermBinFilter', filterAssessmentsToCurrentTermBin);

        vm.inTermBinFilterSettings = {
            label: vm.selectedTermBin.short_name + ' Only',
        }

        vm.scrollBarWidth = _.scrollBarWidth();
    }

    function showAssessments(externalCourse) {
        if (externalCourse) {
            var externalStudentsByExternalId = null;

            vm.allAssessments = _.chain(externalCourse.assessments)
                .each(externalAssessment => {
                    var termBinStartDate = vm.selectedTermBin.start_date;
                    var termBinEndDate = vm.selectedTermBin.end_date;

                    vm.externalService.normalizeAssessment(externalAssessment);

                    externalAssessment.dateFormatted = moment(externalAssessment.date).format("M/D/YY");
                    externalAssessment.lastUpdatedTimestampRelative = moment(externalAssessment.lastUpdated).fromNow();
                    externalAssessment.lastUpdatedTimestampFormatted = moment(externalAssessment.lastUpdated).format("dddd, M/D/YYYY h:mma");
                    externalAssessment.inTermBin = externalAssessment.date >= termBinStartDate
                        && externalAssessment.date <= termBinEndDate;
                    externalAssessment.state = 'unknown';
                    externalAssessment.name = (_.escape(externalAssessment.name));
                })
                .sortBy('lastUpdated')
                .reverse()
                .value();

            filterAssessmentsToCurrentTermBin();

            // if no assessments are in the term bin, show all
            vm.inTermBinFilter = vm.assessments.length
                && vm.externalIntegration.has_due_dates;

            $q.when(vm.externalService.getStudents(externalCourse))
                .then(studentsResponse => {
                    externalStudentsByExternalId = studentsResponse;

                    return vm.externalService.getAssessmentStudents(externalCourse);
                })
                .then(externalStudentResultsByExternalAssessmentId => {
                    return normalizeAllAssessmentStudents(
                        externalStudentsByExternalId,
                        externalStudentResultsByExternalAssessmentId,
                        vm.allAssessments
                    )
                })
                .catch(handleExternalApiError);
        } else {
            vm.allAssessments = null;
            vm.assessments = null; // on logout
        }
    }

    function normalizeAllAssessmentStudents(
        externalStudentsByExternalId,
        externalStudentResultsByExternalAssessmentId,
        externalAssessments
    ) {
        _.each(externalAssessments, function(externalAssessment) {
            vm.externalService.normalizeAssessmentStudents(
                externalAssessment,
                externalStudentsByExternalId,
                externalStudentResultsByExternalAssessmentId
            );
        });
        
        getSrAssessments(
            externalAssessments,
            externalStudentsByExternalId,
            externalStudentResultsByExternalAssessmentId
        );
    }
    
    function getSrAssessments(externalAssessments, externalStudentsByExternalId, externalStudentResultsByExternalAssessmentId) {
        var env = angular.element('.js-env');
        var termId = env.data('term-id');
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        var externalSource = 'google';
        var order = SettingService.get('gradebook_assessment_date_order');
        var expand = 'assessment_answers,assessment_questions.objective';
        var promiseCanceler = $q.defer();
        var fetchGradebookDataPromise = GradebookService.getTimelineData(
            courseId,
            sectionIds,
            termBinId,
            termId,
            order,
            externalSource,
            expand,
            { timeout: promiseCanceler.promise }
        );
        var studentsApi = '/api/v1/students?embellish=1';
        
        $q.all([
            fetchGradebookDataPromise,
            $http.get(studentsApi)
        ]).then(responses => ExternalAssessmentService.diffExternalAndSrAssessments(
                _.get(responses, '[0].results.assessments') || [], // srAssessments
                _.get(responses, '[0].results.students') || [], // srGradebookStudents
                overrideEmailFieldWithSsoField(_.get(responses, '[1].results.students') || []), // allSrStudents
                externalAssessments,
                externalStudentsByExternalId,
                externalStudentResultsByExternalAssessmentId,
                courseId
            )
        );
    }
    function overrideEmailFieldWithSsoField(allSrStudents) {
        var ssoEmailKey = SettingService.get('sso_student_email_storage');
        var isAttrRegex = /^student_attrs:/;

        // override the email field with the value from the attr specified on
        // the settings page if the student has a value for that attr
        if (ssoEmailKey && ssoEmailKey.match(isAttrRegex)) {
            var attrKey = ssoEmailKey.replace(isAttrRegex, '');

            _.each(allSrStudents, record => {
                if (record[attrKey]) {
                    record['email'] = record[attrKey];
                }
            });
        }

        return allSrStudents;
    }

    function filterAssessmentsToCurrentTermBin() {
        if (vm.inTermBinFilter) {
            vm.assessments = _.filter(vm.allAssessments, { inTermBin: true });
        } else {
            vm.assessments = vm.allAssessments;
        }
    }

    function handleAssessmentClick(externalAssessment) {
        if (externalAssessment.state == 'new') {
            vm.externalAssessment = externalAssessment;
        } else if (externalAssessment.state == 'update') {
            ExternalAssessmentService.storeResultsToSchoolrunner(externalAssessment);
        }
    }

    function updateAll(assessments) {
        var promises = _.map(assessments, externalAssessment => ExternalAssessmentService.storeResultsToSchoolrunner(externalAssessment, null, true));

        $q.all(promises).then(() => $route.reload())
    }

    function handleExternalApiError() {
        console.log('external-assessments.handleExternalApiError');
        console.log(arguments);
        vm.externalProfile = vm.externalService.getProfile();
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('gradebook')
    .controller('AddEditAssessmentController', [ 
        '$scope',
        '$route',
        '$routeParams',
       '$rootScope',
        '$q',
        '$window',
        '$sce',
        '$httpParamSerializer',
        '$timeout',
        'AssessmentService',
        'AssessmentQuestionService',
        'AssessmentTypeService',
        'CourseService',
        'GoogleClassroomService',
        'EdlinkService',
        'ExternalAssessmentService',
        'SettingService',
        'ObjectiveService',
        'SectionService',
        'TermBinService',
        'StaffMemberService',
        'GradingScalesService', 

        function($scope,
            $route,
            $routeParams,
            $rootScope,
            $q,
            $window,
            $sce,
            $httpParamSerializer,
            $timeout,
            AssessmentService,
            AssessmentQuestionService,
            AssessmentTypeService,
            CourseService,
            GoogleClassroomService,
            EdlinkService,
            ExternalAssessmentService,
            SettingService,
            ObjectiveService,
            SectionService,
            TermBinService,
            StaffMemberService,
            GradingScalesService
        ){
    var env = angular.element('.js-env');
    var staffMemberId = _.toString(env.data('staff-member-id')); // we convert to string because the staff_member_ids we get via the gradebook API are strings and that way we compare string to string
    var schoolId = env.data('school-id');
    var userId = env.data('user-id');

    $scope.assessment = _.extend({}, {
        enter_grades_by_level: 0,
        course_wide: 1,
        staff_member_id: staffMemberId,
        enter_grades_by_level: _.get($scope.ngDialogData, 'enterGradesByLevel', 0),
    }, $scope.ngDialogData ? $scope.ngDialogData.assessment : {});
    $scope.standardRequired = _.get($scope.ngDialogData, 'standardRequired', false);
    $scope.defaultStandardRequired = $scope.standardRequired;
    $scope.page = _.get($scope.ngDialogData, 'page', 4);
    $scope.externalIntegrations = _.get($scope.ngDialogData, 'externalIntegrations', []);
    $scope.importingFromExternal = _.get($scope.ngDialogData, 'importingFromExternal', false);
    // go directly to showCourses / page 2 if there's only one option
    // or if the user's already chosen an LMS
    if ($scope.importingFromExternal && $scope.page == 1) {
        if ($rootScope.lastExternalIntegration) {
            $scope.externalIntegration = $rootScope.lastExternalIntegration;
        } else if ($scope.externalIntegrations.length == 1) {
            $scope.externalIntegration = $scope.externalIntegrations[0];
        }
    }
    $scope.isEdit = $scope.ngDialogData && $scope.ngDialogData.assessment;
    $scope.isDefaultGradingScalePct = false;
    $scope.saving = false;
    var objectiveLevelForGrades = SettingService.get('objective_level_for_grades', 0);
    var objectiveGroupKey = objectiveLevelForGrades > 0
        ? 'parent_objective.display_name'
        : '';
    var courseObjectives = [];

    $scope.objectiveSettings = {
        valueKey: 'objective_id',
        labelKey: 'display_name',
        orderKey: 'new_order_key',
        groupKey: objectiveGroupKey,
        loadingSpinner: true
    };
    $scope.assessmentTypeSettings = {
        valueKey: 'assessment_type_id',
        labelKey: 'name',
        orderKey: ['groupOrder', 'name'],
        loadingSpinner: true
    };
    $scope.staffMemberSettings = {
        valueKey: 'staff_member_id',
        labelKey: 'display_name',
        orderKey: 'display_name',
        loadingSpinner: true
    };

    $scope.getSetting = SettingService.get;
    $scope.getCourseSectionDescription = getCourseSectionDescription;
    $scope.onDateChange = onDateChange;
    $scope.save = save;
    $scope.getSaveButtonText = getSaveButtonText;
    $scope.goToAddAssessmentPage = goToAddAssessmentPage;
    $scope.goToEditAssessmentPage = goToEditAssessmentPage;
    $scope.nextPage = nextPage;
    $scope.setPage = setPage;
    $scope.previousPage = previousPage;

    init();

    function init() {
        getFormOptions()
            .then(processFormOptions);
        TermBinService.findById($routeParams.term_bin_id)
            .then(function(termBin) {
                $scope.selectedTermBin = termBin;

                // for the external_source case we'll do this later once they select
                // an assessment to import
                if (!$scope.importingFromExternal) {
                    setSelectedTermBinAndDates();
                }
            });
        GradingScalesService.getPrimaryGradingScaleForGradingScaleSetId(CourseService.getDefaultGradingScaleSetIdForCourseId($routeParams.course_id))
            .then(function(gradingScale){
                if (gradingScale && gradingScale.is_pct == 0) {
                    $scope.assessment.enter_grades_by_level = 0;
                    $scope.isDefaultGradingScalePct = true
                }
            });

        if ($scope.importingFromExternal) {
            $scope.$watch('externalProfile', externalProfile => {
                if (!externalProfile) return;

                if (!externalProfile.isSignedIn) {
                    setPage(2);

                    if (!externalProfile.leaveDialogOpen) {
                        $timeout(() => $scope.closeThisDialog());
                    }
                }
            });

            // show the add assessment dialog and preload the data from a Google assessment
            $scope.$watch('externalAssessment', externalAssessment => showAddEditExternalAssessment(externalAssessment));
        }
    }

    function setSelectedTermBinAndDates(initialDate) {
        $scope.assessment.date = getDefaultDatesFromTermBin($scope.selectedTermBin, initialDate);
        onDateChange($scope.assessment.date);
    }

    function getDefaultDatesFromTermBin(termBin, initialDate) {
        var defaultDate = null;
        var today = moment(initialDate);

        // Term bin doesn't exist
        if (!termBin) {
            defaultDate = today;
        } else {
            $scope.minDate = moment(termBin.start_date);
            $scope.maxDate = moment(termBin.end_date);

            // Today is within term bin start and end dates, use today
            if (today.isSameOrAfter($scope.minDate) && today.isSameOrBefore($scope.maxDate)) {
                defaultDate = today;
            }

            // Term bin is in the future, use start date
            if (today.isBefore($scope.minDate)) {
                defaultDate = $scope.minDate;
            }

            // Term bin is in the past, use end date
            if (today.isAfter($scope.maxDate)) {
                defaultDate = $scope.maxDate;
            }

            // Assessment already exists, use its date
            if ($scope.assessment.date) {
                defaultDate = moment($scope.assessment.date);
            }
        }

        // pikaday requires this format to implement date bounds
        $scope.minDate = $scope.minDate.toDate();
        $scope.maxDate = $scope.maxDate.toDate();

        return defaultDate;
    }

    function getFormOptions() {
        var objectiveExpand = objectiveLevelForGrades > 0 ? 'parent_objective' : '';

        return $q.all({
            objectives: ObjectiveService.get($routeParams.course_id, objectiveExpand),
            staffMembers: StaffMemberService.get()
        });
    }

    function processFormOptions(data) {
        courseObjectives = _.get(data.objectives, 'results.objectives');
        $scope.objectiveOptions = formatObjectiveOptions(courseObjectives);
        $scope.staffMemberOptions = _.get(data.staffMembers, 'results.staff_members');
    }

    function formatObjectiveOptions(objectives) {
        var newOrderKey = 0;

        return _.map(objectives, objective => {
            var code = objective['code'] ? '[' + objective['code'] + '] ' : '';
            newOrderKey++;
            return _.extend({}, objective, {
                display_name: code + objective['description'],
                new_order_key: newOrderKey,
            });
        })
    }

    function getCourseSectionDescription() {
        var courseName = CourseService.getNameForCourseId($routeParams.course_id);

        var sectionIds = _.split($routeParams.section_ids, ',');
        var sectionNames = SectionService.getNamesForSectionIds(sectionIds);

        var firstTwoSectionNames = sectionNames.splice(0, 2);
        var sections = firstTwoSectionNames.join(', ');
        if (sectionNames.length) {
            sections += `,
                <a tss-tooltip tss-tooltip-auto-size title="${sectionNames.join('<br />')}">
                    + ${sectionNames.length} More
                </a>`;
        }

        return $sce.trustAsHtml(_.escape(`${courseName} - ${$scope.selectedTermBin.short_name} - ${sections}`));
    }

    function getSaveButtonText() {
        if ($scope.isEdit) {
            return $scope.saving ? 'Updating...' : 'Update';
        } else {
            return $scope.saving ? 'Saving...' : 'Save';
        }
    }

    function save() {
        $scope.saving = true;
        var course = CourseService.findById($routeParams.course_id);
        var departmentId = _.get(course, 'department_id', null);
        var data = {
            // avoid errors due to the usage of different js “date” types (moment vs datepicker vs Date)
            date: $scope.assessment.date.toString('YYYY-MM-DD'),
            created_by_id: userId,
            school_id: schoolId,
            is_summary: 0,
            from_gradebook: 1,
            course_id: $routeParams.course_id,
            department_id: departmentId,
            section_ids: $routeParams.section_ids
        };

        if ($scope.isEdit) {
            // if we're just editing the assessment itself, then just update
            // the assessment record and optionally the assessment_question if
            // they changed the objective
            var params = _.extend({}, $scope.assessment, {date: data.date});
            AssessmentService.put($scope.assessment.assessment_id, params)
                .then(updateAssessmentQuestion)
                .then(updateRouteParams)
                .catch(handleSaveFailed);
        } else {
            var params = _.extend({}, $scope.assessment, data);
            var externalAssessment = $scope.externalAssessment; // take this out of the scope here so we can add multiple back to back

            if (externalAssessment) {
                $scope.assessment.date = null; // reset form: force pikaday to reload
                previousPage();
                externalAssessment.previousState = externalAssessment.state;
                externalAssessment.state = 'saving';
                $scope.saving = false; // allow saving another one
            }

            AssessmentService.post(params)
                .then(_.partial(createAssessmentQuestion, externalAssessment))
                .then(_.partial(optionallyStoreExternalResults, externalAssessment))
                .then(_.partial(optionallyUpdateRouteParams, externalAssessment))
                .catch(_.partial(handleSaveFailed, externalAssessment));
        }
    }

    function handleSaveFailed(externalAssessment, e) {
        $scope.saving = false;

        if (externalAssessment && externalAssessment.previousState) {
            externalAssessment.state = externalAssessment.previousState;
        }
    }

    function optionallyStoreExternalResults(externalAssessment, assessmentAndQuestion) {
        return externalAssessment
            ? ExternalAssessmentService.storeResultsToSchoolrunner(externalAssessment, assessmentAndQuestion)
            : $q.when(assessmentAndQuestion);
    }

    function optionallyUpdateRouteParams(externalAssessment, assessmentAndQuestion) {
        return !externalAssessment
            ? updateRouteParams(assessmentAndQuestion, true)
            : $q.when(assessmentAndQuestion);
    }

    function createAssessmentQuestion(externalAssessment, assessment) {
        var questions = _.get(externalAssessment, 'questions') || [];

        if (questions.length) {
            return {
                assessment: assessment,
            };
        }

        var assessmentId = assessment.assessment_id;
        var data = {
            question_name: 1,
            question_number: 1,
            point_value: 100,
            course_id: assessment.course_id,
            objective_id: assessment.objective_id
        }

        return AssessmentQuestionService.post(assessmentId, data)
            .then(assessmentQuestion => {
                return {
                    assessment: assessment,
                    assessment_question: assessmentQuestion
                };
            });
    }

    function updateAssessmentQuestion(assessment) {
        var assessmentId = assessment.assessment_id;
        return AssessmentQuestionService.getAll(assessmentId)
            .then(assessmentQuestions => determineIfQuestionNeedsUpdate(assessment, assessmentQuestions))
            .then(updateQuestion)
            .catch((assessmentAndQuestion) => assessmentAndQuestion);
    }

    function determineIfQuestionNeedsUpdate(assessment, assessmentQuestions) {
        var isMultiQuestionAssessment = assessmentQuestions.length > 1;
        var questionObjectiveId = _.get(assessmentQuestions, '[0]objective_id');
        var objectiveChanged = assessment.objective_id != questionObjectiveId;
        var assessmentAndQuestion = {
            assessment: assessment,
            assessment_question: null
        };

        // don't update the assessment question if:
        // it's a multi-question assessment
        // or if the objective hasn't been changed
        if (isMultiQuestionAssessment || !objectiveChanged) {
            return $q.reject(assessmentAndQuestion);
        }

        return _.extend({}, assessmentAndQuestion, { assessment_question: assessmentQuestions[0] });
    }

    function updateQuestion(assessmentAndQuestion) {
        var assessment = assessmentAndQuestion.assessment;
        var assessmentQuestion = assessmentAndQuestion.assessment_question;
        var data = {
            objective_id: assessment.objective_id
        };
        return AssessmentQuestionService.put(assessment.assessment_id, assessmentQuestion.assessment_question_id, data)
            // overwrite with the updated assessment question
            .then(assessmentQuestion => _.extend({}, assessmentAndQuestion, { assessment_question: assessmentQuestion }));
    }

    function updateRouteParams(assessmentAndQuestion, editingScores = false) {
        var newParams = {
            focused_assessment_id: _.toString(_.get(assessmentAndQuestion, 'assessment.assessment_id')),
            editing_scores: editingScores ? 1 : 0
        };
        $route.updateParams(_.extend({}, $routeParams, newParams));
    }

    function onDateChange(date) {
        if (!date) {
            return;
        }

        AssessmentTypeService.get($routeParams.course_id, $routeParams.term_bin_id, date.toString('YYYY-MM-DD'))
            .then(processAssessmentTypeFormOptions)
    }

    function processAssessmentTypeFormOptions(response) {
        var assessmentTypeOptions = _.chain(response)
            .get('results.assessment_types')
            .filter(record => record.active == 1)
            .value();

        if (!$scope.importingFromExternal) {
            assessmentTypeOptions = _.filter(assessmentTypeOptions, 'countsForGrades');
        }

        $scope.assessmentTypeOptions = assessmentTypeOptions;
    }

    function goToAddAssessmentPage() {
        var urlData = _.merge({}, $scope.assessment, {
            ref: 'gradebook',
            course_id: $routeParams.course_id,
            section_period_ids: SectionService.getSectionPeriodIdsForSectionIds(_.split($routeParams.section_ids, ',')).join(','),
            date: $scope.assessment.date.toString('YYYY-MM-DD')
        });
        var params = $httpParamSerializer(urlData);
        $scope.closeThisDialog();
        $window.open('/academics/add/assessment?' + params, '_blank');
    }

    function goToEditAssessmentPage() {
        $scope.closeThisDialog();
        $window.open('/assessment/edit/' + $scope.assessment.assessment_id + '?ref=gradebook', '_blank');
    }

    function nextPage() {
        setPage($scope.page + 1);
    }

    function previousPage() {
        $scope.externalAssessment = null;
        setPage($scope.page - 1);
    }

    function setPage(page) {
        $scope.prevPage = $scope.page;
        $scope.page = page;
    }

    function showAddEditExternalAssessment(externalAssessment) {
        if (!externalAssessment) return;

        $scope.assessment.external_source = externalAssessment.source;
        $scope.assessment.name = externalAssessment.name;
        $scope.assessment.course_wide = externalAssessment.courseWide ? 1 : 0;
        $scope.assessment.assessment_question_count = _.get(externalAssessment, 'questions.length')

        var objectives = _.chain(externalAssessment)
            .get('questions')
            .map('objective_code')
            .uniq()
            .value();
        if (objectives.length > 1) {
            $scope.assessment.objective_id = null;
            $scope.standardRequired = false;
            $scope.objectiveSettings.placeholder = '(Multiple)';
        } else {
            if (objectives.length) {
                $scope.assessment.objective_id = _.chain(courseObjectives)
                    .find(o => o.code == objectives[0])
                    .get('objective_id')
                    .value();
            }
            $scope.standardRequired = $scope.defaultStandardRequired;
            $scope.objectiveSettings.placeholder = 'No Option Selected';
        }

        setSelectedTermBinAndDates(externalAssessment.date);
        nextPage();
    }
}]);


    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
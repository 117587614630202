var _module_exports = "<div class=\"course-grade-cell text-right\">\n    <div ng-if=\"cell.courseGrade.invalid\">\n        <i class=\"icon-spinner icon-spin\"></i>\n    </div>\n    <div ng-if=\"!cell.courseGrade.invalid\">\n        <span\n            ng-style=\"{\n                'color': cell.getCourseGradeScoreColor(cell.courseGrade.levelIndex)\n            }\"\n            class=\"score-display\"\n            title=\"{{ cell.getTitleName() }}\">\n            {{ cell.getDisplayName() }}\n        </span>\n        <div class=\"indicators\" data-module=\"Indicators\">\n            <!--\n                comment indicator is hidden and just for styling purposes for now.\n                it will be helpful if/when we want to add course grade overrides\n                and comments from gradebook.\n                see assessment-student-cell.html for a working example.\n            -->\n            <div class=\"comment-indicator\">\n                <i class=\"icon-comment\"></i>\n            </div>\n            <div\n                class=\"score-override-indicator\"\n                ng-if=\"cell.courseGrade.score_override != null && cell.courseGrade.score_override !== ''\"\n                data-feature=\"Course Grade Override Indicator\">\n                <i\n                    class=\"icon-info-sign text-sr-gray-04\"\n                    tss-popover\n                    tss-popover-settings=\"cell.overridePopoverSettings\">\n                </i>\n            </div>\n        </div>\n    </div>\n</div>\n";;
var path = '/views/gradebook/common/course-grade-cell/course-grade-cell.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
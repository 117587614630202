var _module_exports = "<div class=\"tss-date\">\n    <i\n        class=\"icon-calendar holiday-indicator\"\n        ng-show=\"holidayMessage\"\n        tss-tooltip\n        title=\"{{ holidayMessage }}\">\n    </i>\n    <input \n        type=\"text\"\n        pikaday=\"ngModel\"\n        format=\"MM/DD/YYYY\"\n        placeholder=\"MM/DD/YYYY\"\n        on-select=\"onDateSelect(pikaday)\"\n        on-draw=\"onDateDraw()\"\n        default-date=\"{{ ngModel.format('MM/DD/YYYY') }}\"\n        min-date=\"minDate\"\n        max-date=\"maxDate\"\n        set-default-date=\"true\" />\n</div>\n";;
var path = '/views/gradebook/common/tss-date/tss-date.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
angular
    .module('common')
    .controller('AssessmentStudentCommentsController',
        ['$scope',
        'AssessmentStudentCommentService', 
       

function (
    $scope,
    AssessmentStudentCommentService
) {
    $scope.handlePostClick = handlePostClick;

    /**
    * Handes post comment click
    */
    function handlePostClick(assessmentStudentId, newComment) {
        AssessmentStudentCommentService.post(
            assessmentStudentId,
            newComment
        ).then(handlePostCommentSuccess);
    };

    /**
     * Handles post comment success
     * @param {object} response
     */
    function handlePostCommentSuccess(response) {
        $scope.assessmentStudentComment.comment = '';
        var comments = _.get(response, 'results.comments');
        _.set($scope, '$parent.assessmentStudent.comments', comments);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
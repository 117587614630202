angular
    .module('common')
    .controller('AssessmentBucketHeaderCellController', [

function (
) {
    var vm = this;

    vm.toggleBucket = toggleBucket;

    function toggleBucket($event) {
        $event.stopPropagation();
        vm.bucket.open = !vm.bucket.open;
    }

}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import overridePopoverTemplate from './objective-grade-override-popover.html';
import * as helpers from '../../../helpers';

angular
    .module('common')
    .controller('SbgAssessmentBucketCellController', [
        'SettingService',
        '$element',
        '$scope',
        '$rootScope',
        '$timeout',
        '$keyCodes',
      
    function (
        SettingService,
        $element,
        $scope,
        $rootScope,
        $timeout,
        $keyCodes
    ) {
    var vm = this;
    var clonedStudentObjectiveBucket;
    var objectiveId;

    vm.$onInit = init;

    vm.enterObjectiveGradesByLevel = enterObjectiveGradesByLevel;
    vm.navigateCells = navigateCells;
    vm.gradeChanged = gradeChanged;
    vm.commentChanged = commentChanged;

    function init() {
        objectiveId = vm.studentObjectiveBucket.objective_id;

        vm.state = {
            focused: false
        }
        vm.studentObjectiveBucket.score_override = !_.isNull(_.get(vm.studentObjectiveBucket, 'score_override', null))
            ? parseFloat(vm.studentObjectiveBucket.score_override)
            : null;
        vm.studentObjectiveBucket.raw_score = helpers.getRoundedScore(vm.studentObjectiveBucket, 'raw_score')
        vm.studentObjectiveBucket.gradebook_score = helpers.getRoundedScore(vm.studentObjectiveBucket, 'gradebook_score')
        vm.modelKey = 'score_override';
        vm.placeholderKey = 'raw_score';
        vm.objectiveBucket.showComments = false;
        vm.toggleComments = toggleComments;

        var baseUrl = `/api/v1/objective-grades/${vm.studentObjectiveBucket.objective_grade_id}`;
        var expands = `change_logs.change_log_details,change_logs.user.staff_member`;
        var changeLogFilters = `{"filters":{"change_log_details.column=":["score_override",null]},"limit":1}`;

        vm.tssPopoverSettings = {
            width: 250,
            right: true,
            closeOnContentsClick: true,
            templateUrl: overridePopoverTemplate,
            dataUrl: vm.studentObjectiveBucket.objective_grade_id ? `${baseUrl}?expand=${expands}&objective_grade_change_log_filters=${changeLogFilters}` : null,
            extraData: {
                raw_score: vm.studentObjectiveBucket.raw_score,
            },
            helpers: {
                enterByLevel: enterObjectiveGradesByLevel,
                formatDateString: formatDateString,
                getScoreLevelName: getScoreLevelName
            }
        };
        vm.gradingScaleLevelSelectOptions = getGradingScalelevelOptions();
        vm.gradingScaleLevelSelectSettings = { placeholder: '...' };
        vm.fillDownSettings = {
            type: 'Objective',
            id: vm.studentObjectiveBucket.objective_id,
        }
        vm.scoreColor = getScoreColor();
        vm.titleName = getTitleName();
        vm.displayName = getDisplayName();

        $scope.$on('focusObjectiveGrade.' + vm.index, focusInput);
        $scope.$on('editingObjectiveGrades', onEditingObjectiveGrades);
        $scope.$on('cancelEditingObjectiveGrades.' + objectiveId, revertStudentObjectiveBucket);

        $scope.$on('$destroy', handleDestroy);
    }

    function navigateCells(event) {
        var code = event.keyCode;
        if (_.includes([$keyCodes.UP, $keyCodes.DOWN], code)) {
            event.preventDefault();
            var index = (code == $keyCodes.DOWN) ? vm.index + 1 : vm.index - 1;
            $rootScope.$broadcast('focusObjectiveGrade.' + index);
        }
    }

    function getGradingScalelevelOptions() {
        return enterObjectiveGradesByLevel() ? vm.gradingScale.levels : [];
    }

    function onEditingObjectiveGrades(event, bucket) {
        if (vm.objectiveBucket != bucket) {
            return;
        }

        // clone objective grade for reverting on cancel
        clonedStudentObjectiveBucket = _.cloneDeep(vm.studentObjectiveBucket);

        // put focus in first element
        if (vm.index == 0) {
            focusInput();
        }
    }

    function revertStudentObjectiveBucket() {
        // we want to overwrite the original object here so that angular
        // knows to update this objective grade in the parent view
        _.extend(vm.studentObjectiveBucket, clonedStudentObjectiveBucket, { has_changed: false });
    }

    function focusInput() {
        $timeout(() => $element.find('input').select());
    }

    function formatDateString(dateString) {
        return moment(dateString).format('MM/DD/YY');
    }

    function getScoreLevelName(score) {
        var level = Functions.getScoreLevel(score, vm.gradingScale.levels, true, true);
        return level.abbreviation || level.name;
    }

    function getScoreColor() {
        var gradingScaleLevelIndex = vm.studentObjectiveBucket.levelIndex;
        return parseInt(SettingService.get('gradebook_grade_color_coding', 1)) == 1
            ? vm.gradingScale.colorScale(gradingScaleLevelIndex)
            : null;
    }

    function getTitleName() {
        var levelName = _.get(vm.studentObjectiveBucket, 'levelName', '');
        var levelAbbreviation = _.get(vm.studentObjectiveBucket, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore(vm.studentObjectiveBucket, 'gradebook_score');

        return `${!_.isNull(roundedScore) ? `${levelName}${levelAbbreviation ? ` (${levelAbbreviation})` : ''} ${roundedScore}%` : ''}`;
    }

    function getDisplayName() {
        var levelName = _.get(vm.studentObjectiveBucket, 'levelName', '');
        var levelAbbreviation = _.get(vm.studentObjectiveBucket, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore(vm.studentObjectiveBucket, 'gradebook_score');

        switch (getScoreDisplayMode()) {
            case 'score':
                return `${!_.isNull(roundedScore) ? `${roundedScore}%` : ''}`;
            case 'grade-level':
                return levelAbbreviation || levelName;
            case 'score-and-grade-level':
                return `${!_.isNull(roundedScore) ? `${roundedScore}% ${levelAbbreviation || levelName}` : ''}`;
        }
    }

    function gradeChanged(newGrade) {
        vm.studentObjectiveBucket.has_changed = true;
    }

    function commentChanged(newComment) {
        vm.studentObjectiveBucket.has_changed = true;
    }

    // enter objective grades by level if bucket grades are displayed by level
    function enterObjectiveGradesByLevel() {
        return getScoreDisplayMode() == 'grade-level';
    }

    function getScoreDisplayMode() {
        return SettingService.get('gradebook_score_display', 'score');
    }

    // if you're NOT editing scores and you click a comment bubble, show the comment
    // if you ARE editing scores and you click a comment bubble, open comments for edit
    function toggleComments(event) {
        focusInput();
        if (vm.objectiveBucket.editingObjectiveGrades) {
            vm.objectiveBucket.showComments = !vm.objectiveBucket.showComments;
        }
    }

    function handleDestroy() {
        clonedStudentObjectiveBucket = null;
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
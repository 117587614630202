import externalCoursesTemplate from './external-courses.html';
import './external-courses.controller.js';

angular
    .module('common')
    .component('externalCourses', {
        bindings: {
            previousPage: '&',
            nextPage: '&',
            closeThisDialog: '&',
            externalService: '=',
            externalProfile: '=',
            externalCourse: '=',
            externalIntegration: '=',
        },
        controller: 'ExternalCoursesController as externalCourses',
        templateUrl: externalCoursesTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import sbgAssessmentBucketHeaderCellTemplate from './sbg-assessment-bucket-header-cell.html';
import './sbg-assessment-bucket-header-cell.controller.js';

angular
    .module('common')
    .component('sbgAssessmentBucketHeaderCell', {
        bindings: {
            bucket: '=',
            color: '@',
            getObjectiveGrades: '&',
            onAfterSave: '&',
        },
        controller: 'SbgAssessmentBucketHeaderCellController as cell',
        templateUrl: sbgAssessmentBucketHeaderCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
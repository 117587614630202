angular
    .module('common')
    .controller('SbgAssessmentBucketHeaderCellController',[
        'ObjectiveGradeService',
        '$q',
        '$rootScope',
        '$scope',

    function (
        ObjectiveGradeService,
        $q,
        $rootScope,
        $scope
    ) {
    var vm = this;

    vm.toggleBucket = toggleBucket;
    vm.overrideObjectiveGrades = overrideObjectiveGrades;
    vm.cancelObjectiveGradesEdit = cancelObjectiveGradesEdit;
    vm.saveObjectiveGrades = _.debounce(saveObjectiveGrades, 1000, {leading: true, trailing: false});

    vm.$onInit = init;

    function init() {
        vm.bucket.editingObjectiveGrades = false;
        vm.bucket.savingObjectiveGrades = false;
        // all bucket header cells listen for objective score editing, and close if they
        // are not the objective being edited - ensures we can only edit one at a time
        var objectiveGradeEditingListener = $scope.$on('editingObjectiveGrades', (event, bucket) => {
            if (vm.bucket.objective.objective_id != bucket.objective.objective_id && vm.bucket.editingObjectiveGrades) {
                cancelObjectiveGradesEdit();
            }
        });
        // if we open assessment objective scores for edit, then we cant also be editing objective grades
        var assessmentObjectiveScoreEditingListener = $scope.$on('editingAssessmentObjectiveScores', (event, assessment) => {
                cancelObjectiveGradesEdit();
        });
        $scope.$on('$destroy', assessmentObjectiveScoreEditingListener);
        $scope.$on('$destroy', objectiveGradeEditingListener);
    }

    function toggleBucket($event) {
        $event.stopPropagation();
        if (vm.bucket.assessments.length) {
            vm.bucket.open = !vm.bucket.open;
        }
    }

    function overrideObjectiveGrades() {
        vm.bucket.editingObjectiveGrades = true;
        var objectiveId = vm.bucket.objective.objective_id;
        $rootScope.$broadcast('editingObjectiveGrades', vm.bucket);
    }

    function cancelObjectiveGradesEdit($event) {
        if ($event) {
            $event.stopPropagation();
        }
        vm.bucket.editingObjectiveGrades = false;
        vm.bucket.showComments = false;
        var objectiveId = vm.bucket.objective.objective_id;
        $rootScope.$broadcast('cancelEditingObjectiveGrades.' + objectiveId, vm.bucket);
    }

    function completeSaveAndEdit() {
        vm.bucket.editingObjectiveGrades = false;
        vm.bucket.savingObjectiveGrades = false;
    }

    function saveObjectiveGrades($event) {
        $event.stopPropagation();
        vm.bucket.savingObjectiveGrades = true;

        var objectiveGrades = vm.getObjectiveGrades()(vm.bucket);
        var requests = _.map(objectiveGrades, (objectiveGrade) => {
            if (objectiveGrade.objective_grade_id) {
                return ObjectiveGradeService.put(objectiveGrade.objective_grade_id, objectiveGrade);
            } else {
                return ObjectiveGradeService.post(objectiveGrade);
            }
        });
        $q.all(requests)
            .then(_.partial(vm.onAfterSave(), vm.bucket))
            .then(completeSaveAndEdit);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import studentCellTemplate from './assessment-student-cell.html';
import studentCellPopoverTemplate from './assessment-student-override-popover.html';
import * as helpers from '../../helpers';

angular
    .module('common')
    .directive('assessmentStudentCell', [
    'ngDialog',
    'SettingService',
    '$timeout',
    '$rootScope',
    '$keyCodes',
       
function (
    ngDialog,
    SettingService,
    $timeout,
    $rootScope,
    $keyCodes
) {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            index: '<',
            assessment: '<',
            student: '=',
            gradingScale: '<',
            assessmentStudent: '=',
            filldownSettings: '='
        },
        templateUrl: studentCellTemplate,
        link: assessmentStudentCellLink
    };

    function assessmentStudentCellLink($scope, $element) {
        var clonedAssessmentStudent;
        var clonedStudent;
        var assessmentId = $scope.assessment.assessment_id;
        var studentId = $scope.student.student_id;

        $scope.gradingScaleLevelSelectOptions = getGradingScalelevelOptions();

        $scope.assessmentStudent.avg_score = !_.isNil($scope.assessmentStudent.avg_score)
            ? Math.round($scope.assessmentStudent.avg_score)
            : null;
        $scope.assessmentStudent.score_override = !_.isNil($scope.assessmentStudent.score_override)
            ? parseFloat($scope.assessmentStudent.score_override)
            : null;

        $scope.tssPopoverSettings = {
            width: 250,
            right: true,
            closeOnContentsClick: true,
            onHover: false,
            templateUrl: studentCellPopoverTemplate,
            dataUrl: $scope.assessmentStudent.assessment_student_id ? '/api/v1/assessment-student-score-override/' + $scope.assessmentStudent.assessment_student_id : null,
            extraData: {
                scoreDisplayMode: getScoreDisplayMode(),
                gradingScaleLevels: $scope.gradingScale.levels
            },
            helpers: {
                getGradingScaleLevelNameForScore: getGradingScaleLevelNameForScore
            }
        };

        $scope.presentMissingOrExcused = getPresentMissingOrExcused();
        $scope.scoreColor = getScoreColor();
        $scope.displayName = getDisplayName();
        $scope.titleName = getTitleName();
        $scope.modelKey = getModelKey();
        var keyIndicatingAssessmentForOverride = 'score_override';
        $scope.assessmentStudent.for_override = $scope.modelKey == keyIndicatingAssessmentForOverride;
        $scope.assessmentStudent.enter_grades_by_level = $scope.assessment.enter_grades_by_level;
        $scope.placeholderKey = 'avg_score';
        $scope.assessment.showComments = false;
        $scope.toggleComments = toggleComments;

        init();

        function init() {
            $scope.$on('focusAssessmentStudent.' + $scope.index, focusInput);
            $scope.$on('editingAssessmentStudentScores', onEditingAssessmentStudentScores);
            $scope.$on('cancelEditingAssessmentStudentScores.' + assessmentId, revertAssessmentStudent);
            $scope.$on('$destroy', handleDestroy);
        }

        function onEditingAssessmentStudentScores(event, assessment) {
            if ($scope.assessment != assessment) {
                return;
            }

            // clone assessment student for reverting
            clonedAssessmentStudent = _.cloneDeep($scope.assessmentStudent);

            // put focus in first element
            if ($scope.index == 0) {
                focusInput();
            }
        }

        function revertAssessmentStudent() {
            // we want to overwrite the original object here so that angular
            // knows to update this assessment student in the parent view
            _.extend($scope.assessmentStudent, clonedAssessmentStudent, { changed: false });
        }

        function focusInput() {
            $timeout(() => $element.find('input').select());
        }

        function handleDestroy() {
            clonedAssessmentStudent = null;
            clonedStudent = null;
        }

        function getGradingScalelevelOptions() {
            var gradingScaleLevels = [];
            // if not entering grades by level, we're done here
            if ($scope.assessment.enter_grades_by_level == 1) {
                var allowedGradingLevelIds = _.chain($scope.assessment.allowed_grading_scale_level_ids)
                    .split(',')
                    .compact()
                    .value();

                // if no specifically-allowed levels, all levels are allowed
                // otherwise, only levels in the allowed list are allowed
                if (_.isEmpty(allowedGradingLevelIds)) {
                    gradingScaleLevels = $scope.gradingScale.levels;
                } else {
                    gradingScaleLevels = _.chain($scope.gradingScale.levels)
                    .filter(level => {
                        return _.includes(allowedGradingLevelIds, level.grading_scale_level_id);
                    })
                    .value();
                }
            }

            return gradingScaleLevels;
        }

        function getScoreDisplayMode() {
            return SettingService.get('gradebook_score_display', 'score');
        }

        function getScoreColor() {
            if ($scope.presentMissingOrExcused == 'present') {
                var gradingScaleLevelIndex = $scope.assessmentStudent.levelIndex;
                return parseInt(SettingService.get('gradebook_grade_color_coding', 1)) == 1
                    ? $scope.gradingScale.colorScale(gradingScaleLevelIndex)
                    : null;
            }

            return null;
        }

        function getGradingScaleLevelNameForScore(score, gradingScaleLevels) {
            var gradingScaleLevel = Functions.getScoreLevel(score, gradingScaleLevels);
            return gradingScaleLevel && (gradingScaleLevel.abbreviation || gradingScaleLevel.name);
        }

        // TODO: look at moving this up onto the assessment itself? why check every assessment student?
        function getModelKey() {
            return (parseInt($scope.assessmentStudent.assessment_question_count) == 1
                && parseInt($scope.assessmentStudent.assessment_question_point_value) == 100
                && $scope.assessmentStudent.assessment_question_correct_answer == null
                && $scope.assessment.external_source != 'google'
                && parseInt($scope.assessment.curve || 0) == 0
                && _.get($scope.assessmentStudent, 'score_override') == null)
                ? 'avg_score'
                : 'score_override';
        }

        // excused == inactive
        // missing == active + missing
        // present == active + !missing
        function getPresentMissingOrExcused() {
            var status = 'excused';
            if (_.get($scope.assessmentStudent, 'active') == 1) {
                if (_.get($scope.assessmentStudent, 'missing') == 1) {
                    status = 'missing';
                } else {
                    status = 'present';
                }
            }
            return status;
        }

        // if present, display actual grade
        // otherwise, display missing or excused
        function getTitleName() {
            if ($scope.presentMissingOrExcused == 'present') {
                var levelName = _.get($scope.assessmentStudent, 'levelName', '');
                var levelAbbreviation = _.get($scope.assessmentStudent, 'levelAbbreviation', null);
                var roundedScore = helpers.getRoundedScore($scope.assessmentStudent, 'gradebook_score');

                return `${!_.isNull(roundedScore) ? `${levelName}${levelAbbreviation ? ` (${levelAbbreviation})` : ''} ${roundedScore}%` : ''}`;
            }
            return _.startCase($scope.presentMissingOrExcused);
        }

        // if present, display actual grade
        // otherwise, display missing or excused
        function getDisplayName() {
            if ($scope.presentMissingOrExcused == 'present') {
                var levelName = _.get($scope.assessmentStudent, 'levelName', '');
                var levelAbbreviation = _.get($scope.assessmentStudent, 'levelAbbreviation', null);
                var roundedScore = helpers.getRoundedScore($scope.assessmentStudent, 'gradebook_score');

                switch (getScoreDisplayMode()) {
                    case 'score':
                        return `${!_.isNull(roundedScore) ? `${roundedScore}%` : ''}`;
                    case 'grade-level':
                        return levelAbbreviation || levelName;
                    case 'score-and-grade-level':
                        return `${!_.isNull(roundedScore) ? `${roundedScore}% ${levelAbbreviation || levelName}` : ''}`;
                }
            }
            return $scope.presentMissingOrExcused;
        }

        // if you're NOT editing scores and you click a comment bubble, show the comment
        // if you ARE editing scores and you click a comment bubble, open comments for edit
        function toggleComments(event) {
            focusInput();
            if ($scope.assessment.editingAssessmentStudentScores) {
                $scope.assessment.showComments = !$scope.assessment.showComments;
            } else if ($scope.$parent.forceAssessmentStudentEdit) {
                $scope.student.showComments = !$scope.student.showComments;
            }
        }

    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
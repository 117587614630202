import socketNotificationTemplate from './socket-notification.html';
import './socket-notification.controller.js';

angular
    .module('common')
    .component('socketNotification', {
        bindings: {
            socket: '=',
            showClose: '=',
            offlineTitle: '=',
            offlineText: '=',
            outOfDateTitle: '=',
            outOfDateText: '=',
        },
        controller: 'SocketNotificationController as notification',
        templateUrl: socketNotificationTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
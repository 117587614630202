import sortableHeaderCellTemplate from './sortable-header-cell.html';
import './sortable-header-cell.controller.js';

angular
    .module('common')
    .component('sortableHeaderCell', {
        bindings: {
            sortColumn: '@',
            sortedColumn: '=',
            sortFunc: '&'
        },
        transclude: true,
        controller: 'SortableHeaderCellController as headerCell',
        templateUrl: sortableHeaderCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import sbgAssessmentHeaderCellTemplate from './sbg-assessment-header-cell.html';
import './sbg-assessment-header-cell.controller.js';

angular
    .module('common')
    .component('sbgAssessmentHeaderCell', {
        bindings: {
            assessment: '=',
            getAssessmentStudents: '&',
            onAfterDeactivate: '&',
            onAfterSave: '&',
            color: '@'
        },
        controller: 'SbgAssessmentHeaderCellController as cell',
        templateUrl: sbgAssessmentHeaderCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import assessmentHeaderCellTemplate from './assessment-header-cell.html';
import './assessment-header-cell.controller.js';

angular
    .module('common')
    .component('assessmentHeaderCell', {
        bindings: {
            assessment: '=',
            color: '@',
            getAssessmentStudents: '&',
            onAfterDeactivate: '&',
            onAfterSave: '&',
        },
        controller: 'AssessmentHeaderCellController as cell',
        templateUrl: assessmentHeaderCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
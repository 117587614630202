var _module_exports = "<div class=\"fill-down-wrapper\">\n    <ng-transclude />\n    <i\n        ng-hide=\"fillDown.disabled\"\n        class=\"icon-circle-arrow-down\"\n        rel=\"fill-down\" \n        tss-tooltip \n        title=\"Fill Down\"\n        ng-click=\"fillDown.triggerFillDown($event)\">\n    </i>\n</div>\n";;
var path = '/views/gradebook/common/fill-down/fill-down.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
angular
    .module('services')
    .factory('GradingMethodologyBucketService', [
        '$http',
        '$httpParamSerializer',
    
    function (
        $http,
        $httpParamSerializer
    ) {
    var uri = '/api/v1/grading-methodology-buckets';

    return {
        getTermBinsForCourse: getTermBinsForCourse,
        getGradingMethodologyBucketContents: getGradingMethodologyBucketContents
    };

    function getTermBinsForCourse(termId, courseId) {
        var params = $httpParamSerializer({
            term_ids: termId,
            course_ids: courseId,
            expand: 'term_bin',
            active: 1,
            term_bin_active: 1,
        });

        return $http.get(uri + '?' + params)
            .then(_.property('results.grading_methodology_buckets'))
            .then(getTermBinsFromGradingMethodologyBuckets);
    }

    function getTermBinsFromGradingMethodologyBuckets(gradingMethodologyBuckets) {
        return _.chain(gradingMethodologyBuckets)
            .map('term_bin')
            .uniqBy('term_bin_id')
            .value();
    }

    function getGradingMethodologyBucketContents(courseId, termId, termBinId) {
        var params = $httpParamSerializer({
            term_ids: termId,
            term_bin_ids: termBinId,
            course_ids: courseId,
            expand: 'grading_methodology_bucket_contents',
            active: 1,
        });

        return $http.get(uri + '?' + params)
            .then(_.property('results.grading_methodology_buckets'))
            .then(getContentsFromGradingMethodologyBucket);
    }

    function getContentsFromGradingMethodologyBucket(gradingMethodologyBuckets) {
        return _.chain(gradingMethodologyBuckets)
            .flatMap('grading_methodology_bucket_contents')
            .value();
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import * as helpers from '../../helpers';

angular
    .module('gradebook')
    .controller('StudentAssessmentsEditController', [  
        '$element',
        '$location',
        '$rootScope',
        '$routeParams',
        '$scope',
        '$sce',
        '$timeout',
        '$window',
        'AssessmentService',
        'CourseService',
        'SectionService',
        'SettingService', 
        
    function ( $element,
        $location,
        $rootScope,
        $routeParams,
        $scope,
        $sce,
        $timeout,
        $window,
        AssessmentService,
        CourseService,
        SectionService,
        SettingService
    ){
var clonedStudent;

    $scope.student = _.extend({}, $scope.ngDialogData ? $scope.ngDialogData.student : {});
    $scope.assessments = _.extend({}, $scope.ngDialogData ? $scope.ngDialogData.assessments : {});
    $scope.assessmentStudents = $scope.ngDialogData ? $scope.ngDialogData.assessmentStudents : [];
    $scope.gradingScales = _.extend({}, $scope.ngDialogData ? $scope.ngDialogData.gradingScales : {});
    $scope.onAfterSave = $scope.ngDialogData ? $scope.ngDialogData.onAfterSave : _.noop;

    $scope.save = _.debounce(save, 1000, {leading: true, trailing: false});
    $scope.getStudentDescription = getStudentDescription;
    $scope.goToAssessmentPage = goToAssessmentPage;
    $scope.toggleComments = toggleComments;
    $scope.completeSaveAndEdit = completeSaveAndEdit;
    $scope.cancelEdit = cancelEdit;
    $scope.hideBanner = hideBanner;

    init();

    function init() {
        $scope.deregisterFunc = $rootScope.$on('ngDialog.opened', sizeModal);
        $scope.$on('$destroy', handleDestroy);

        $scope.showComments = false;
        $scope.forceAssessmentStudentEdit = true;
        var view = $location.path().replace('/gradebook/', '');
        $scope.showBanner = _.includes(view, 'standards');

        _.each($scope.assessments, (assessment) => {
            assessment.editingAssessmentStudentScores = false;
            assessment.editingAssessmentObjectiveScores = false;
            assessment.showComments = false;
        });

        cloneStudent();
        focusInput();
    }

    function sizeModal() {
        var modal = angular.element('.student-assessments-edit-container');
        var modalPadding = 20;
        var windowHeight = $window.innerHeight;
        var modalHeight = windowHeight - (2 * modalPadding);
        modal.css({
            'margin-top': `${modalPadding}px`,
            'height': `${modalHeight}px`,
        });
    }

    function cloneStudent() {
        // clone student (including all assessement scores) for reverting
        clonedStudent = _.cloneDeep($scope.student);
    }

    function focusInput() {
        $timeout(() => $element.find('input').select(), 100);
    }

    function revertStudent() {
        // we want to overwrite the original object here so that angular
        // knows to update this student in the parent view
        _.merge($scope.student, clonedStudent);
    }

    function getScoreDisplayMode() {
        return SettingService.get('gradebook_score_display', 'score');
    }

    function getScoreDisplay() {
        var levelName = _.get($scope.student.course_grade, 'levelName', '');
        var levelAbbreviation = _.get($scope.student.course_grade, 'levelAbbreviation', null);
        var roundedScore = helpers.getRoundedScore($scope.student.course_grade, 'score');

        switch (getScoreDisplayMode()) {
            case 'score':
                return `${!_.isNull(roundedScore) ? `${roundedScore}%` : ''}`;
            case 'grade-level':
                return levelAbbreviation || levelName || '';
            case 'score-and-grade-level':
                return `${!_.isNull(roundedScore) ? `${roundedScore}% ${levelAbbreviation || levelName || ''}` : ''}`;
        }
    }

    function getStudentDescription() {
        var sectionName = `Section ${$scope.student.section_number}`;
        var courseGrade = getScoreDisplay();

        return $sce.trustAsHtml(_.escape(`${sectionName} - ${courseGrade}`));
    }

    function save() {
        $scope.student.savingScores = true;
        var changedAssessmentStudents = _.chain($scope.assessmentStudents)
            .filter('changed')
            .map(assessmentStudent => helpers.cleanAssessmentStudent(
                _.filter($scope.assessments, { assessment_id: "" + assessmentStudent.assessment_id }),
                assessmentStudent,
                $scope.gradingScales,
                $routeParams.course_id,
                [ $scope.student ]
            ))
            .value();
        if (changedAssessmentStudents.length) {
            return AssessmentService.saveScores(changedAssessmentStudents)
                .then(_.partial($scope.onAfterSave(), $scope.student))
                .finally(completeSaveAndEdit);
        } else {
            completeSaveAndEdit();
        }
    }

    function completeSaveAndEdit() {
        $scope.student.savingScores = false;
        $scope.closeThisDialog();
    }

    function cancelEdit() {
        revertStudent();
        $scope.closeThisDialog();
    }

    function goToAssessmentPage(assessmentId) {
        $window.open(`/assessment/${assessmentId}`, '_blank');
    }

    function toggleComments($event) {
        $scope.showComments = !$scope.showComments;
    }

    function handleDestroy() {
        clonedStudent = null;
        $scope.deregisterFunc();
    }

    function hideBanner() {
        $scope.showBanner = false;
    }

}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
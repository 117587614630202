var _module_exports = "<div class=\"popover-contents score-override-details\">\n    <div class=\"text-center\" ng-show=\"!popoverContentsData\">\n        <i class=\"icon-spinner icon-spin\"></i> <em>Loading Override Details</em>\n    </div>\n    <div ng-show=\"popoverContentsData.objective_grade && popoverContentsData.objective_grade.score_override\">\n        <h6>Score Override Details</h6>\n        <p>\n            <strong>Override Score:</strong> {{ helpers.enterByLevel()\n                ? helpers.getScoreLevelName(popoverContentsData.objective_grade.score_override)\n                : (popoverContentsData.objective_grade.score_override) }}\n        </p>\n        <p>\n            <strong>Raw Score:</strong> {{ helpers.enterByLevel()\n                ? helpers.getScoreLevelName(popoverContentsData.raw_score)\n                : (popoverContentsData.raw_score) }}\n        </p>\n        <p ng-if=\"popoverContentsData.objective_grade.change_logs[0].user.staff_member\">\n            <strong>By:</strong>\n            <a\n                href=\"/staffmember/{{ popoverContentsData.objective_grade.change_logs[0].user.staff_member.staff_member_id }}\">\n                {{ popoverContentsData.objective_grade.change_logs[0].user.display_name }}\n            </a>\n        </p>\n\n        <p ng-if=\"!popoverContentsData.objective_grade.change_logs[0].user.staff_member\">\n            <strong>By:</strong> {{ popoverContentsData.objective_grade.change_logs[0].user.display_name }}\n        </p>\n        <p ng-if=\"popoverContentsData.objective_grade.from_date\">\n            <strong>On:</strong> {{ helpers.formatDateString(popoverContentsData.objective_grade.from_date) }}\n        </p>\n    </div>\n    <div ng-show=\"popoverContentsData && !popoverContentsData.objective_grade\">\n        <p><em>Unable to find change history.</em></p>\n    </div>\n</div>\n";;
var path = '/views/gradebook/views/standards-based-grid/sbg-assessment-bucket-cell/objective-grade-override-popover.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
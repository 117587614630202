angular
    .module('services')
    .factory('AlertService', [

function () {
    var Growl = window.Growl;

    return {
        info: info,
        success: success,
        warning: warning,
        error: error
    };

    function info(message) {
        Growl.info({message: message});
    };

    function success(message) {
        Growl.success({message: message});
    };

    function warning(message) {
        Growl.warning({message: message});
    };

    function error(message) {
        Growl.error({message: message});
    };

}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import * as helpers from '../../helpers';

angular
    .module('common')
    .controller('ScoreInputController', 
        [
            '$scope',
            '$timeout',
            '$element',
            '$keyCodes',
            '$document',
            '$rootScope',
            '$window',
            'SettingService',
       
function (
    $scope,
    $timeout,
    $element,
    $keyCodes,
    $document,
    $rootScope,
    $window,
    SettingService
) {
    var vm = this;
    var watches = [];

    vm.dropdownOpen = false;
    vm.gradingScaleLevels = [];

    vm.toggleDropdown = toggleDropdown;
    vm.isDropdownOpen = isDropdownOpen;
    vm.selectDropdownOption = selectDropdownOption;
    vm.isDropdownOptionSelected = isDropdownOptionSelected;
    vm.getPlaceholder = getPlaceholder;
    vm.onInputChange = onInputChange;
    vm.onInputFocus = onInputFocus;
    vm.onInputBlur = onInputBlur;
    vm.onInputKeydown = onInputKeydown;
    vm.haveMinGradeOrMaxGradeWarning = haveMinGradeOrMaxGradeWarning;
    vm.showMinGradeMessageWarning = showMinGradeMessageWarning;
    vm.showMaxGradeMessageWarning = showMaxGradeMessageWarning;

    vm.$onInit = init;

    function init() {
        vm.minGrade = parseInt(SettingService.get('min_grade', 0));
        vm.maxGrade = 100;

        vm.model = _.extend(vm.model, {
            missing: parseInt(vm.model.missing),
            active: parseInt(vm.model.active),
            edit_key: vm.modelKey
        });

        vm.gradingScaleLevels = vm.gradingScaleLevels
            ? decorateGradingScaleLevels(vm.gradingScaleLevels)
            : vm.gradingScaleLevels;

        vm.dropdownOptions = getDefaultDropdownOptions().concat(vm.gradingScaleLevels || []);
        vm.initialFocusedOptionIndex = getInitialFocusedOptionIndex(vm.dropdownOptions);
        vm.focusedOptionIndex = vm.initialFocusedOptionIndex;

        // - Watch model changes
        //   - select missing, excused or grading scale level
        // - Typing in input will be handled via ng-change handler
        $scope.$watchCollection('input.model', updateInputValue);
        $scope.$watch('input.focusedOptionIndex', scrollFocusedOptionIntoView);
        $scope.$on(`fillDown.${vm.filldownSettings.type}.${vm.filldownSettings.id}`, onFillDown);
        $scope.$on(`fillDownDone.${vm.filldownSettings.type}.${vm.filldownSettings.id}`, onFillDownDone);

        angular.element('.biaxial-scroll-rows').on('scroll', positionFormMessages);
        $document.on('click', handleOutsideClick);
    }

    function getDefaultDropdownOptions() {
        return [
            { display_name: 'Missing', icon: 'icon-ban-circle', onOptionSelect: toggleMissing, hide: _.get(vm, 'hideMissingOption', false) },
            { display_name: 'Excused', icon: 'icon-remove', onOptionSelect: toggleExcused, hide: _.get(vm, 'hideExcusedOption', false) },
            { display_name: 'Fill Down', icon: 'icon-circle-arrow-down', onOptionSelect: fillDown, disabled: false }
        ];
    }

    function getInitialFocusedOptionIndex(options) {
        return _.findIndex(options, (option) => { return !option.hide }) - 1;
    }

    function decorateGradingScaleLevels(gradingScaleLevels) {
        return _.chain(gradingScaleLevels)
            .map(addClickHandlerToGradingScaleLevel)
            .orderBy('max_value', 'desc')
            .value();
    }

    function addClickHandlerToGradingScaleLevel(gradingScaleLevel) {
        var maxVal = _.isNull(gradingScaleLevel.max_value)
            ? 100
            : parseFloat(gradingScaleLevel.max_value);

        var minVal = _.isNull(gradingScaleLevel.min_value)
            ? 0
            : parseFloat(gradingScaleLevel.min_value);

        return _.extend({}, gradingScaleLevel, {
            max_value: maxVal,
            min_value: minVal,
            onOptionSelect: () => {
                vm.model.missing = 0;
                vm.model.active = 1;
                vm.model[vm.modelKey] = maxVal;
                vm.invalid = false;
            }
        });
    }

    function toggleMissing() {
        vm.model.missing = + !vm.model.missing;
        vm.model.active = 1;
        vm.invalid = false;
    }

    function toggleExcused() {
        vm.model.active = + !vm.model.active;
        vm.model.missing = 0;
        vm.invalid = false;
    }

    function setMissing() {
        vm.inputValue = 'Missing';
        vm.model.missing = 1;
        vm.model.active = 1;
        vm.invalid = false;
    }

    function setExcused() {
        vm.inputValue = 'Excused';
        vm.model.active = 0;
        vm.model.missing = 0;
        vm.invalid = false;
    }

    function fillDown() {
        $rootScope.$broadcast(
            `fillDown.${vm.filldownSettings.type}.${vm.filldownSettings.id}`,
            vm.index,
            vm.model,
            vm.modelKey
        );

        $timeout(broadcastFilldownDone, 1500);
    }

    function broadcastFilldownDone() {
        $rootScope.$broadcast(`fillDownDone.${vm.filldownSettings.type}.${vm.filldownSettings.id}`);
    }

    function onFillDown(event, index, newModel, srcModelKey) {
        if (vm.index > index) {
            vm.fillingdown = true;
            // set destination avg_score or score_override to whatever value
            // you were editing in the source cell you filled down from
            vm.model[vm.modelKey] = newModel[srcModelKey];
            vm.model.missing = newModel.missing;
            vm.model.active = newModel.active;

            if (vm.onChange()) {
                vm.onChange()(newModel[srcModelKey]);
            }
        }
    }

    function onFillDownDone() {
        vm.fillingdown = false;
    }

    function selectDropdownOption(option) {
        var result = _.invoke(option, 'onOptionSelect');
        toggleDropdown();
    }

    function updateInputValue(newVal, oldVal) {
        if (newVal != oldVal) {
            vm.model.changed = true;
        }

        vm.inputValue = getInputValue();

        updateFilldownDisabled();

        $timeout(positionFormMessages);
    }

    function getInputValue() {
        if (parseInt(vm.model.active) == 0) {
            return 'Excused';
        }

        if (parseInt(vm.model.missing) == 1) {
            return 'Missing';
        }

        if (!_.isNil(vm.model[vm.modelKey])
            && vm.model[vm.modelKey] !== ''
            && vm.gradingScaleLevels
            && vm.gradingScaleLevels.length > 0
        ) {
            var closestLevel = Functions.getScoreLevel(vm.model[vm.modelKey], vm.gradingScaleLevels, true, true);
            if (closestLevel) {
                var levelName = closestLevel.display_name;
                vm.model.levelName = levelName;
                return levelName;
            }
        }
        return vm.model[vm.modelKey]
    }

    function updateFilldownDisabled() {
        var filldownOption = _.find(vm.dropdownOptions, { display_name: 'Fill Down' });
        if (vm.inputValue == '' || _.isNil(vm.inputValue)) {
            filldownOption.disabled = true;
        } else {
            filldownOption.disabled = false;
        }
    }

    function getPlaceholder() {
        if (!_.isNil(vm.model[vm.placeholderKey])
            && vm.model[vm.placeholderKey] !== ''
            && vm.gradingScaleLevels
            && vm.gradingScaleLevels.length > 0
        ) {
            var closestLevel = Functions.getScoreLevel(vm.model[vm.placeholderKey], vm.gradingScaleLevels, true, true);
            return _.get(closestLevel, 'display_name') || 'Enter Score';
        }

        var placeholderScore = _.get(vm.model, vm.placeholderKey);

        return !_.isNil(placeholderScore) ? placeholderScore : 'Enter Score';
    }

    function onInputChange() {
        var inputValue = vm.inputValue;
        var lowerInputValue = _.toLower(inputValue);
        var newModelValue = null;

        if (vm.onChange()) {
            vm.onChange()(inputValue);
        }

        var shouldBeMissing = lowerInputValue === 'missing';
        var shouldBeExcused = lowerInputValue === 'excused';

        // set missing and active values based on input
        // set instead of toggle because if you already were missing and then
        // we re-type 'missing' over it, we want to make sure it stays missing
        // instead of toggling it off as if you had clicked the option
        if (shouldBeMissing) {
            setMissing();
            return;
        } else if (shouldBeExcused) {
            setExcused();
            return;
        }

        // if we are entering a grade level
        if (inputValue && vm.gradingScaleLevels && vm.gradingScaleLevels.length > 0) {
            var exactLevel = _.find(vm.gradingScaleLevels, (level) => _.toLower(level.display_name) === lowerInputValue);
            // if we have an exact match, it's good
            if (exactLevel) {
                newModelValue = exactLevel.max_value;
                vm.invalid = false;
            // if no match, it's invalid
            } else {
                vm.invalid = true;
                return;
            }
        // entering #, or blanked out cell
        // use isNaN instead of _.isNaN which is only true for values of NaN and type Number
        // lets us account for string numbers and empty strings
        } else if (!isNaN(inputValue)) {
            vm.invalid = false;
            newModelValue = inputValue;
        // something garbagey like a partially-completed 'missing', abort
        } else {
            vm.invalid = true;
            return;
        }

        // reset missing & active and update model value
        vm.model.missing = 0;
        vm.model.active = 1;
        vm.model[vm.modelKey] = newModelValue;
    }

    function onInputFocus() {
        vm.focused = true;
        $timeout(positionFormMessages);
    }

    function onInputBlur() {
        vm.focused = false;
    }

    function onInputKeydown(event) {
        var keycode = event.keyCode;

        // open on "down" and not already open
        // close on "tab" and already open
        if ((keycode == $keyCodes.DOWN && !isDropdownOpen())
            || (keycode == $keyCodes.TAB && isDropdownOpen() || keycode == $keyCodes.ESC && isDropdownOpen())) {
            return toggleDropdown();
        }

        // up/down to focus option
        // enter to select focused option
        if (keycode == $keyCodes.DOWN && vm.focusedOptionIndex < vm.dropdownOptions.length - 1) {
            vm.focusedOptionIndex++;
        } else if (keycode == $keyCodes.UP && vm.focusedOptionIndex > vm.initialFocusedOptionIndex) {
            vm.focusedOptionIndex--;
        } else if (keycode == $keyCodes.ENTER) {
            var focusedOption = vm.dropdownOptions[vm.focusedOptionIndex];
            selectDropdownOption(focusedOption);
        }
    }

    function toggleDropdown() {
        vm.dropdownOpen = !vm.dropdownOpen;
        // if we're opening it, figure out if it should open up or down for visiblity
        // wait for it to exist so we can get height
        // keep invisible until positioned so it doesn't flash while positioning
        if (vm.dropdownOpen) {
            $timeout(function () {
                var scoreInputElem = $element.find('.score-input');
                var container = angular.element('.biaxial-scroll-container');
                var optionsListWrapper = scoreInputElem.find('.options-list-wrapper');
                var boundingRect = scoreInputElem[0].getBoundingClientRect();
                var containerBoundingRect = container[0].getBoundingClientRect();
                var windowHeight = $window.innerHeight;
                var containerIsBelowWindow = (windowHeight - containerBoundingRect.bottom) < 0;
                // if container goes beyond screen, make sure there is enough room on screen
                // if container ends before screen, make sure there is enough room in container
                var spaceBelow = containerIsBelowWindow
                    ? windowHeight - boundingRect.bottom
                    : containerBoundingRect.bottom - boundingRect.bottom;

                if (spaceBelow < optionsListWrapper.height()) {
                    optionsListWrapper.addClass('toggle-up');
                    optionsListWrapper.css('top', `-${optionsListWrapper.height()}.px`);
                } else {
                    optionsListWrapper.removeClass('toggle-up');
                }
                optionsListWrapper.removeClass('invisible');
                optionsListWrapper.css('visiblity', 'visible');
            });
        }
    }

    function isDropdownOpen() {
        return vm.dropdownOpen;
    }

    function isDropdownOptionSelected(option) {
        return option.display_name == vm.inputValue;
    }

    function scrollFocusedOptionIntoView() {
        var optionsList = $element.find(`.options-list-wrapper`);
        var elem = $element.find(`.options-list-wrapper li:nth-child(${vm.focusedOptionIndex + 1})`);
        var position = elem.position();
        var height = elem.height();

        if (!elem.length) {
            return;
        }

        var aboveTop = position.top < 0;
        var belowBottom = (position.top + height) > optionsList.height();
        if (aboveTop || belowBottom) {
            elem.scrollintoview();
        }
    }

    function handleOutsideClick(event) {
        var target = angular.element(event.target);
        var elem = $element.find('.score-input');

        // if dropdown is open and we clicked outside of dropdown
        // close dropdown
        if (isDropdownOpen() && !target.closest(elem).length) {
            $timeout(toggleDropdown);
        }
    }

    function positionFormMessages() {
        if (!vm.focused) {
            return;
        }

        var scrollRowsElem = angular.element('.biaxial-scroll-rows');
        var formMessageElem = $element.find('.form-message:visible');

        if (!formMessageElem.length) {
            return;
        }

        var scrollContainerOffset = scrollRowsElem.offset();
        scrollContainerOffset.bottom = scrollContainerOffset.top + scrollRowsElem.height();
        scrollContainerOffset.right = scrollContainerOffset.left + scrollRowsElem.width();

        var messageOffset = formMessageElem.offset();
        messageOffset.bottom = messageOffset.top + formMessageElem.height();
        messageOffset.right = messageOffset.left + formMessageElem.outerWidth();

        if (formMessageElem.hasClass('form-message-right') && messageOffset.right > scrollContainerOffset.right) {
            formMessageElem.removeClass('form-message-right');
        } else if(!formMessageElem.hasClass('form-message-right') && messageOffset.left < scrollContainerOffset.left) {
            formMessageElem.addClass('form-message-right');
        }

        formMessageElem.addClass('positioned');
    }

    function haveMinGradeOrMaxGradeWarning() {
        return vm.model[vm.modelKey]
            && vm.model[vm.modelKey] != null
            && vm.model.missing == 0
            && vm.model.active == 1
            && (vm.model[vm.modelKey] < vm.minGrade || vm.model[vm.modelKey] > vm.maxGrade);
    }

    function showMinGradeMessageWarning() {
        return vm.focused
            && vm.model[vm.modelKey]
            && vm.model.missing == 0
            && vm.model.active == 1
            && vm.model[vm.modelKey] > 0
            && vm.model[vm.modelKey] < vm.minGrade;
    }

    function showMaxGradeMessageWarning() {
        return vm.focused
            && vm.model[vm.modelKey]
            && vm.model.missing == 0
            && vm.model.active == 1
            && vm.model[vm.modelKey] > vm.maxGrade;
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import * as helpers from '../../helpers'

angular
    .module('gradebook')
    .controller('TimelineGridController', [
        '$scope',
        '$rootScope',
        '$location',
        '$route',
        '$routeParams',
        '$timeout',
        '$q',
        '$window',
        'ngDialog',
        'GradebookService',
        'SettingService',
        'TermBinService',
        'SectionService',
        'SocketService',
        'settings', 

    function($scope,
        $rootScope,
        $location,
        $route,
        $routeParams,
        $timeout,
        $q,
        $window,
        ngDialog,
        GradebookService,
        SettingService,
        TermBinService,
        SectionService,
        SocketService,
        settings
    ) {
    var watches = [];
    var fetchGradebookDataPromise;
    var promiseCanceler;
    var courseListenerDeregister;

    $scope.filters = { sort: 'section_number' };
    $scope.socket = null;

    $scope.getSetting = SettingService.get;
    $scope.getAssessmentStudentsForAssessment = getAssessmentStudentsForAssessment;
    $scope.sortStudents = sortStudents;
    $scope.onAfterAssessmentDeactivation = fetchData;
    $scope.onAfterAssessmentScoreSave = fetchData;
    $scope.promiseIsNull = promiseIsNull;
    $scope.goToCoursePage = goToCoursePage;
    $scope.openLinkInNewTab = openLinkInNewTab;
    $scope.getSelectedSectionNames = getSelectedSectionNames;
    $scope.hasRequiredParams = hasRequiredParams;
    $scope.getEditingClasses = getEditingClasses;
    var hasEditingCellsClass = 'has-editing-cells';
    var editingWithCommentsClass = 'with-comments';

    init();

    function init() {
        setupSocket();
        fetchData();
        setupWatches();

        // extend so that previousRoute is a new object, otherwise it will reference
        // the same object as $route.current and will change when current route changes
        $route.previous = _.extend({}, $route.current);
    }

    // TODO: this is not optimized. figure out how to load updated assessment
    // without fetching and re-rendering ALL data
    function fetchData() {
        var env = angular.element('.js-env');
        var termId = env.data('term-id');
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        var order = SettingService.get('gradebook_assessment_date_order');
        promiseCanceler = $q.defer();

        if (courseId && sectionIds && termBinId) {
            $rootScope.$broadcast('loadingGradebookData', true);
            // load selected term bin every time we fetch data
            TermBinService.findById(termBinId)
                .then(termBin => $scope.selectedTermBin = termBin);

            fetchGradebookDataPromise = GradebookService.getTimelineData(
                courseId,
                sectionIds,
                termBinId,
                termId,
                order,
                null,
                null,
                { timeout: promiseCanceler.promise }
            );
            return fetchGradebookDataPromise
                .then(processAssessmentData)
                .then(() => $timeout(scrollAssessmentIntoView))
                .then(editAssessmentStudentScores)
                .then(clearFocusedAssessmentParams)
                .then(() => fetchGradebookDataPromise = null)
                .catch(_.noop)
                .finally(() => $rootScope.$broadcast('loadingGradebookData', false));
        } else {
            return $timeout(resetGradebookData);
        }
    }

    function fetchDataAndCloseDialog() {
        return fetchData().then(ngDialog.closeAll);
    }

    function processAssessmentData(response) {
        if (!response) {
            return $q.reject();
        }

        var courseGradingScale = _.get(response, 'results.course_grading_scale');
        var allGradingScales = _.get(response, 'results.all_grading_scales');

        $scope.course_grading_scale = helpers.cleanGradingScale(courseGradingScale);
        $scope.grading_scales = _.mapValues(allGradingScales, helpers.cleanGradingScale);
        $scope.assessments = _.get(response, 'results.assessments');
        $scope.students = _.get(response, 'results.students');

        // key assessments by assessment_id for fast lookup
        $scope.assessmentsKeyed = _.keyBy(_.get(response, 'results.assessments'), 'assessment_id');
        return response;
    }

    function scrollAssessmentIntoView() {
        var assessmentId = $routeParams.focused_assessment_id;
        if (assessmentId) {
            var assessmentColumn = angular.element('#assessment-header-cell-' + assessmentId);
            var columnOffset = assessmentColumn.offset();
            var container = angular.element('.biaxial-scroll-rows');
            container.scrollLeft(columnOffset.left - container.offset().left);
        }
    }

    function editAssessmentStudentScores() {
        var shouldEditScores = $routeParams.editing_scores == 1;
        if (shouldEditScores) {
            var assessmentId = $routeParams.focused_assessment_id;
            var assessment = _.find($scope.assessments, { assessment_id: assessmentId});
            assessment.editingAssessmentStudentScores = 1;
            assessment.getAssessmentStudents = () => getAssessmentStudentsForAssessment(assessment)
            $scope.$broadcast('editingAssessmentStudentScores', assessment);
        }
    }

    function clearFocusedAssessmentParams() {
        $route.updateParams(_.extend({}, $routeParams, { focused_assessment_id: null, editing_scores: null }));
    }

    function getAssessmentStudentsForAssessment(assessment, ignoreChanged) {
        var filterBy = !ignoreChanged ? 'changed' : undefined;

        return _.chain($scope.students)
            .map(student =>  _.find(student.assessment_students, { assessment_id: assessment.assessment_id }))
            .filter(filterBy)
            .map(assessmentStudent => helpers.cleanAssessmentStudent(assessment, assessmentStudent, $scope.grading_scales, $routeParams.course_id, $scope.students))
            .value();
    }

    function resetGradebookData() {
        $scope.assessments = null;
        $scope.assessmentsKeyed = null;
        $scope.students = null;
        $scope.course_grading_scale = null;
        $scope.grading_scales = null;

        $scope.filters = { sort: 'section_number' };
    }

    function setupWatches() {
        watches.push($scope.$on('settingsChanged', fetchDataAndCloseDialog));
        watches.push($scope.$on('$routeUpdate', handleRouteChange));
        $scope.$on('$destroy', handleDestroy);
        $window.onbeforeunload = handleOnBeforeUnload;
    }

    function handleOnBeforeUnload() {
        var editingAssessment = _.find($scope.assessments, { editingAssessmentStudentScores: true });
        var modalIsVisible = angular.element('.ngdialog').length;

        if (editingAssessment || modalIsVisible) {
            return 'It looks like you have unsaved edits!';
        }
    }

    function handleRouteChange(event, route) {
        // we dont want to fetch data if the route change was only the result
        // of clearing the focused assessment (which we do so that the assessment
        // doesn't scroll into view every time we change filters)
        if (!_.get($route.previous, 'params.focused_assessment_id')) {
            promiseCanceler.resolve();
            resetGradebookData();
            fetchDataAndCloseDialog();
        };
        // extend so that previous route is a new object, otherwise it will
        // reference the same object as route and will change when route changes
        $route.previous = _.extend({}, route);
    }

    function handleDestroy() {
        angular.forEach(watches, function(watch) {
            watch.call();
        });
    }

    function sortStudents(sortKey) {
        // if removing sort, default to ordering by section and student
        if (sortKey == '') {
            $scope.students = _.orderBy($scope.students, ['section_number', 'display_name'], ['asc', 'asc']);
        } else {
            // `-section_number` would be desc, `section_number` would be asc
            var order = sortKey.substr(0, 1) == '-' ? 'desc' : 'asc';
            // sort by function because sortKey might be nested e.g. bucket.score
            $scope.students = _.orderBy($scope.students, _.partial(dataSort, sortKey), [order]);
        }
    }

    // if sorting by score, the sortKey will be something like assessments[4]
    // otherwise it will be a simple key on the student object
    function dataSort(sortKey, student) {
        // trim leading `-` from a col sorted descending
        sortKey = sortKey.replace(/^-/, '');
        var matches = sortKey.match(/assessments\[(\d+)\]\['gradebook_score'\]/);

        if (matches && matches.length) {
            var assessmentStudent = _.find(student.assessment_students, { assessment_id: matches[1] });
            if (assessmentStudent.missing == 1 && assessmentStudent.active == 1) {
                return -1;
            }
            if (assessmentStudent.active == 0) {
                return -2;
            }
            return parseFloat(_.get(assessmentStudent, 'gradebook_score', -3));
        }

        return _.get(student, sortKey);
    }

    function promiseIsNull() {
        return !fetchGradebookDataPromise;
    }

    function hasRequiredParams() {
        var courseId = $routeParams.course_id;
        var sectionIds = $routeParams.section_ids;
        var termBinId = $routeParams.term_bin_id;
        return courseId && sectionIds && termBinId;
    }

    function goToCoursePage(goToGradingMethodologies) {
        $window.open('/course/' + $routeParams.course_id + (goToGradingMethodologies ? '#grading-methodologies' : ''), '_blank');
    }

    function openLinkInNewTab($event) {
        $event.preventDefault();
        $window.open($event.currentTarget.href, '_blank');
    }

    function getSelectedSectionNames() {
        var sectionIds = _.split($routeParams.section_ids, ',');
        return _.join(SectionService.getNamesForSectionIds(sectionIds), ', ');
    }

    function getEditingClasses(assessments) {
        var className = '';

        _.each(assessments, (assessment) => {
            if (assessment.editingAssessmentStudentScores) {
                className = `${hasEditingCellsClass}${assessment.showComments ? ` ${editingWithCommentsClass}` : ''}`;
                return false;
            }
        });

        return className;
    }

    //--------------------------
    // Socket Stuff
    //--------------------------

    function setupSocket() {
        $scope.socket = SocketService.init({
            scope: $scope,
            updateFunction: fetchData
        });
    }
}]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import * as helpers from '../../helpers';

angular
    .module('common')
    .controller('CommentInputController', [
        '$scope',
        '$timeout',
        '$element',
        '$keyCodes',
        '$document',
        '$rootScope',
        '$window',
function (
    $scope,
    $timeout,
    $element,
    $keyCodes,
    $document,
    $rootScope,
    $window,
) {
    var vm = this;
    var watches = [];

    vm.dropdownOpen = false;
    vm.initialFocusedOptionIndex = vm.focusedOptionIndex = -1;

    vm.toggleDropdown = toggleDropdown;
    vm.isDropdownOpen = isDropdownOpen;
    vm.fillDown = fillDown;
    vm.getPlaceholder = getPlaceholder;
    vm.onInputChange = onInputChange;
    vm.onInputFocus = onInputFocus;
    vm.onInputBlur = onInputBlur;
    vm.onInputKeydown = onInputKeydown;

    vm.$onInit = init;

    function init() {
        // - Watch model changes
        // - Typing in input will be handled via ng-change handler
        $scope.$watchCollection('input.model', updateInputValue);
        $scope.$on(`fillDown.${vm.filldownSettings.type}.${vm.filldownSettings.id}.${vm.modelKey}`, onFillDown);
        $scope.$on(`fillDownDone.${vm.filldownSettings.type}.${vm.filldownSettings.id}.${vm.modelKey}`, onFillDownDone);

        $document.on('click', handleOutsideClick);
    }

    function fillDown() {
        $rootScope.$broadcast(
            `fillDown.${vm.filldownSettings.type}.${vm.filldownSettings.id}.${vm.modelKey}`,
            vm.index,
            vm.model
        );

        toggleDropdown();
        $timeout(broadcastFilldownDone, 1500);
    }

    function broadcastFilldownDone() {
        $rootScope.$broadcast(`fillDownDone.${vm.filldownSettings.type}.${vm.filldownSettings.id}.${vm.modelKey}`);
    }

    function onFillDown(event, index, newModel) {
        if (vm.index > index) {
            vm.fillingdown = true;
            vm.model[vm.modelKey] = newModel[vm.modelKey];

            if (vm.onChange()) {
                vm.onChange()(newModel[vm.modelKey]);
            }
        }
    }

    function onFillDownDone() {
        vm.fillingdown = false;
    }

    function updateInputValue(newVal, oldVal) {
        if (newVal != oldVal) {
            vm.model.changed = true;
        }

        vm.inputValue = getInputValue();
    }

    function getInputValue() {
        return vm.model[vm.modelKey]
    }

    function getPlaceholder() {
        return 'comment';
    }

    function onInputChange() {
        var inputValue = vm.inputValue;

        if (vm.onChange()) {
            vm.onChange()(inputValue);
        }

        vm.model[vm.modelKey] = inputValue;
    }

    function onInputFocus() {
        vm.focused = true;
    }

    function onInputBlur() {
        vm.focused = false;
    }

    function toggleDropdown() {
        vm.dropdownOpen = !vm.dropdownOpen;
        // if we're opening it, figure out if it should open up or down for visiblity
        // wait for it to exist so we can get height
        // keep invisible until positioned so it doesn't flash while positioning
        if (vm.dropdownOpen) {
            $timeout(function () {
                var commentInputElem = $element.find('.comment-input');
                var container = angular.element('.biaxial-scroll-container');
                var optionsListWrapper = commentInputElem.find('.options-list-wrapper');
                var boundingRect = commentInputElem[0].getBoundingClientRect();
                var containerBoundingRect = container[0].getBoundingClientRect();
                var windowHeight = $window.innerHeight;
                var containerIsBelowWindow = (windowHeight - containerBoundingRect.bottom) < 0;
                // if container goes beyond screen, make sure there is enough room on screen
                // if container ends before screen, make sure there is enough room in container
                var spaceBelow = containerIsBelowWindow
                    ? windowHeight - boundingRect.bottom
                    : containerBoundingRect.bottom - boundingRect.bottom;

                if (spaceBelow < optionsListWrapper.height()) {
                    optionsListWrapper.addClass('toggle-up');
                    optionsListWrapper.css('top', `-${optionsListWrapper.height()}.px`);
                } else {
                    optionsListWrapper.removeClass('toggle-up');
                }
                optionsListWrapper.removeClass('invisible');
                optionsListWrapper.css('visiblity', 'visible');
            });
        }
    }

    function isDropdownOpen() {
        return vm.dropdownOpen;
    }

    function handleOutsideClick(event) {
        var target = angular.element(event.target);
        var elem = $element.find('.comment-input');

        // if dropdown is open and we clicked outside of dropdown
        // close dropdown
        if (isDropdownOpen() && !target.closest(elem).length) {
            $timeout(toggleDropdown);
        }
    }

    function onInputKeydown(event) {
        var keycode = event.keyCode;

        // open on "down" and not already open
        // close on "tab" and already open
        if ((keycode == $keyCodes.DOWN && !isDropdownOpen())
            || (keycode == $keyCodes.TAB && isDropdownOpen() || keycode == $keyCodes.ESC && isDropdownOpen())) {
            return toggleDropdown();
        }

        // up/down to focus option
        // enter to select focused option
        if (keycode == $keyCodes.DOWN && vm.focusedOptionIndex < 0) {
            vm.focusedOptionIndex++;
        } else if (keycode == $keyCodes.UP && vm.focusedOptionIndex > vm.initialFocusedOptionIndex) {
            vm.focusedOptionIndex--;
        } else if (keycode == $keyCodes.ENTER) {
            fillDown();
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import tssSwitchTemplate from './tss-switch.html';

angular
    .module('common')
    .directive('tssSwitch', [

function () {
    return {
        replace: true,
        restrict: 'E',
        scope: {
            ngModel: '=',
            tssSwitchOptions: '='
        },
        templateUrl: tssSwitchTemplate,
        link: tssSwitchLink
    };

    function tssSwitchLink($scope, $element) {
        $scope.options = !_.isEmpty($scope.tssSwitchOptions)
            ? $scope.tssSwitchOptions
            : [
                { label: 'Yes', value: '1' },
                { label: 'No', value: '0' }
            ];

        $scope.setModel = setModel;

        function setModel(value) {
            if (!$element.hasClass('disabled')) {
                $scope.ngModel = value;
            }
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
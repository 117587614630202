angular
    .module('services')
    .factory('AssessmentStudentCommentService', [
        '$http',

function (
    $http,
) {

    return {
        post: post,
        getByAssessmentStudent: getByAssessmentStudent,
    };

    function post(assessmentStudentId, comment, options) {
        var self = this,
            uri = '/api/v1/assessment-student-comment',
            data = {
                assessment_student_id: assessmentStudentId,
                comment: comment
            };

        return $http.post(uri, data, options);
    }

    // Gets assessment student comments for assessment student
    function getByAssessmentStudent(assessmentStudentId, options) {
        var uri = '/api/v1/assessment-student-comment/' + assessmentStudentId;

        return $http.get(uri, options);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
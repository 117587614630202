angular
    .module('common')
    .controller('ExternalCoursesController', [
        '$scope',
        '$q',
function (
    $scope,
    $q,
) {
    var vm = this;
     
    vm.$onInit = init;
    vm.goBack = goBack;
    vm.showAssessments = showAssessments;
    
    function init() {
        // handle login/logout workflow--any time we log in, load the courses
        $scope.$watch('externalCourses.externalService', initExternalService);
        $scope.$watch('externalCourses.externalProfile', getCourses);
        vm.scrollBarWidth = _.scrollBarWidth();
    }

    function initExternalService() {
        if (vm.externalIntegration) {
            vm.externalService.init(vm.externalIntegration)
                .then(externalProfile => {
                    // console.log('init resolved!');
                    return vm.externalProfile = externalProfile;
                });
        }
    }

    function getCourses() {
        vm.courses = null;

        if (vm.externalProfile && vm.externalProfile.isSignedIn) {
            vm.externalService.getCourses()
                .then(showCourses)
                .catch(handleExternalApiError);
        }
    }
    
    function showCourses(externalCourses) {
        vm.courses = externalCourses;

        var promises = $q.when();

        _.each(externalCourses, function(externalCourse, i) {
            externalCourse.nameAndSection = (_.escape(externalCourse.name
                + (externalCourse.section ? ` [${externalCourse.section}]` : ''))); // for filtering

            // sequential to avoid a race condition in edlink when they refresh
            // their own tokens against canvas.
            promises = promises.then(() => vm.externalService.getAssessments(externalCourse))
                .then(externalAssessments => vm.externalService.normalizeCourse(externalCourse, externalAssessments))
                .catch(handleExternalApiError);
        });
    }

    function goBack() {
        vm.courses = null;
        vm.externalIntegration = null;
        vm.externalService = null;
        vm.previousPage();
    }

    function showAssessments(externalCourse) {
        if (externalCourse.numAssessments) {
            vm.externalCourse = externalCourse;
            vm.nextPage();
        }
    }

    function handleExternalApiError(err) {
        console.log(err)
        console.log('external-courses.handleExternalApiError');
        vm.externalProfile = vm.externalService.getProfile();
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
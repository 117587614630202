angular
    .module('services')
    .factory('EdlinkService', [
        '$http',
        '$httpParamSerializer',
        '$q',
        'SettingService',

function (
    $http,
    $httpParamSerializer,
    $q,
    SettingService
) {
    var userAccessToken = null;
    var clientId = null;
    var integration = null; // canvas vs schoology vs moodle, etc.

    return {
        init: init,
        getCourses: getCourses,
        getAssessments: getAssessments,
        getStudents: getStudents,
        getAssessmentStudents: getAssessmentStudents,
        getProfile: getProfile,
        normalizeAssessment: normalizeAssessment,
        normalizeCourse: normalizeCourse,
        normalizeAssessmentStudents: normalizeAssessmentStudents,
        normalizeStudents: normalizeStudents,
        handleUseAnotherAccount: handleUseAnotherAccount,
        loadIntegrations: loadIntegrations,
    };

    function init(externalIntegration) {
        var env = angular.element('.js-env');

        clientId = env.data('edlink-client-id');
        integration = externalIntegration;

        // get access token from the server
        return $q.when(getAccessTokenFromRefreshToken())
            .then(attemptEdlinkSignInIfNecessary);
    }

    function getAccessTokenFromRefreshToken() {
        return $http.get(`/api/v1/edlink/access-token-from-refresh-token/${integration.id}`)
            .then(response => userAccessToken = _.get(response, 'results.access_token'));
    }

    function getAccessTokenFromCode(code) {
        return $http.get(`/api/v1/edlink/access-token-from-code/${integration.id}/${code}`)
            .then(response => userAccessToken = _.get(response, 'results.access_token'));
    }

    /**
     * Called when the signed in status changes, to update the UI
     * appropriately. After a sign-in, the API is called.
     */
    function attemptEdlinkSignInIfNecessary() {
        return userAccessToken
            ? handleSuccessfulSignIn()
            : showEdlinkSignInPrompt();
    }

    /**
     * @return Promise that resolves when the user has completed the OAuth sign
     * in process.
     */
    function showEdlinkSignInPrompt() {
        var d = $q.defer();
        const state = 'pop' // tells our back end to render the oauth2_popup
            + Math.random().toString(36).substring(2, 12)
            + Math.random().toString(36).substring(2, 15);
        const redirect_uri = `${window.location.protocol}//${window.location.host}/oauth2/edlink`;
        const params = {
            state,
            redirect_uri,
            client_id: clientId,
        };
        // In the current workflow we should always have an integration.id here,
        // which represents the combination of Schoolrunner + a specific
        // school's instance of an LMS (e.g. RePublic's Canvas). This allows us
        // to take the user directly to their login screen for their LMS instead
        // of to edlink's login page.
        const signInUrl = integration.id
            ? `https://ed.link/api/authentication/integrations/${integration.id}`
            : `https://ed.link/sso/login`;
        const w = 600;
        const h = 650;
        const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
        const popup = window.open(`${signInUrl}?${$httpParamSerializer(params)}`, 'edlink_sso', `width=${w},height=${h},top=${y},left=${x}`);
        let succeeded = false;
        const listener = event => {
            // Make sure that this event is coming from the correct origin
            // and has the correct state variable
            if (event.origin !== window.origin) {
                return console.warn('Invalid origin or state parameter.');
            }

            succeeded = event.data.state === params.state;
            window.removeEventListener('message', listener);
            popup.close();

            if (!succeeded) {
                console.warn('Invalid origin or state parameter.');
                return d.reject();
            }

            // trade this temporary code with the server to get an access token
            return $q.when(getAccessTokenFromCode(event.data.code))
                .then(attemptEdlinkSignInIfNecessary)
                .then(d.resolve);
        };

        window.addEventListener('message', listener);

        // poll so we can close our modal if they closed the SSO popup window
        var intervalId = setInterval(function() {
            if (popup.closed) {
                clearInterval(intervalId);

                if (!succeeded) {
                    handleFailedSignIn()
                        .then(d.resolve);
                }
            }  
        }, 1000);

        return d.promise;
    }

    function attemptEdlinkSignOut() {
        return $http.post(`/api/v1/edlink/logout/${integration.id}`);
    }

    function handleSuccessfulSignIn() {
        return $q.when(getProfile(true));
    }

    function handleFailedSignIn() {
        return $q.when(getProfile(false));
    }

    function handleEdlinkUseAnotherAccount() {
        return attemptEdlinkSignOut()
            .then(showEdlinkSignInPrompt);
    }

    function getCourses() {
        let api = 'https://ed.link/api/v1/my/courses';
        let params = {};
        let filterFunction = course => {
            return course.state == 'active'
                && _.filter(
                        course.enrollments,
                        enrollment => enrollment.type == 'teacher'
                    ).length
        }

        return $q.when(getFullResource(api, params))
            .then(response => {
                let allCourses = _.chain(response)
                    .filter(filterFunction)
                    .groupBy(m => {
                        // these results are really sections, not courses.
                        // so group by course.id if we have one so we can
                        // render a logical course all together
                        return _.get(m, 'course.id') || m.id;
                    }) 
                    .map(arr => {
                        // return the first section result so we have name, etc
                        // with all sections listed under .sections since we
                        // have to load assignments for each one separately
                        arr[0].sections = arr;
                        return arr[0];
                    })
                    .value();

                return allCourses;
            });
    }

    function getAssessments(edlinkCourse) {
        var allResponses = [];
        let promises = $q.when();

        for (let section of edlinkCourse.sections) {
            let api = `https://ed.link/api/v1/my/courses/${section.id}/assignments/`;
            let params = {};

            // sequential to avoid race condition with edlink refreshing its
            // token with the LMS since this is the first request we make in
            // the UI that actually requires edlink to hit the LMS
            promises = promises.then(response => {
                if (response) {
                    allResponses.push(response);
                }

                return getFullResource(api, params, true);
            });
        }

        let arrayMerger = (objValue, srcValue) => {
            if (_.isArray(objValue)) {
                return _.chain(objValue)
                    .concat(srcValue)
                    .uniqWith((aVal, bVal) => _.isObject(aVal) && _.isObject(bVal) && aVal.id == bVal.id)
                    .value();
            }
        };

        return promises.then(response => {
                allResponses.push(response);
                // keep track of section_ids so we can request submissions for
                // each section on a given assessment
                let allResponsesWithSectionIds = _.map(
                    allResponses,
                    (response, i) => _.map(response, assessment => _.extend(assessment, { 'section_ids': [edlinkCourse.sections[i].id] }))
                );
                let allAssessments = _.chain(_.concat(...allResponsesWithSectionIds))
                    .groupBy('id')
                    .map(arr => _.reduce(arr, (res, val) => _.mergeWith(res, val, arrayMerger), {}))
                    .values()
                    .value();

                return allAssessments;
            });
    }

    function getStudents(edlinkCourse) {
        let promises = [];

        for (let edlinkSection of edlinkCourse.sections) {
            let api = `https://ed.link/api/v1/my/courses/${edlinkSection.id}/enrollments`;
            let params = {};

            promises.push(getFullResource(api, params));
        }

        return $q.all(promises)
            .then(responses => normalizeStudents(_.concat(...responses)));
    }

    function normalizeStudents(students) {
        return _.chain(students)
            .each(normalizeStudent)
            .keyBy('person.id')
            .value();
    }

    function normalizeStudent(externalStudent) {
        externalStudent.email = externalStudent.person.email;
        externalStudent.firstLast = `${externalStudent.person.first_name} ${externalStudent.person.last_name}`;
        externalStudent.imageUrl = externalStudent.person.picture_url;
        externalStudent.externalId = externalStudent.person.id;
    }

    function getAssessmentStudents(edlinkCourse) {
        let promises = [];
        let assessmentIds = [];


        for (let assessment of edlinkCourse.assessments) {
            // double check this is working for 
            for (let sectionId of assessment.section_ids) {
                let api = `https://ed.link/api/v1/my/courses/${sectionId}/assignments/${assessment.id}/submissions`;
                let params = {};
                let promise = getFullResource(api, params, true);

                assessmentIds.push(assessment.id);
                promises.push(promise);
            }
        }

        // merge all the responses into a map of externalAssessmentId to array
        // of externalAssessmentStudents
        return $q.all(promises)
            .then(responses => {
                let resultsByAssessmentId = {};

                for (let response of responses) {
                    let assessmentId = assessmentIds.shift();
                    if (assessmentId in resultsByAssessmentId) {
                        resultsByAssessmentId[assessmentId] = _.chain(resultsByAssessmentId[assessmentId])
                            .concat(response)
                            .uniqBy('id') // filter out dupe student results from two diff sections
                            .value();
                    } else {
                        resultsByAssessmentId[assessmentId] = response;
                    }
                }

                return resultsByAssessmentId;
            });
    }

    function getAuthHeaders() {
        return {
            headers: {
                'Authorization': `Bearer ${userAccessToken}`,
            },
        };
    }

    function getFullResource(api, params, usePage = false) {
        let allResults = [];
        let $first = 100; // number of results to return in one request

        const getResourcePage = ($page, $after) => {
            let pagedParams = _.extend(params, {
                $first,
            });

            if (usePage) {
                if ($page > 1) {
                    pagedParams['$page'] = $page;
                }
            } else {
                pagedParams['$after'] = $after;
            }

            const url = `${api}?${$httpParamSerializer(pagedParams)}`;

            return $q.when($http.get(url, getAuthHeaders()))
                .then(response => retryIfNecessary(response, url))
                .then(response => {
                    let data = _.get(response, '$data');

                    if (!data) {
                        return allResults;
                    }

                    allResults = allResults.concat(data);

                    // if we got back the exact number we asked for then there
                    // might be more so get all results after the last id that
                    // got returned. some of their APIs don't use the $after
                    // flag but will take a sequential $page flag so we
                    // increment that here as well.
                    return data.length == $first
                        ? getResourcePage($page + 1, data.pop().id)
                        : allResults;
                })
                .catch(showEdlinkApiError);
        };

        return getResourcePage(1);
    }

    function retryIfNecessary(response, url) {
        if (_.get(response, '$error') == 'The bearer token provided has expired.') {
            return $q.when(getAccessTokenFromRefreshToken())
                .then(() => $http.get(url, getAuthHeaders()));
        }

        return response;
    }

    function getProfile(isSignedIn) {
        if (!isSignedIn) {
            return {
                isSignedIn: false,
                imageUrl: undefined,
            };
        }

        let options = getAuthHeaders();

        return $http.get('https://ed.link/api/v1/my/profile', options)
            .then(response => ({
                isSignedIn: true,
                imageUrl: _.get(response, '$data.picture_url'),
            }));
    }

    function normalizeAssessment(externalAssessment) {
        var courseWide = !externalAssessment.personalized;
        var dueDateTime = externalAssessment.due_date
            || externalAssessment.created_date;
        var tz = SettingService.get('tz');
        var date = moment.tz(dueDateTime, 'UTC').tz(tz).format('YYYY-MM-DD');

        externalAssessment.source = integration.source;
        externalAssessment.name = externalAssessment.title;
        externalAssessment.courseWide = courseWide;
        externalAssessment.date = date;
        externalAssessment.lastUpdated = externalAssessment.updated_date;
    }

    function normalizeCourse(externalCourse, externalAssessments) {
        var gradedAssessments = _.filter(externalAssessments, externalAssessment => {
            // If points_possible is in the map and falsey then it's not graded.
            // Also, only query for published/active assignments that we can get
            // submissions for--otherwise we might get weird 401s.
            return (externalAssessment.points_possible
                    || !('points_possible' in externalAssessment))
                && (externalAssessment.state == 'active'
                    || !('state' in externalAssessment));
        });
        var maxDate = null;
        var lastUpdatedAssessmentTitle = null;
        
        externalCourse.assessments = gradedAssessments;

        _.each(gradedAssessments, function(externalAssessment) {
            var date = externalAssessment.updated_date;

            if (!maxDate || (date && date > maxDate)) {
                maxDate = date;
                lastUpdatedAssessmentTitle = externalAssessment.title;
            }
        });

        externalCourse.numAssessments = gradedAssessments.length;

        if (maxDate) {
            externalCourse.lastUpdatedTimestampRelative = moment(maxDate).fromNow();
            externalCourse.lastUpdatedTimestampFormatted = moment(maxDate).format("dddd, M/D/YYYY h:mma");
            externalCourse.lastUpdatedAssessmentTitle = lastUpdatedAssessmentTitle;
        }
    }

    function normalizeAssessmentStudents(
        externalAssessment,
        externalStudentsByExternalId,
        externalStudentResultsByExternalAssessmentId
    ) {
        var pointsPossible = externalAssessment.points_possible;
        var externalStudentResults = _.get(externalStudentResultsByExternalAssessmentId, externalAssessment.id) || [];
        var numGrades = 0;
        var questions = [];
        var questionsByCode = {};

        // pick all objective_codes and sort and map to question numbers
        // so we have a stable ordering of code to question number
        var codeToQuestionNumber = _.chain(..._.map(externalStudentResults, 'standards'))
            .map('code')
            .uniq()
            .sortBy()
            .reduce((acc, val, i) => { acc[val] = i + 1; return acc }, {})
            .value();

        _.each(externalStudentResults, function(externalStudentResult) {
            var score = parseFloat(_.get(externalStudentResult, 'score'));
            var externalStudent = _.get(externalStudentsByExternalId, externalStudentResult.person.id);
            var externalStandardResults = externalStudentResult.standards || [];
            var externalStudentResultPointsPossible = parseFloat(
                _.get(externalStudentResult, 'points_possible')
                    || pointsPossible
                );
            var hasScore =
                _.isFinite(score)
                    && _.isFinite(externalStudentResultPointsPossible)
                    && externalStudentResultPointsPossible; // non-zero

            externalStudentResult.externalStudent = externalStudent;
            externalStudentResult.missing = !hasScore;
            externalStudentResult.avgScore = hasScore
                ? (100 * score / externalStudentResultPointsPossible)
                : null;

            numGrades += externalStudentResult.missing ? 0 : 1;

            externalStudentResult.answers = [];
            for (let standard of externalStandardResults) {
                if (standard.code) {
                    let question = _.get(questionsByCode, standard.code);

                    if (!question) {
                        let questionNumber = codeToQuestionNumber[standard.code];

                        question = {
                            objective_code: standard.code,
                            objective_description: standard.description,
                            correct_answer: null,
                            point_value: parseFloat(standard.points_possible),
                            question_number: questionNumber,
                        };
                        questions.push(question);
                        questionsByCode[standard.code] = question;
                    }

                    const answer = {
                        question_number: question.question_number,
                        points: parseFloat(standard.score),
                        answer: null,
                    };

                    externalStudentResult.answers.push(answer);
                }
            }
        });

        externalAssessment.questions = questions;
        externalAssessment.externalStudentResults = externalStudentResults;
        externalAssessment.numStudentResults = numGrades;
    }

    function handleUseAnotherAccount() {
        return attemptEdlinkSignOut()
            .then(showEdlinkSignInPrompt);
    }

    function showEdlinkApiError() {
        var msg = `Unable to load your Edlink data. Make sure your account has Edlink access.`;

        Growl.error({ message: 'Error: ' + msg });
        return attemptEdlinkSignOut()
            .then(handleFailedSignIn);
    }

    function loadIntegrations(email) {
        // email = 'brenda.baker@example.com'; // TESTING! Canvas
        // email = 'sr+teacher@ed.link'; // TESTING! Schoology
        // email = 'devteam@schoolrunner.org'; // TESTING! Canvas / RePublic
        const env = angular.element('.js-env');
        const clientId = env.data('edlink-client-id');
        const api = 'https://ed.link/api/authentication/accounts'
            + '?email=' + encodeURIComponent(email)
            + '&application_id=' + encodeURIComponent(clientId);

        // for now, filter out google classroom in case it shows up since we
        // already have our original google integration hard-coded.
        return $q.when($http.get(api))
            .then(response => _.chain(response)
                .get('$data')
                .filter(row => !_.get(row, 'provider.application', '').includes('google'))
                .map(row => ({
                    id: row.id,
                    name: _.get(row, 'provider.name', 'Edlink'), // e.g. Canvas
                    source: _.get(row, 'provider.application', 'edlink'), // e.g. canvas
                    icon: _.get(row, 'provider.icon_url') // e.g. https://ed.link/source/canvas.png
                        ? `https://ed.link${row.provider.icon_url}`
                        : '/dist/logos/edlink-mark.svg',
                    url: _.get(row, 'source.configuration.url'), // e.g. https://republic.instructure.com
                    no_link_between_courses_and_assessments:  _.get(row, 'provider.application') == 'illuminate',
                    has_due_dates:  _.get(row, 'provider.application') != 'illuminate',
                }))
                .value()
            );
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import settingsModalTemplate from '../../views/settings/settings.html';
import addEditAssessmentModalTemplate from '../../views/assessment-add-edit/assessment-add-edit.html';
import * as helpers from '../../helpers';

angular
    .module('common')
    .controller('FilterBarController',[ 
        '$http',
        '$httpParamSerializer',
        '$location',
        '$route',
        '$routeParams',
        '$timeout',
        '$window',
        '$q',
        'ngDialog',
        'CourseService',
        'SectionService',
        'SettingService',
        'TermBinService',
        'GradingMethodologyBucketService',
        'GoogleClassroomService',
        'EdlinkService',
        '$scope',

function(
    $http,
    $httpParamSerializer,
    $location,
    $route,
    $routeParams,
    $timeout,
    $window,
    $q,
    ngDialog,
    CourseService,
    SectionService,
    SettingService,
    TermBinService,
    GradingMethodologyBucketService,
    GoogleClassroomService,
    EdlinkService,
    $scope
) {
    var vm = this;
    var env = angular.element('.js-env');
    var userId = env.data('user-id');
    var districtId = null;
    var termId = env.data('term-id');
    var schoolId = env.data('school-id');
    var staffMemberId = env.data('staff-member-id');
    var editingAssessment = null;

    vm.http = $http;

    vm.getSetting = SettingService.get;
    vm.handleCourseFilterChange = _.noop;
    vm.handleSectionFilterChange = _.noop;
    vm.handleTermBinFilterChange = _.noop;
    vm.showGradebookSettingsModal = showGradebookSettingsModal;
    vm.showAddGradeModal = showAddGradeModal;
    vm.exportCsv = exportCsv;
    vm.courseSettings = {
        valueKey: 'course_id',
        labelKey: 'name',
        groupKey: 'optgroup',
        loadingSpinner: true
    };
    vm.sectionSettings = {
        valueKey: 'section_id',
        labelKey: 'display_name',
        loadingSpinner: true,
        multi: true
    };
    vm.termBinSettings = {
        valueKey: 'term_bin_id',
        labelKey: 'short_name',
        orderKey: 'short_name',
        loadingSpinner: true,
        icon: (option) => option && parseInt(option.is_locked) == 1 ? 'icon-lock' : ''
    };
    vm.externalIntegrations = [
        {
            source: 'google',
            name: 'Google Classroom',
            icon: '/dist/logos/google-classroom-color-96.png',
            url: 'https://classroom.google.com',
            service: GoogleClassroomService,
            no_link_between_courses_and_assessments: false,
            has_due_dates: true,
        },
    ];
    vm.loadedExternalIntegrations = false;
    vm.loadingGradebookData = false;
    vm.shouldShowMissingFilterParamsEmptyState = shouldShowMissingFilterParamsEmptyState;
    vm.getMissingFilterParamsEmptyStateTitle = getMissingFilterParamsEmptyStateTitle;
    vm.shouldShowEmptyTermBinsEmptyState = shouldShowEmptyTermBinsEmptyState;
    vm.isSelectedTermBinLocked = isSelectedTermBinLocked;

    init();

    function init() {
        $scope.$on('loadingGradebookData', (event, loading) => vm.loadingGradebookData = loading);
        loadUserState()
            .then(validateState)
            .then(storeState)
            .then(getFilterOptions)
            .then(storeFilterOptionsAndSetupFilters)
            .then(setRouteParams)
            .then(updatePageTitle);
        loadEdlinkOptions();
        $scope.$on('editingAssessmentStudentScores', (event, assessment) => editingAssessment = assessment);
        $scope.$on('cancelEditingAssessmentStudentScores', () => editingAssessment = null);
    }

    function loadUserState() {
        return SettingService.loadAll()
            .then(getStatePreferringRouteParams);
    }

    function validateState(state) {
        var termBinId = state.term_bin_id;

        if (!termBinId) {
            return state;
        }

        // if our term_bin is not for this term,
        // reset the state to just the
        // course_id and store it back to the db.
        // if we don't do this then the user gets a grumpy message
        // saying term_bin.term_id XX does not match termId YY which stinks.
        return TermBinService.findById(termBinId)
            .then(termBin => {
                if (termBin && termBin.term_id == termId) {
                    return state; // normal case
                }

                // update route and save settings
                const newState = { course_id: state.course_id };

                saveState(newState);

                return newState;
            });
    }

    function getStatePreferringRouteParams(settings) {
        return $timeout(() => {
            if (_.keys($routeParams).length) {
                return $routeParams;
            }

            var state = JSON.parse(_.get(settings, 'gradebook_state', null));
            return _.get(state, schoolId, {});
        });
    }

    function storeState(state) {
        vm.state = _.extend({}, state, {
            section_ids: state && state.section_ids ? _.split(state.section_ids, ',') : []
        });

        return vm.state;
    }

    function getFilterOptions(state) {
        var coursesPromise = CourseService.get().then(courses => vm.courseOptions = courses);
        var sectionsPromise = getSectionsPromise(state).then(sections => vm.sectionOptions = sections);
        var termBinsPromise = getTermBinsPromise(state).then(termBins => vm.termBinOptions = termBins);

        return $q.all({
            courses: coursesPromise,
            sections: sectionsPromise,
            termBins: termBinsPromise,
        });
    }

    function getSectionsPromise(state) {
        return state.course_id
            ? SectionService.get(schoolId, state.course_id, termId, staffMemberId)
            : $q.when([]);
    }

    function getTermBinsPromise(state) {
        return state.course_id
            ? GradingMethodologyBucketService.getTermBinsForCourse(termId, state.course_id)
            : $q.when([]);
    }

    function storeFilterOptionsAndSetupFilters(data) {
        // Refresh plugin after we know that the view has re-rendered
        return $timeout(setupFilters);
    }

    function setupFilters() {
        var coursesElem = angular.element('[ng-model="filterBar.state.course_id"]');
        var sectionsElem = angular.element('[ng-model="filterBar.state.section_ids"]');
        var termBinsElem = angular.element('[ng-model="filterBar.state.term_bin_id"]');

        // dont attach handler until setup is done
        vm.handleCourseFilterChange = handleCourseFilterChange;
        vm.handleSectionFilterChange = handleSectionFilterChange
        vm.handleTermBinFilterChange = handleTermBinFilterChange;
    }

    function setRouteParams(state) {
        state = state || vm.state

        $route.updateParams({
            'course_id': state.course_id,
            'section_ids': _.has(state.section_ids, 'length') ? state.section_ids.join(',') : null,
            'term_bin_id': state.term_bin_id
        });
    }

    // Updates page title based on section and term bin
    function updatePageTitle() {
        return TermBinService.findById(vm.state.term_bin_id)
            .then(termBin => {
                var pageTitlePieces = document.title.split(' | ');
                var newPrefix = 'Gradebook';
                var courseName = CourseService.getNameForCourseId(vm.state.course_id);
                var termBinName = termBin ? termBin.short_name : '';

                if (courseName.length && termBinName.length) {
                    newPrefix = courseName + ' ' + termBinName + ' ' + newPrefix;
                }

                pageTitlePieces[0] = newPrefix;
                document.title = pageTitlePieces.join(' | ');
            });
    }

    function handleCourseFilterChange() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            vm.state.course_id = $route.current.params.course_id // revert
            return;
        }

        vm.state.section_ids = null;
        vm.state.term_bin_id = null;
        vm.sectionOptions = null;
        vm.termBinOptions = null;

        getFilterOptions(vm.state)
            .then(storeFilterOptionsAndSetupFilters);

        setRouteParams();
        saveState();

    }

    function handleSectionFilterChange() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            vm.state.section_ids = $route.current.params.section_ids.split(',') // revert
            return;
        }

        setRouteParams();
        saveState();
    }

    function handleTermBinFilterChange() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            vm.state.term_bin_id = $route.current.params.term_bin_id // revert
            return;
        }

        setRouteParams();
        saveState();
    }

    function saveState(state) {
        state = state || vm.state

        // get the current state and override current school id key with current state
        var currentState = JSON.parse(SettingService.get('gradebook_state', '{}'));
        var newState = _.set(currentState, schoolId, state);

        var settings = {
            'gradebook_state': angular.toJson(newState)
        };

        return SettingService.save(settings, null, null, userId)
            .then(updatePageTitle);
    }

    function getSectionGroupLabel(key) {
        return _.titleize(_.humanize(key));
    }

    function showGradebookSettingsModal() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            return;
        }

        ngDialog.open({
            controller: 'SettingsController',
            template: settingsModalTemplate,
            className: 'gradebook-dialog ngdialog-theme-default',
            showClose: false,
            closeByEscape: false,
            closeByDocument: false,
        });
    }

    function showAddGradeModal(importingFromExternal) {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            return;
        }
        if (importingFromExternal) {
            fetch('/totango/gradebookImportAssessment/Academics')
        }
        else {
            fetch('/totango/gradebookAddAssessment/Academics')
        }

        var segment = $location.path().replace('/gradebook/', '');
        var standardRequired = getCurrentCourseUseStandardsBasedGrading()
            || _.includes(segment, 'standards');
        var enterGradesByLevel = getCurrentCourseEnterGradesByLevel();
        ngDialog.open({
            controller: 'AddEditAssessmentController',
            template: addEditAssessmentModalTemplate,
            className: 'gradebook-dialog ngdialog-theme-default',
            showClose: false,
            closeByEscape: false,
            closeByDocument: false,
            data: {
                standardRequired: standardRequired,
                enterGradesByLevel: enterGradesByLevel && !importingFromExternal ? 1 : 0,
                page: importingFromExternal ? 1 : undefined,
                externalIntegrations: vm.externalIntegrations,
                importingFromExternal: importingFromExternal,
            }
        });
    }

    /**
     * @return boolean
     */
    function getCurrentCourseUseStandardsBasedGrading() {
        var courseUsesStandardsBasedGrading = CourseService.getStandardsBasedGradingForCourseId($routeParams.course_id);

        return courseUsesStandardsBasedGrading != null
            ? courseUsesStandardsBasedGrading == 1
            : SettingService.get('use_standards_based_grading', 0) == 1;
    }

    /**
     * @return boolean
     */
    function getCurrentCourseEnterGradesByLevel() {
        var enterGradesByLevel = CourseService.getEnterGradesByLevelForCourseId($routeParams.course_id);

        return enterGradesByLevel != null
            ? enterGradesByLevel == 1
            : SettingService.get('enter_grades_by_level', 0) == 1;
    }

    function exportCsv() {
        var params = $httpParamSerializer({
            course_id: vm.state.course_id,
            section_ids: vm.state.section_ids.join(','),
            term_bin_id: vm.state.term_bin_id,
            order: SettingService.get('gradebook_assessment_date_order')
        });

        var segment = $location.path().replace('/gradebook/', '');

        var uri = '/api/v1/gradebook/' + segment + '/export?' + params;
        $window.location.assign(uri);
    }

    function shouldShowMissingFilterParamsEmptyState() {
        return hasCurrentRoute()
            && !hasAllFilterParams()
            && !shouldShowEmptyTermBinsEmptyState();
    }

    function shouldShowEmptyTermBinsEmptyState() {
        return hasCurrentRoute() && _.get($routeParams, 'course_id') && isTermBinOptionsEmptyArray();
    }

    function hasCurrentRoute() {
        return $route.current;
    }

    function hasAllFilterParams() {
        return _.every(['course_id', 'section_ids', 'term_bin_id'], (param) => _.get($routeParams, param));
    }

    function isTermBinOptionsEmptyArray() {
        return _.isEqual(vm.termBinOptions, []);
    }

    function getMissingFilterParamsEmptyStateTitle() {
        var message = 'Select ';
        var pieces = [];

        if (!_.get($routeParams, 'course_id')) {
            pieces.push('course');
        }

        if (!_.get($routeParams, 'section_ids')) {
            pieces.push('section');
        }

        if (!_.get($routeParams, 'term_bin_id')) {
            pieces.push('term');
        }

        if (pieces.length == 3) {
            message += 'course, then section and term';
        } else {
            message += pieces.join(' and ');
        }

        return message
    }

    function isSelectedTermBinLocked() {
        var selectedTermBinId = $routeParams.term_bin_id;
        var termBin = _.find(vm.termBinOptions, { term_bin_id: selectedTermBinId });

        if (!termBin) {
            return false;
        }

        return parseInt(termBin.is_locked) == 1 && hasAllFilterParams();
    }

    function loadEdlinkOptions() {
        var env = angular.element('.js-env');
        var userId = env.data('user-id');

        $q.when($http.get(`/api/v1/users/${userId}`))
            .then(response => EdlinkService.loadIntegrations(_.get(response, 'results.user.email')))
            .then(externalIntegrations => _.each(externalIntegrations, externalIntegration => externalIntegration.service = EdlinkService))
            .then(externalIntegrations => vm.externalIntegrations = vm.externalIntegrations.concat(externalIntegrations))
            .then(() => vm.loadedExternalIntegrations = true);
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
var _module_exports = "<div\n    class=\"assessment-student-bucket text-right\"\n    ng-class=\"{\n        'open': assessmentBucketIsOpen(assessmentStudent.course_grading_bucket_id)\n    }\"\n    style=\"color:{{ ::cell.scoreColor }}\">\n    <span\n        class=\"score-display\"\n        title=\"{{ ::cell.titleName }}\">\n        {{ ::cell.displayName }}\n    </span>\n</div>\n";;
var path = '/views/gradebook/views/bucketed-grid/assessment-bucket-cell/assessment-bucket-cell.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
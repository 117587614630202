import bucketedGridTemplate from './views/bucketed-grid/bucketed-grid.html';
import standardsBasedGridTemplate from './views/standards-based-grid/standards-based-grid.html';
import timelineGridTemplate from './views/timeline-grid/timeline-grid.html';

angular
    .module('gradebook')
    .config([ 
        '$routeProvider', 
        '$httpProvider', 
        '$locationProvider', 
        '$compileProvider', 

    function(
        $routeProvider, 
        $httpProvider,
        $locationProvider, 
        $compileProvider
        ) {
        $compileProvider.debugInfoEnabled(false);
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);
        $locationProvider.html5Mode(true);
        $httpProvider.useApplyAsync(true);

        var resolves = {
            settings: ['SettingService', function (SettingService) {
                return SettingService.loadAll();
            }]
        };

        $routeProvider
            .when('/gradebook/bucketed-methodologies', {
                templateUrl : bucketedGridTemplate,
                controller  : 'BucketedGridController',
                reloadOnSearch: false,
                resolve: resolves
            })
            .when('/gradebook/bucketed-standards', {
                templateUrl : standardsBasedGridTemplate,
                controller  : 'StandardsBasedGridController',
                reloadOnSearch: false,
                resolve: resolves
            })
            .when('/gradebook/timeline', {
                templateUrl : timelineGridTemplate,
                controller  : 'TimelineGridController',
                reloadOnSearch: false,
                resolve: resolves
            })
            .otherwise({
                resolveRedirectTo: ['SettingService', function (SettingService) {
                    return SettingService.loadAll()
                        .then(settings => _.get(settings, 'gradebook_view', 'timeline'))
                        .then(view => '/gradebook/' + view);
                }]
            });

        // Use x-www-form-urlencoded Content-Type
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        // Override $http service's default transformRequest
        $httpProvider.defaults.transformRequest = [requestTransformer];

        // Add interceptors for handling http responses and errors
        $httpProvider.interceptors.push('HttpInterceptor');

        function requestTransformer(data) {
            return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
        }

        function param(obj) {
            var query = '';
            var name, value, fullSubName, subName, subValue, innerObj, i;

            for (name in obj) {
                if (obj.hasOwnProperty(name)) {
                    value = obj[name];

                    if (value instanceof Array) {
                        for (i = 0; i < value.length; ++i) {
                            subValue = value[i];
                            fullSubName = name + '[' + i + ']';
                            innerObj = {};
                            innerObj[fullSubName] = subValue;
                            query += param(innerObj) + '&';
                        }
                    }
                    else if (value instanceof Object) {
                        for (subName in value)  {
                            if (value.hasOwnProperty(subName)) {
                                subValue = value[subName];
                                fullSubName = name + '[' + subName + ']';
                                innerObj = {};
                                innerObj[fullSubName] = subValue;
                                query += param(innerObj) + '&';
                            }
                        }
                    }
                    else if (value === null) {
                        query += encodeURIComponent(name) + '=&'; // send empty string instead of null
                    }
                    else if (value !== undefined) {
                        query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
                    }
                }
            }
            return query.length ? query.substr(0, query.length - 1) : query;
        }
    }]);

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
var _module_exports = "<div class=\"tss-checkbox-ng\">\n    <span\n        ng-class=\"{checked: isChecked()}\"\n        ng-click=\"toggle()\">\n    </span>\n    <label\n        ng-show=\"settings.label\"\n        ng-click=\"toggle()\">{{ ::settings.label }}\n    </label>\n</div>\n";;
var path = '/views/gradebook/common/tss-checkbox/tss-checkbox.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
var _module_exports = "<div\n    class=\"comment-input\"\n    ng-class=\"{\n        'open': input.dropdownOpen,\n        'filling-down': input.fillingdown,\n        'invalid': input.invalid,\n    }\">\n    <button\n        type=\"button\"\n        class=\"comment-input-btn\"\n        ng-click=\"input.toggleDropdown()\"\n        ng-disabled=\"input.disabled()\">\n        <i class=\"icon-chevron-down\"></i>\n    </button>\n    <input\n        type=\"text\"\n        tabindex=\"1\"\n        ng-model=\"input.inputValue\"\n        ng-model-options=\"::{ updateOn: 'default', debounce: 250 }\"\n        placeholder=\"{{ ::input.getPlaceholder() }}\"\n        ng-change=\"input.onInputChange()\"\n        ng-focus=\"input.onInputFocus()\"\n        ng-blur=\"input.onInputBlur()\"\n        ng-keydown=\"input.onInputKeydown($event)\"\n        ng-disabled=\"input.disabled()\"\n        title=\"{{ input.inputValue }}\"\n    />\n    <div\n        class=\"options-list-wrapper box-shadow invisible\"\n        ng-if=\"input.dropdownOpen\">\n        <ul>\n            <li ng-click=\"input.fillDown()\"\n                ng-class=\"{\n                    'focused': input.focusedOptionIndex == 0,\n                }\">\n                <i class=\"icon-circle-arrow-down\"></i>\n                Fill Down\n            </li>\n        </ul>\n    </div>\n</div>\n";;
var path = '/views/gradebook/common/comment-input/comment-input.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;
    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            var templateKey = module.exports;
            var gradebook = angular.element(document).find('#gradebook');
            var injector = gradebook.injector();
            if (injector) {
                $.get(templateKey)
                    .then(replaceTemplate)
                    .then(reload);

                function replaceTemplate(templateHtml) {
                    injector.get('$templateCache').put(templateKey, templateHtml);
                }
                
                function reload() {
                    injector.get('$route').reload();
                }
            }
        })
    }
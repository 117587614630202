angular
    .module('gradebook')
    .filter('highlight', function($sce) {
        return function(text, searchTerm) {
            if (searchTerm) {
                // escape special chars in the search term so you can search "(review"
                // @see https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
                var searchRegex = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

                text = text.replace(new RegExp('(' + searchRegex + ')', 'gi'), '<mark> $1</mark>');
            }
            
            return $sce.trustAsHtml(text);
        };
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
import tssDateTemplate from './tss-date.html';

angular
    .module('common')
    .directive('tssDate', [
        'TermBinService',
        '$window',

function (
    TermBinService,
    $window
) {
    return {
        replace: true,
        restrict: 'E',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            minDate: '=',
            maxDate: '=',
            onDateChange: '&'
        },
        templateUrl: tssDateTemplate,
        link: tssDateLink
    };

    function tssDateLink($scope, $element, attrs, ngModelCtrl) {
        $scope.holidayMessage = null;

        $scope.onDateDraw = onDateDraw;
        $scope.onDateSelect = onDateSelect;

        init();

        function init() {
            // add a custom validator for pickaday since it doesnt support ng-model 
            ngModelCtrl.$validators.pikadayRequired = pikadayRequiredValidator;

            // reposition datepicker on resize or scroll
            angular.element($window).on('resize scroll', handleScrollOrResize);
            $scope.$on('$destroy', handleDestroyEvent);

            TermBinService.get()
                .then(processTermBins)
        }

        function processTermBins(termBins) {
            $scope.termBins = _.reduce(termBins, (formatted, termBin) => {
                return formatted.concat([
                    { date: termBin.start_date, title: termBin.display_name + ' Start Date' },
                    { date: termBin.end_date, title: termBin.display_name + ' End Date' }
                ]);
            }, []);
        }

        function pikadayRequiredValidator(modelValue, viewValue) {
            var value = modelValue || viewValue;
            return _.has(value, '_d');
        }

        function onDateDraw() {
            var pikadayElem = angular.element('.pika-single');
            var cells = pikadayElem.find('td');
            _.each(cells, cell => {
                var $button = $(cell).find('button');
                var data = $button.data();
                if (!data) return;

                // fix zero indexed months in pikaday
                data.pikaMonth++

                // build date string based on pikaday data attrs
                var year = data.pikaYear;
                var month = _.padStart(data.pikaMonth, 2, '0');
                var day = _.padStart(data.pikaDay, 2, '0');
                var cellDate = `${year}-${month}-${day}`;

                // is this cell a term bin date
                var termBinDate = _.find($scope.termBins, { date: cellDate });
                if (termBinDate) {
                    $button.addClass('termBinDate').attr('title', termBinDate.title);
                }
            });
        }

        function onDateSelect(selectedDate) {
            ngModelCtrl.$validate();
            $scope.onDateChange()(selectedDate);

            TermBinService.getDateDesc(selectedDate.toString('YYYY-MM-DD'))
                .then(_.property('results'))
                .then(setHolidayMessage);
        }

        function setHolidayMessage(data) {
            $scope.holidayMessage = null;

            if (!data.is_school_day) {
                $scope.holidayMessage = data.holiday_description;
            }
        }

        function handleScrollOrResize() {
            if ($scope.ngModel.isVisible()) {
                $scope.ngModel.adjustPosition();
            }
        }

        function handleDestroyEvent() {
            angular.element($window).off('resize scroll', handleScrollOrResize);
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('FillDownController', 
        [
            '$scope',
            '$rootScope',
            '$timeout',
            '$element',

function (
    $scope,
    $rootScope,
    $timeout,
    $element
) {
    var vm = this;

    var objType;
    var id;

    vm.triggerFillDown = triggerFillDown;

    vm.$onInit = init;

    function init() {
        objType = _.get(vm, 'fillDownSettings.objType');
        id = _.get(vm, 'fillDownSettings.id');
        $scope.$on(`fillDown${objType}.` + id, onFillDown);
    }

    function triggerFillDown(event) {
        event.preventDefault();

        $rootScope.$broadcast(
            `fillDown${objType}.` + id,
            vm.index,
            vm.value
        );

        $timeout(() => angular.element('.fill-down-wrapper').removeClass('filling-down'), 1500);
    }

    function onFillDown(event, index, newValue) {
        if (vm.index > index) {
            $element.find('.fill-down-wrapper').addClass('filling-down');
            vm.value = newValue;
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
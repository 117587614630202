import sbgAssessmentBucketCellTemplate from './sbg-assessment-bucket-cell.html';
import './sbg-assessment-bucket-cell.controller.js';

angular
    .module('common')
    .component('sbgAssessmentBucketCell', {
        bindings: {
            index: '<',
            studentObjectiveBucket: '=',
            objectiveBucket: '<',
            gradingScale: '<',
        },
        controller: 'SbgAssessmentBucketCellController as cell',
        templateUrl: sbgAssessmentBucketCellTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
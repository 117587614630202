import externalIntegrationsTemplate from './external-integrations.html';
import './external-integrations.controller.js';

angular
    .module('common')
    .component('externalIntegrations', {
        bindings: {
            nextPage: '&',
            closeThisDialog: '&',
            externalService: '=',
            externalIntegration: '=',
            externalIntegrations: '<',
        },
        controller: 'ExternalIntegrationsController as externalIntegrations',
        templateUrl: externalIntegrationsTemplate
    });

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
angular
    .module('common')
    .controller('BiaxialScrollContainerController', 
        [
            '$timeout',
            '$scope',
function (
    $timeout,
    $scope,
) {
    var vm = this;

    vm.scrollBarWidth = _.scrollBarWidth();
    vm.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
    vm.programmedScrolling = false;

    // wrap in timeout to ensure
    // innner directives are 'linked'
    $timeout(init);

    function init() {
        vm.element = $('.gradebook-grid');
        vm.biaxialScrollHeader = vm.element.find('.biaxial-scroll-header');
        vm.biaxialScrollRows = vm.element.find('.biaxial-scroll-rows');
        vm.biaxialFixedRows = vm.element.find('.biaxial-fixed-rows');

        var scrollRowsScrollHandler = handleScrollRowsScroll;
        var fixedRowsScrollHandler = handleFixedRowsScroll;
        var scrollHeaderScrollHandler = handleScrollHeaderScroll;

        if (navigator.userAgent.indexOf("Firefox") > -1) {
            scrollRowsScrollHandler = _.debounce(handleScrollRowsScroll, 10);
            fixedRowsScrollHandler = _.debounce(handleFixedRowsScroll, 10);
        }

        vm.biaxialScrollRows.on('scroll', scrollRowsScrollHandler);
        vm.biaxialFixedRows.on('scroll', fixedRowsScrollHandler);
        vm.biaxialScrollHeader.on('scroll', scrollHeaderScrollHandler);
        // need wheel event because "scrolling" horizontally from fixed rows
        // doesn't actually cause a scroll event because there's nothing to scroll
        vm.biaxialFixedRows.on(vm.wheelEvent, handleFixedRowsWheel);

        $scope.$on('$destroy', handleDestroy);
    }

    function handleDestroy() {
        vm.biaxialScrollRows.off('scroll');
        vm.biaxialFixedRows.off('scroll');
        vm.biaxialFixedRows.off(vm.wheelEvent);
    }

    // if scrolling in the main grid, this fn should NOT be programmatic
    // but the fixed row scroll SHOULD
    // if scrolling in the student part of the grid, this fn SHOULD be programmatic
    // but the fixed row scroll should NOT
    function handleScrollRowsScroll(e) {
        if (vm.programmedScrolling) {
            vm.programmedScrolling = false;
            return;
        }
        vm.programmedScrolling = true;
        vm.biaxialScrollHeader.scrollLeft($(this).scrollLeft());
    }

    // if scrolling in the main grid, this fn SHOULD be programmatic
    // but the scroll row scroll should NOT
    // if scrolling in the student part of the grid, this fn should NOT be programmatic
    // but the scroll row scroll SHOULD
    function handleFixedRowsScroll(e) {
        if (vm.programmedScrolling) {
            vm.programmedScrolling = false;
            return;
        }
        vm.programmedScrolling = true;
    }

    // dont worry about being able to scroll from the header row because
    // it's a bitch to allow scrolling but hide the scrollbar
    // plus it makes more work to programmatically scroll the main grid
    function handleScrollHeaderScroll(e) {
        vm.programmedScrolling = false;
    }

    function handleFixedRowsWheel(e) {
        var deltaX = e.type === 'wheel' ? -e.originalEvent.deltaX : e.originalEvent.wheelDeltaX;
        if (deltaX) {
            // technically you are scrolling from within the fixed rows, but
            // we are actually letting you scroll the main grid via this wheel event
            var scrollLeft = vm.biaxialScrollRows.scrollLeft() - deltaX;

            vm.biaxialScrollRows.scrollLeft(scrollLeft);
        }
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }
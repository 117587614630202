import addEditAssessmentModalTemplate from '../../assessment-add-edit/assessment-add-edit.html';
import * as helpers from '../../../helpers';

angular
    .module('common')
    .controller('SbgAssessmentHeaderCellController',[
            'AssessmentService',
            'AssessmentStudentService',
            'AlertService',
            'ngDialog',
            '$rootScope',
            '$route',
            '$routeParams',
            '$scope',
            '$window',
            '$q',   
        
function (
    AssessmentService,
    AssessmentStudentService,
    AlertService,
    ngDialog,
    $rootScope,
    $route,
    $routeParams,
    $scope,
    $window,
    $q,
) {
    var vm = this;
    var editingAssessment = null;

    vm.viewAssessment = viewAssessment;
    vm.editAssessmentObjectiveScores = editAssessmentObjectiveScores;
    vm.cancelAssessmentObjectiveScoresEdit = cancelAssessmentObjectiveScoresEdit;
    vm.saveAssessmentObjectiveScores = _.debounce(saveAssessmentObjectiveScores, 1000, {leading: true, trailing: false});
    vm.showAssessmentEditModal = showAssessmentEditModal;
    vm.handleRemoveAssessment = handleRemoveAssessment;

    vm.$onInit = init;

    function init(){
        vm.assessmentIsEditable = assessmentIsEditable();
        vm.assessment.editingAssessmentObjectiveScores = false;
        vm.assessment.savingAssessmentObjectiveScores = false;
        vm.assessment.deactivatingAssessment = false;
        // all header cells listen for student score editing, and close if they
        // are not the assessment being edited - ensures we can only edit one at a time
        var assessmentObjectiveScoreEditingListener = $scope.$on('editingAssessmentObjectiveScores', (event, assessment) => {
            if (vm.assessment.assessment_id != assessment.assessment_id && vm.assessment.editingAssessmentObjectiveScores) {
                cancelAssessmentObjectiveScoresEdit();
            }
        });
        // if we open objective grades for edit, then we cant also be editing assessment objective scores
        var objectiveGradeEditingListener = $scope.$on('editingObjectiveGrades', (event, bucket) => {
                cancelAssessmentObjectiveScoresEdit();
        });
        $scope.$on('$destroy', objectiveGradeEditingListener);
        $scope.$on('$destroy', assessmentObjectiveScoreEditingListener);
        $scope.$on('editingAssessmentStudentScores', (event, assessment) => editingAssessment = assessment);
        $scope.$on('cancelEditingAssessmentStudentScores', () => editingAssessment = null);
    }

    function assessmentIsEditable() {
        return parseInt(vm.assessment.assessment_question_count) == 1
            && parseInt(vm.assessment.assessment_question_point_value) == 100
            && vm.assessment.assessment_question_correct_answer == null
    }

    function editAssessmentObjectiveScores() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            return;
        }

        vm.assessment.editingAssessmentObjectiveScores = true;
        vm.assessment.getAssessmentStudents = () => vm.getAssessmentStudents()(vm.assessment)
        var assessmentId = vm.assessment.assessment_id;
        $rootScope.$broadcast('editingAssessmentObjectiveScores', vm.assessment);
    }

    function cancelAssessmentObjectiveScoresEdit($event) {
        if ($event) {
            $event.stopPropagation();

            if (!helpers.confirmUnsavedEdits(editingAssessment)) {
                return;
            }
        }
        vm.assessment.editingAssessmentObjectiveScores = false;
        vm.assessment.showComments = false;
        var assessmentId = vm.assessment.assessment_id;
        $rootScope.$broadcast('cancelEditingAssessmentStudentScores', vm.assessment);
        $rootScope.$broadcast('cancelEditingAssessmentObjectiveScores.' + assessmentId, vm.assessment);
    }

    function completeSaveAndEdit() {
        vm.assessment.editingAssessmentObjectiveScores = false;
        vm.assessment.savingAssessmentObjectiveScores = false;
    }

    // get all changed assessment students
    // create records for those that don't exist
    // update records for those that do
    // can only update scores for one-question-assessments from standards-view
    // so don't have to worry about whether or not to update answers b/c we always do
    // recalculate assessment scores and enrollments
    function saveAssessmentObjectiveScores($event) {
        $event.stopPropagation();
        vm.assessment.savingAssessmentObjectiveScores = true;

        var assessmentStudents = vm.getAssessmentStudents()(vm.assessment);
        // if nothing changed then don't waste time
        if (assessmentStudents.length) {
            return AssessmentService.saveScores(assessmentStudents)
                .then(_.partial(vm.onAfterSave(), vm.assessment))
                .finally(completeSaveAndEdit);
        } else {
            completeSaveAndEdit();
        }
    }

    function viewAssessment() {
        $window.open('/assessment/' + vm.assessment.assessment_id + '?ref=gradebook', '_blank');
    }

    function showAssessmentEditModal() {
        if (!helpers.confirmUnsavedEdits(editingAssessment)) {
            return;
        }

        ngDialog.open({
            controller: 'AddEditAssessmentController',
            template: addEditAssessmentModalTemplate,
            className: 'gradebook-dialog ngdialog-theme-default',
            data: {
                assessment: vm.assessment
            },
            showClose: false,
            closeByEscape: false,
            closeByDocument: false,
        });
    }

    function handleRemoveAssessment() {
        vm.assessment.deactivatingAssessment = true;
        getAllAssessmentStudentsForAssessment()
            .then(confirmRemoveResultsOrDeactivate)
            .finally(() => vm.assessment.deactivatingAssessment = false)
    }

    function confirmRemoveResultsOrDeactivate(allActiveAssessmentStudents) {
        var ignoreChanged = true;
        var allVisibleActiveAssessmentStudents = (vm.getAssessmentStudents()(vm.assessment, ignoreChanged))
            .filter(sa => sa.active == 1 && sa.assessment_student_id);
        var numVisibleActiveAssessmentStudents = allVisibleActiveAssessmentStudents.length;
        var numHiddenActiveAssessmentStudents = allActiveAssessmentStudents.length
            - numVisibleActiveAssessmentStudents;
        var message = null;

        if (numHiddenActiveAssessmentStudents) {
            message = "We'll remove this assessment for the "
                + displayItems(numVisibleActiveAssessmentStudents, 'kid')
                + " with results in this Gradebook."
                + " There are results in other sections for "
                + displayItems(numHiddenActiveAssessmentStudents, 'kid')
                + " whose results we'll leave alone."
        } else {
            message = "Are you sure you want to remove '"
                + vm.assessment.name
                + " from ALL standards'? This will deactivate the assessment along with all student results.";
        }

        var confirmed = confirm(message)
        if (confirmed) {
            return numHiddenActiveAssessmentStudents
                ? removeFromThisGradebookView(allVisibleActiveAssessmentStudents)
                : deactivateAssessment();
        }
    }

    function removeFromThisGradebookView(allVisibleActiveAssessmentStudents) {
        return unlinkCurrentSectionsFromAssessment()
            .then(() => deactivateAssessmentStudents(allVisibleActiveAssessmentStudents))
            .then(_.partial(vm.onAfterSave(), vm.assessment))
            .finally(completeSaveAndEdit);
    }

    function unlinkCurrentSectionsFromAssessment() {
        var visibleSectionIds = $route.current.params.section_ids.split(',');
        var sectionParams = {
            section_ids: _.difference(vm.assessment.section_ids, visibleSectionIds).join(',')
        }

        return AssessmentService
            .updateSections(vm.assessment.assessment_id, sectionParams)
    }

    function deactivateAssessmentStudents(assessmentStudents) {
        var setInactive = sa => _.extend(sa, { active: 0, missing: 0 });
        var assessmentStudentsToSave = _.map(assessmentStudents, setInactive);

        if (!assessmentStudentsToSave.length) {
            return null;
        }

        return AssessmentService.saveScores(assessmentStudentsToSave)
    }

    function deactivateAssessment() {
        vm.assessment.active = 0;

        return AssessmentService
            .put(vm.assessment.assessment_id, vm.assessment)
            .then(showSuccessfulDeactivationMessage)
            .then(_.property('results.assessment'))
            .then(vm.onAfterDeactivate());
    }

    function getAllAssessmentStudentsForAssessment() {
        var params = {
            assessment_ids: vm.assessment.assessment_id,
            active: 1
        };

        return AssessmentStudentService.get(params)
    }

    function showSuccessfulDeactivationMessage(response) {
        AlertService.success('You have successfully removed the assessment.');
        return response;
    }
}])

    if (module.hot) {
        module.hot.accept();
        module.hot.dispose(function () {
            console.warn('Must reload to see change to angular js file.');
        });
    }